import { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { Container, Row, Col } from "react-bootstrap";

import api from "../../services/api";
import Template from "../../template";
import { toast } from "react-toastify";

import CarregarPDFAluno from '../../components/CarregarPDFAluno/';

import * as S from "./styled";

function Redacoes(props) {
  const { id, redacao } = props.match.params;

  const { isDragReject } = useDropzone({
    accept: "application/pdf",
  });

  const [arquivo, setArquivo] = useState("");


  async function sendFile(file) {
    const data = new FormData();

    data.append("id", id);

    if (file) {
      data.append("image", file);
    }

    try {
      const retorno = await api.post("redacao", data);

    //  console.log(retorno);
      setArquivo(`${retorno.data.arquivo}`)
      //history.push(`/correcao/${id}`);
    } catch (error) {}
  }

  const onDrop = useCallback((acceptedFiles) => {
    //const fileUrl = URL.createObjectURL(file);

    if (acceptedFiles[0] === undefined) {
      toast.error("Tipo de arquivo invalido");
      return false;
    }

    toast('Carregando arquivo');

    const file = acceptedFiles[0];

   // setArquivo(file.name);
    sendFile(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    async function getPDF() {
      try {
        const { data } = await api.get(`/getRedacao/${id}`);

        setArquivo(data[0].pdf);
      } catch (error) {}
    }

    if (redacao === "redacao") {
      getPDF();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "application/pdf",
  });

  return (
    <Template>
      <S.MainWrapper>
        <Container>
          <Row>
            <Col sm={12}>
              <S.MainContent>
                <Row>
                  <Col>
                    <legend>
                      <strong>Enviar Redação</strong> {arquivo}
                    </legend>
                  </Col>
                </Row>

                <Row>
                  {arquivo ? (
                    <>
                      <Col>
                        <CarregarPDFAluno
                          pdf={`${arquivo}`}
                          id={id}
                          setArquivo={setArquivo}
                          dadosRedacao={[]}
                          preview={true}
                        />
                      </Col>
                    </>
                  ) : (
                    <Col>
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input
                          name="image"
                          accept="application/pdf"
                          {...getInputProps()}
                        />
                        {isDragActive ? (
                          <p>Carregar PDF...</p>
                        ) : (
                          <p>
                            Clique aqui, ou arraste sua redação no formato PDF..
                          </p>
                        )}
                        <p>
                          {isDragReject && <p>Só pode enviar PDF</p>}
                        </p>
                      </div>
                    </Col>
                  )}
                </Row>
              </S.MainContent>
            </Col>
          </Row>
        </Container>
      </S.MainWrapper>
    </Template>
  );
}

export default Redacoes;
