import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

import Menu from "../../components/Menu";

import * as S from "./styled";

import { useAuth } from "../../context/auth";

const Suporte = (props) => {
  const { user } = useAuth();

  const { socket } = props;
  socket.on("connect", () => console.log("Suprote Iniciado"));



  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [myId, setMyId] = useState(uuidv4());

  useEffect(() => {
    socket.on("connect", () => console.log("Chat iniciado"));

    const handleNewMessage = (newMessage) =>
      setMessages([...messages, newMessage]);
    socket.on("chat.message", handleNewMessage);
    return () => socket.off("chat.message", handleNewMessage);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const handleSubmitMessage = (e) => {
    e.preventDefault();

    if (message.trim()) {
      socket.emit("chat.message", {
        id: myId,
        message,
        view: 0,
        token: user.token,
      });

      setMessage("");
    }
  };

  return (
    <S.MainWrapper>
      <Container fluid>
        <Row>
          <Col sm={2}>
            <Menu />
          </Col>
          <Col sm={10}>
            <S.MainContent>Suporte / Chat</S.MainContent>

            <S.MainContent>
              <Row>
                <Col>
                  Mensagens
                  {messages.map((m, index) => (
                    <div
                      className={
                        m.id === myId
                          ? "alert alert-success"
                          : "alert alert-danger text-right"
                      }
                      key={index}
                    >
                      {m.id === myId
                        ? `Eu: ${m.message}`
                        : `Suporte: ${m.message}`}
                    </div>
                  ))}
                  <p>&nbsp;</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  <input
                    type="text"
                    placeholder="Digite sua menasgem / dúvida"
                    onChange={(e) => setMessage(e.target.value)}
                    className="form-control"
                    value={message}
                  />{" "}
                  <button
                    onClick={handleSubmitMessage}
                    className="btn btn-primary"
                  >
                    Enviar Mensagem
                  </button>
                </Col>
              </Row>
            </S.MainContent>
          </Col>
        </Row>
      </Container>
    </S.MainWrapper>
  );
};

export default Suporte;
