import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";

import api from "../../services/api";

import * as S from "./styled";

import Template from "../../template";
import InputMask from "react-input-mask";

function Corretores() {
  const [loading, setLoading] = useState(true);

  const [dataInicio, setDataInicio] = useState("");
  const [dataFim, setDataFim] = useState("");

  const [redacoes, setRedacoes] = useState([]);
  const [corretores, setCorretores] = useState([]);
  const [idCorretor, setIdCorretor] = useState();

  
  const [temas, setTemas] = useState([]);
  const [idTema, setIdTema] = useState();

  /* formatar data para o padrão BR */
  const formatarData = (dataTurma) => {
    if (dataTurma) {
      let dataFinal = dataTurma.split("T");

      return dataFinal[0].split("-").reverse().join("/");
    }
  };

  const getRedacoes = async () => {
    setLoading(true);
    const dataCorretor = {
      id: idCorretor,
      dataInicio,
      dataFim,
      tema: idTema
    };

    try {
      let response = await api.post(`/getRedacoesCorretores`, dataCorretor);
      setRedacoes(response.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const getCorretores = async () => {
    setLoading(true);
    try {
      let response = await api.get(`/getCorretores`);
      setCorretores(response.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const getTemas = async () => {
    setLoading(true);
    try {
      let response = await api.get(`/pegarTemasGeral`);
      setTemas(response.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    getTemas();
    setLoading(true);
    getCorretores();
    getRedacoes();
  }, []);

  return (
    <Template>
      <S.MainWrapper>
        <Container fluid>
          <Row>
            <Col sm={12}>
              <S.MainContent>
                <Row>
                  <Col>
                    <div className="alert alert-primary" role="alert">
                      <strong>Corretores - Informações</strong>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md>
                    {corretores && corretores.length >= 1 && (
                      <p>
                        <select
                          onChange={(e) => setIdCorretor(e.target.value)}
                          className="form-control"
                        >
                          <option>Filtrar por Corretor</option>
                          {corretores &&
                            corretores.map((corretor, index) => (
                              <option key={index} value={corretor.id}>
                                {corretor.name}
                              </option>
                            ))}
                        </select>
                      </p>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md>
                    {temas && temas.length >= 1 && (
                      <p>
                        <select
                          onChange={(e) => setIdTema(e.target.value)}
                          className="form-control"
                        >
                          <option>Filtrar por Tema</option>
                          {temas &&
                            temas.map((tema, index) => (
                              <option key={index} value={tema.id}>
                                {tema.nome}
                              </option>
                            ))}
                        </select>
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md>
                    <strong>Filtrar por Período</strong>
                  </Col>
                  <Col xs={12} md>
                    <p>
                      <InputMask
                        className="form-control"
                        placeholder="Inicio **/**/**"
                        mask="99/99/9999"
                        maskChar={null}
                        value={dataInicio}
                        onChange={(e) => setDataInicio(e.target.value)}
                      />
                    </p>
                  </Col>
                  <Col xs={12} md>
                    <p>
                      <InputMask
                        className="form-control"
                        placeholder="Fim **/**/**"
                        mask="99/99/9999"
                        maskChar={null}
                        value={dataFim}
                        onChange={(e) => setDataFim(e.target.value)}
                      />
                    </p>
                  </Col>
                  <Col xs={12} md>
                    <p>
                      <button className="btn btn-info" onClick={() => getRedacoes()}>Buscar</button>
                    </p>
                  </Col>
                </Row>
                {loading ? (
                  <>
                    <Col className="text-center">
                      <div className="shadow p-3 mb-4 bg-white rounded fdark flex-c">
                        <div>
                          <div className="spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          <div
                            className="spinner-grow text-secondary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                          <div className="spinner-grow text-success" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          <div className="spinner-grow text-danger" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          <div className="spinner-grow text-warning" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          <div className="spinner-grow text-info" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div>
                          <strong>CARREGANDO</strong>
                        </div>
                      </div>
                    </Col>
                  </>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th className="th-xl">Tema</th>
                            <th className="th-xl">Corretor</th>
                            <th className="th-grande">Aula/Bônus</th>
                            <th className="th-grande">Quando</th>
                            <th className="th-grande">Ação</th>
                          </tr>
                        </thead>
                        <tbody>
                          {redacoes &&
                            redacoes.map((redacao, idx) => (
                              <>
                                <tr>
                                  <td>{redacao.nome_redacao}</td>
                                  <td>{redacao.corretor}</td>
                                  <td>Aula {redacao.aula}</td>
                                  <td>{formatarData(redacao.primeira_data)}</td>
                                  <td>
                                    <Link
                                      to={`/corrigir/redacao/${redacao.id}`}
                                      className="btn btn-sm btn-success"
                                    >
                                      Ver Correção
                                    </Link>
                                  </td>
                                </tr>
                              </>
                            ))}
                            <tr>
                                  <td colSpan={4}>Total de Correções no Período</td>
                                  <td>
                                    {redacoes.length}
                                  </td>
                                </tr>
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </S.MainContent>
            </Col>
          </Row>
        </Container>
      </S.MainWrapper>
    </Template>
  );
}

export default Corretores;
