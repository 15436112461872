import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import api from "../../services/api";
import * as S from "./styled";

import { useAuth } from "../../context/auth";

const ListaRedacoes = ({ turma, id_turma }) => {
  const { user } = useAuth();
  const date1 = new Date();
  const [loading, setLoading] = useState(true);
  const [redacoes, setRedacoes] = useState([]);
  const history = useHistory();

  const trazerTotal = (redacao) => {
    const total =
      parseInt(redacao.competencia1) +
      parseInt(redacao.competencia2) +
      parseInt(redacao.competencia3) +
      parseInt(redacao.competencia4) +
      parseInt(redacao.competencia5);

    if (redacao.falta) {
      return 0;
    }

    return total;
  };

  function enviarRedacao(e) {
    history.push(`/redacao/${e.target.id}`);
  }

  const loadRedacoes = async () => {
    const data = {
      id: user.id,
    };

    try {
      const response = await api.post(`/getRedacoes`, data);
      setRedacoes(response.data.redacoes);
      setLoading(false);
    } catch (error) {
      toast.error("Erro ao carregar suas redações");
    }
  };

  /* Validar datas se esta ou não expirado */
  const validaDataClasse = (dataTema, horaTema) => {
    let strData = dataTema;
    let srtHora = horaTema;

    var arr = `${strData} ${srtHora}`.split(/[- :]/);
    const validarData = new Date(
      arr[0],
      arr[1] - 1,
      arr[2],
      arr[3],
      arr[4],
      arr[5]
    );

    if (validarData > date1) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    loadRedacoes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row>
        <Col>
          <div className="shadow-sm p-3 mb-4 bg-white rounded fdark">
            <strong>Minhas Redações: {turma}</strong>
          </div>
        </Col>
      </Row>
      <Row>
        {loading ? (
          <>
            <Col className="text-center">
              <div className="shadow p-3 mb-4 bg-white rounded fdark flex-c">
                <div>
                  <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-secondary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-success" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-warning" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-info" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
                <div>
                  <strong>CARREGANDO</strong>
                </div>
              </div>
            </Col>
          </>
        ) : (
          <>
            <Col>
              <div className="shadow p-3 mb-4 bg-white rounded fdark">
                <div className="table-responsive">
                  {redacoes.length > 0 ? (
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th className="th-grande">Data Limite Entrega</th>
                          <th className="th-xl">Tema</th>
                          <th className="th-grande">Competência 1</th>
                          <th className="th-grande">Competência 2</th>
                          <th className="th-grande">Competência 3</th>
                          <th className="th-grande">Competência 4</th>
                          <th className="th-grande">Competência 5</th>
                          <th className="th-grande">Total</th>
                          <th className="th-grande">Status</th>
                          <th className="th-grande">Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {redacoes.map((redacao) => (
                          <tr>
                            <td>
                              {redacao.entrega ? (
                                <>
                                  {redacao.entrega &&
                                    redacao.entrega
                                      .split("-")
                                      .reverse()
                                      .join("/")}
                                  <br />
                                  às {redacao.hora && redacao.hora}
                                </>
                              ) : (
                                <>---</>
                              )}
                            </td>
                            <td>
                              {redacao.nome}
                            </td>
                            <td>{redacao.competencia1}</td>
                            <td>{redacao.competencia2}</td>
                            <td>{redacao.competencia3}</td>
                            <td>{redacao.competencia4}</td>
                            <td>{redacao.competencia5}</td>
                            <td>{trazerTotal(redacao)}</td>
                            <td>
                              {redacao.corrigida ? (
                                <>
                                  <span className="badge badge-pill badge-success">
                                    Corrigida
                                  </span>
                                </>
                              ) : (
                                <>
                                  {redacao.finalizada === 1 ? (
                                    <span className="badge badge-pill badge-info">
                                      Aguardando Correção
                                    </span>
                                  ) : (
                                    <span className="badge badge-pill badge-danger">
                                      Envio pendente
                                    </span>
                                  )}
                                </>
                              )}
                            </td>
                            <td>
                              {redacao.corrigida ? (
                                <Link
                                  to={`/correcao/${redacao.id}`}
                                  className="btn btn-success"
                                >
                                  Ver Correção
                                </Link>
                              ) : (
                                <>
                                  {redacao.finalizada === 1 ? (
                                    <button className="btn btn-info">
                                      Aguardar Correção
                                    </button>
                                  ) : (
                                    <>
                                      {validaDataClasse(
                                        redacao.entrega,
                                        `${redacao.hora}:00`
                                      ) ? (
                                        <>
                                          <span className="btn btn-danger">
                                            Expirado 
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            className="btn btn-info"
                                            id={redacao.id}
                                            onClick={enviarRedacao}
                                          >
                                            Enviar Correção
                                          </button>
                                        </>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <span className="badge badge-pill badge-danger">
                      Você não possui redações, escolha um tema em atividades
                    </span>
                  )}
                </div>
              </div>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default ListaRedacoes;
