const updateHash = (highlight) => {
  document.location.hash = `highlight-${highlight.id}`;
};



export function Sidebar({ highlights, removerItem, tipo }) {


  const getNomeCompetencia = (competencia) => {

    if(competencia == 1){
      return tipo != 1 ? `Competência ${competencia}` : `Modalidade culta da língua`;
    }else if(competencia == 2){
      return tipo != 1 ? `Competência ${competencia}` : `Tipologia textual`;
    }else if(competencia == 3){
      return tipo != 1 ? `Competência ${competencia}` : `Argumentação`;
    }else if(competencia == 4){
      return tipo != 1 ? `Competência ${competencia}` : `Coesão e Estrura do período`;
    }else if(competencia == 5){
      return tipo != 1 ? `Competência ${competencia}` : `Adequação ao tema`;
    }else{
      return 'Observação';
    }
  }

  return (
    <div className="sidebar" style={{ width: "25vw" }}>
      <div className="description" style={{ padding: "1rem" }}>
        <p>
          <small>Todos os pontos da correção serão mostrados abaixo</small>
        </p>
      </div>

      <ul className="sidebar__highlights">
        {highlights.map((highlight, index) => (
          <li
            key={index}
            className={`sidebar__highlight competencia_${highlight?.competencia}`}
          >
            <div>
              <button
                onClick={(e) => removerItem(highlight.id)}
                className="ir-direita"
              >
                X
              </button>
              <strong>
                {highlight?.competencia === 0
                  ? "Salvando informação"
                  : getNomeCompetencia(highlight?.competencia)
                  }
                : {highlight.comment.text.replace("undefined ", "")}
              </strong>
              {highlight.content.text ? (
                <blockquote style={{ marginTop: "0.5rem" }}>
                  {`${highlight.content.text.slice(0, 90).trim()}…`}
                </blockquote>
              ) : null}
              {highlight.content.image ? (
                <div
                  className="highlight__image"
                  style={{ marginTop: "0.5rem" }}
                >
                  <img src={highlight.content.image} alt={"Screenshot"} />
                </div>
              ) : null}
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}
