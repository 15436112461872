import { useState, useEffect, FormEvent } from "react";

import {
  Container,
  Row,
  Col,
  Tooltip,
  Button,
  Form,
  Modal,
  OverlayTrigger,
} from "react-bootstrap";

import api from "../../services/api";
import Template from "../../template";
import { toast } from "react-toastify";

import * as S from "./styled";

interface Usuario {
  competencia: string | number;
  descricao: string;
  id: number;
}
function Competencias() {
  const [competencias, setCompetencias] = useState<Usuario[]>([]);

  const [competencia, setCompetencia] = useState("");
  const [descricao, setDescricao] = useState("");
  const [id, setId] = useState(0);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show2, setShow2] = useState(false);
  const handleClose2 = () => setShow(false);

  async function handleAddCompetencia(e: FormEvent) {
    e.preventDefault();

    try {
      const dataCompetencia = {
        competencia,
        descricao,
      };

      await api.post("/addCompetencias", dataCompetencia);

      setCompetencia("");
      setDescricao("");

      setShow(false);

      toast.success("Competência cadastrada com sucesso!");
    } catch (error) {
      toast.error("Não foi possível cadastrar competência");
    }
  }

  async function handleGetCompetencia(e) {
    const dataCompetenciaId = {
      id: e.target.dataset.id,
    };

    let response = await api.post("/pegarCompetencia", dataCompetenciaId);

    setCompetencia(response.data[0].competencia);
    setDescricao(response.data[0].descricao);
    setId(e.target.dataset.id);

    setShow2(true);
  }

  const filtrarCompetencia = async () => {
    const dataFiltro = {
      competencia,
    };

    try {
      let response = await api.post("/filtrarCompetencia", dataFiltro);

      setCompetencias(response.data);
      toast.success("Competências filtradas com sucesso!");
    } catch (err) {
    } finally {
      return false;
    }
  };

  async function handleEditCompetencia(e: FormEvent) {
    e.preventDefault();

    try {
      const dataCompetencia = {
        competencia,
        descricao,
        id,
      };

      await api.put("/editCompetencia", dataCompetencia);

      setCompetencia("");
      setDescricao("");
      setId(0);
      setShow2(false);
      toast.success("Competência editada com sucesso!");

      getCompetencias();
    } catch (error) {
      toast.error("Não foi possível editar competência");
    }
  }

  async function handleDeleteCompetencia(e) {
    e.preventDefault();

    await api.delete(`/deleteCompetencia/${e.target.dataset.id}`);

    toast.success("Competência deletada com sucesso!");

    getCompetencias();
  }

  async function getCompetencias() {
    try {
      let response = await api.get("/getCompetencias");

      setCompetencias(response.data);
    } catch (err) {
    } finally {
      return false;
    }
  }

  useEffect(() => {
    getCompetencias();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Template>
      <S.MainWrapper>
        <Container>
          <Row>
            <Col sm={12}>
              <S.MainContent>
                <strong>Administração</strong>
                <Button
                  className="pull-right"
                  variant="success"
                  size="sm"
                  onClick={handleShow}
                >
                  Adicionar Competência
                </Button>

                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Adicionar Competência</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Control
                        placeholder="Descrição da Competência"
                        value={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                      />
                      <hr />

                      <Form.Control
                        onChange={(e) => setCompetencia(e.target.value)}
                        value={competencia}
                        as="select"
                        custom
                      >
                        <option value="0">Competência</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </Form.Control>

                      <hr />
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleAddCompetencia}>
                      Adicionar
                    </Button>
                  </Modal.Footer>
                </Modal>

                <Modal show={show2} onHide={handleClose2}>
                  <Modal.Header closeButton>
                    <Modal.Title>Editar Competência</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Control
                        placeholder="Descrição da Competência"
                        value={descricao}
                        onChange={(e) => setDescricao(e.target.value)}
                      />
                      <hr />

                      <Form.Control
                        onChange={(e) => setCompetencia(e.target.value)}
                        value={competencia}
                        as="select"
                        custom
                      >
                        <option value="0">Competência</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                      </Form.Control>

                      <hr />
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>
                      Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleEditCompetencia}>
                      Editar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </S.MainContent>

              <S.MainContent>
                <Form>
                  <Form.Row>
                    <Col sm={10}>
                      <select
                        onChange={(e) => setCompetencia(e.target.value)}
                        className="form-control"
                      >
                        <option>Filtrar por competencia</option>
                        <option value="1">Competência 1</option>
                        <option value="2">Competência 2</option>
                        <option value="3">Competência 3</option>
                        <option value="4">Competência 4</option>
                        <option value="5">Competência 5</option>
                      </select>
                    </Col>
                    <Col sm={2}>
                      <Button onClick={filtrarCompetencia}>
                        <S.SearchIcon />
                        Exibir itens
                      </Button>
                    </Col>
                  </Form.Row>
                </Form>
              </S.MainContent>

              <S.MainContent>
                <Row>
                  <Col>
                    <hr />
                    <table className="table table-bordered">
                      <tr>
                        <td>Competência</td>
                        <td>Descrição</td>
                        <td>Ações</td>
                      </tr>
                      {competencias &&
                        competencias.map((c, idx) => (
                          <tr>
                            <td>
                              <strong>{c.competencia}</strong>
                            </td>
                            <td>
                              <strong>{c.descricao}</strong>
                            </td>
                            <td>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Editar Competência
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="warning"
                                  data-id={c.id}
                                  onClick={handleGetCompetencia}
                                >
                                  Editar
                                </Button>
                              </OverlayTrigger>{" "}
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Deletar Competência
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="danger"
                                  data-id={c.id}
                                  onClick={handleDeleteCompetencia}
                                >
                                  Deletar
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))}
                    </table>
                  </Col>
                </Row>
              </S.MainContent>
            </Col>
          </Row>
        </Container>
      </S.MainWrapper>
    </Template>
  );
}

export default Competencias;
