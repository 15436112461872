import React, { useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Modal,
} from "react-bootstrap";

import api from "../../services/api";
import Menu from "../../components/Menu";

import * as S from "./styled";
import { useAuth } from "../../context/auth";

import Template from "../../template";

function Materiais() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const history = useHistory();

  const { user, setUser } = useAuth();

  const [materiais, setMateriais] = useState([]);

  const [name, setName] = useState("");
  const [content, setContent] = useState("");
  const [description, setDescription] = useState("");

  const [busca, setBusca] = useState("");

  const [reload, setReload] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function handleAddClasse(e) {
    e.preventDefault();

    const dataClasse = {
      name: name,
      content: content,
      description: description,
    };

    let response = await api.post("/materials", dataClasse);

    handleClose();

    setName("");
    setContent("");
    setDescription("");

    setReload(response.data);
  }

  async function deletarMaterial(id) {
    try {
      await api.delete(`/deletarMaterial/${id}`);
      setReload(id);
    } catch (err) {
    } finally {
      return false;
    }
  }

  async function handleBusca() {
    try {
      const dataBusca = {
        busca: busca,
      };

      let response = await api.post("/buscaMaterial", dataBusca);

      setMateriais(response.data);
    } catch (err) {
    } finally {
      return false;
    }
  }

  useEffect(() => {
    async function getMateriais() {
      try {
        let response = await api.get("/materials");

        setMateriais(response.data);
      } catch (err) {
      } finally {
        return false;
      }
    }

    getMateriais();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <Template>
      <S.MainWrapper>
        <Container>
          <Row>
            <Col sm={12}>
              {user.nivel === "a" && (
                <S.MainContent>
                  <strong>Administração</strong>
                  <Button
                    className="pull-right"
                    variant="success"
                    size="sm"
                    onClick={handleShow}
                  >
                    Adicionar Material
                  </Button>

                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Adicionar Material</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Label>Nome do Material</Form.Label>
                        <Form.Control
                          placeholder="Nome do Material"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <hr />
                        <Form.Control
                          placeholder="Tipo do Material"
                          value={content}
                          onChange={(e) => setContent(e.target.value)}
                        />
                        <hr />
                        <Form.Control
                          placeholder="Descrição do Material"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                        <hr />
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                      </Button>
                      <Button variant="primary" onClick={handleAddClasse}>
                        Adicionar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </S.MainContent>
              )}

              <S.MainContent>
                <strong>Bem vindo ao material de apoio</strong>
              </S.MainContent>

              <S.MainContent>
                <Form>
                  <Form.Row>
                    <Col sm={10}>
                      <Form.Control
                        onChange={(e) => setBusca(e.target.value)}
                        value={busca}
                        placeholder="Procure um por material desejado"
                      />
                    </Col>
                    <Col sm={2}>
                      <Button onClick={handleBusca}>
                        <S.SearchIcon />
                        {busca && busca !== "" ? (
                          <Button
                            onClick={() => {
                              setReload(new Date().toString());
                              setBusca("");
                            }}
                          >
                            Limpar Busca
                          </Button>
                        ) : (
                          ""
                        )}
                      </Button>
                    </Col>
                  </Form.Row>
                </Form>
              </S.MainContent>

              <S.MainContent>
                <Row>
                  {materiais &&
                    materiais.map((material, idx) => (
                      <Col key={idx} sm={3}>
                        <Card className="text-center">
                          <Card.Header>{material.content}</Card.Header>
                          <Card.Body>
                            <Card.Title>{material.name}</Card.Title>
                            <Card.Text>{material.description}</Card.Text>
                            <Link
                              to={`/material/${material.id}`}
                              className="btn btn-primary"
                            >
                              Visualizar
                            </Link>
                            {user.nivel === "a" && (
                              <>
                                <hr />
                                <button
                                  onClick={() => deletarMaterial(material.id)}
                                  className="btn btn-default"
                                >
                                  Deletar
                                </button>
                              </>
                            )}
                          </Card.Body>
                        </Card>
                      </Col>
                    ))}
                </Row>
              </S.MainContent>
            </Col>
          </Row>
        </Container>
      </S.MainWrapper>
    </Template>
  );
}

export default Materiais;
