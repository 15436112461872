import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Col } from "react-bootstrap";
import { toast } from "react-toastify";
import api from "../../services/api";

const MostrarNota = ({ alunoId, atividadeId, turmaId }) => {
  const [loading, setLoading] = useState(true);
  const [notas, setNotas] = useState([]);

  const trazerTotal = (nota) => {
    const total =
      parseInt(nota.competencia1) +
      parseInt(nota.competencia2) +
      parseInt(nota.competencia3) +
      parseInt(nota.competencia4) +
      parseInt(nota.competencia5);

    if (nota.falta) {
      return 0;
    }

    return total;
  };

  const habilitarReenvio = async (id) => {
    try {
      await api.put(
        `/reenviarRedacao/${id}`
      );
      setLoading(true);
      toast.success("Reenvio de redação habilitado!");
      loadNotas();

    } catch (err) {
      toast.error("Não foi possível habilitar o Reenvio da redação!");
    }
  }

  const loadNotas = async () => {
    try {
      const response = await api.get(
        `/pegarNotas/${alunoId}/${atividadeId}/${turmaId}`
      );
      setNotas(response.data);
      setLoading(false);
    } catch (err) {}
  };

  useEffect(() => {
    setLoading(true);
    loadNotas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [atividadeId]);

  return (
    <>
      {loading ? (
        <td>
          <Col className="text-center">
            <div className="shadow p-3 mb-4 bg-white rounded fdark flex-c">
              <div>
                <div className="spinner-grow text-primary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
                <div className="spinner-grow text-secondary" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              </div>
            </div>
          </Col>
        </td>
      ) : (
        <>
          {notas.length > 0 ? (
            notas.map((nota, key) => (
              <>
                <td>
                  {nota.corrigida && nota.corrigida === 1 ? (
                    <Link to={`/corrigir/redacao/${nota.id}`}>
                      <strong className="badge badge-pill badge-success">
                        Nota: {trazerTotal(nota)}
                      </strong>
                    </Link>
                  ) : (
                    <>
                      {nota.finalizada && nota.finalizada === 1 ? (
                        <>
                        <strong className="badge badge-pill badge-info">
                          Aguardando Correção
                        </strong>
                        <strong className="badge badge-pill badge-primary mhover" onClick={() => habilitarReenvio(nota.id)}>
                          Habilitar Reenvio
                        </strong>
                        </>
                      ) : (
                        <strong className="badge badge-pill badge-danger">
                          Pendente
                        </strong>
                      )}
                    </>
                  )}
                </td>
              </>
            ))
          ) : (
            <td>
              <strong className="badge badge-pill badge-warning">
                inexistente
              </strong>
            </td>
          )}
        </>
      )}
    </>
  );
};

export default MostrarNota;
