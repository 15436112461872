import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import api from "../../services/api";
import Template from "../../template";
import { toast } from "react-toastify";

import * as S from "./styled";

import { useAuth } from "../../context/auth";

const Monitores = () => {
  // eslint-disable-next-line no-unused-vars
  const { user } = useAuth();

  // eslint-disable-next-line no-unused-vars
  const history = useHistory();

  const [monitores, setMonitores] = useState([]);
  const [horas, setHoras] = useState([]);

  const [monitor, setMonitor] = useState("");

  const [dia, setDia] = useState("");

  const [hora, setHora] = useState("");
  const [minuto, setMinuto] = useState("");

  const [hora2, setHora2] = useState("");
  const [minuto2, setMinuto2] = useState("");

  const [mostrar, setMostrar] = useState("hide");

  const [idhora, setIdHora] = useState();

  function getDiaSemana(dia) {
    let diasemana = parseInt(dia);

    if (diasemana === 1) {
      return "Segunda Feira";
    } else if (diasemana === 2) {
      return "Terça Feira";
    } else if (diasemana === 3) {
      return "Quarta Feira";
    } else if (diasemana === 4) {
      return "Quinta Feira";
    } else if (diasemana === 5) {
      return "Sexta Feira";
    } else if (diasemana === 6) {
      return "Sábado";
    } else if (diasemana === 0) {
      return "Domingo";
    }
  }

  function resetData() {
    setMonitor("");
    setDia("");
    setHora("");
    setMinuto("");
    setHora2("");
    setMinuto2("");
    setIdHora("");
    setMostrar("hide");
  }

  async function getMonitores() {
    const dataFiltro = {
      filtro: "m",
    };

    try {
      let response = await api.post("/filterUsuarios", dataFiltro);

      setMonitores(response.data);
    } catch (err) {
    } finally {
      return false;
    }
  }

  async function getHorarios() {
    try {
      let response = await api.get("/getHorarios");

      setHoras(response.data);
    } catch (err) {
    } finally {
      return false;
    }
  }

  async function cadastrarHora() {
    const dataHora = {
      monitor_id: monitor,
      dia: dia,
      hora: hora,
      minuto: minuto,
      hora2: hora2,
      minuto2: minuto2,
    };

    try {
      await api.post("/saveDia", dataHora);
      toast.success('Monitoria cadastrada com sucesso');

      resetData();

      getHorarios();
    } catch (err) {
    } finally {
      return false;
    }
  }

  async function editarHora(e) {
    const dataUserId = {
      id: e.target.dataset.id,
    };

    try {
      const response = await api.post("/pegarHora", dataUserId);

      setIdHora(e.target.dataset.id);

      setMonitor(response.data[0].monitor_id);
      setDia(response.data[0].dia);
      setHora(response.data[0].hora);
      setMinuto(response.data[0].minuto);
      setHora2(response.data[0].hora2);
      setMinuto2(response.data[0].minuto2);
    } catch (err) {
    } finally {
      setMostrar("mostrar");
    }
  }

  async function editaHora() {
    const dataHora = {
      monitor_id: monitor,
      dia: dia,
      hora: hora,
      minuto: minuto,
      hora2: hora2,
      minuto2: minuto2,
      id: idhora,
    };

    try {
      await api.put("/editDia", dataHora);
      toast.success('Horário atualizado com sucesso');

      resetData();

      getHorarios();
    } catch (err) {
    } finally {
      return false;
    }
  }

  async function deletarHora(e) {
    await api.delete(`/deletaHora/${e.target.dataset.id}`);
    getHorarios();
  }

  useEffect(() => {
    getMonitores();
    getHorarios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Template>
      <S.MainWrapper>
        <Container>
          <Row>
            <Col sm={12}>
              <S.MainContent>
                <div className="alert alert-primary" role="alert">
                  <strong>Monitores</strong>
                </div>
              </S.MainContent>

              <S.MainContent>
                <Row>
                  <Col>
                    <p>
                      <strong>Calendário de monitorias</strong>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Escolha o Monitor</p>
                    <Form.Control
                      onChange={(e) => setMonitor(e.target.value)}
                      as="select"
                      className="form-control"
                      custom
                    >
                      <option value="">Escolha o Monitor</option>
                      {monitores &&
                        monitores.map((monitor, idx) => (
                          <option key={idx} value={monitor.id}>
                            {monitor.name}
                          </option>
                        ))}
                    </Form.Control>
                  </Col>
                  <Col>
                    <p>Dia da semana</p>
                    <Form.Control
                      onChange={(e) => setDia(e.target.value)}
                      as="select"
                      className="form-control"
                      custom
                    >
                      <option value="">Dia da semana</option>
                      <option value="1">Segunda Feira</option>
                      <option value="2">Terça Feira</option>
                      <option value="3">Quarta Feira</option>
                      <option value="4">Quinta Feira</option>
                      <option value="5">Sexta Feira</option>
                      <option value="6">Sábado</option>
                      <option value="0">Domingo</option>
                    </Form.Control>
                  </Col>
                  <Col>
                    <p>Escolha o inicio</p>
                    <Row>
                      <Col>
                        <Form.Control
                          onChange={(e) => setHora(e.target.value)}
                          as="select"
                          className="form-control"
                          custom
                        >
                          <option value="">Hora</option>
                          <option value="00">00</option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Control
                          onChange={(e) => setMinuto(e.target.value)}
                          as="select"
                          className="form-control"
                          custom
                        >
                          <option value="">Minuto</option>
                          <option value="00">00</option>
                          <option value="15">15</option>
                          <option value="30">30</option>
                          <option value="45">45</option>
                        </Form.Control>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <p>Escolha o fim</p>
                    <Row>
                      <Col>
                        <Form.Control
                          onChange={(e) => setHora2(e.target.value)}
                          as="select"
                          className="form-control"
                          custom
                        >
                          <option value="">Hora</option>
                          <option value="00">00</option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Control
                          onChange={(e) => setMinuto2(e.target.value)}
                          as="select"
                          className="form-control"
                          custom
                        >
                          <option value="">Minuto</option>
                          <option value="00">00</option>
                          <option value="15">15</option>
                          <option value="30">30</option>
                          <option value="45">45</option>
                        </Form.Control>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <hr />
                    <Button onClick={cadastrarHora}>Salvar</Button>
                  </Col>
                </Row>
              </S.MainContent>

              <S.MainContent className={mostrar}>
                <Row>
                  <Col>
                    <p>
                      <strong>Editar Monitoria</strong>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <p>Escolha o Monitor</p>
                    <Form.Control
                      as="select"
                      onChange={(e) => setMonitor(e.target.value)}
                      name="monitor"
                      defaultValue={monitor}
                      value={monitor}
                    >
                      {monitores &&
                        monitores.map((monitor, idx) => (
                          <option key={idx} value={monitor.id}>
                            {monitor.name}
                          </option>
                        ))}
                    </Form.Control>
                  </Col>
                  <Col>
                    <p>Dia da semana</p>
                    <Form.Control
                      onChange={(e) => setDia(e.target.value)}
                      as="select"
                      name="dia"
                      defaultValue={dia}
                      value={dia}
                    >
                      <option value="1">Segunda Feira</option>
                      <option value="2">Terça Feira</option>
                      <option value="3">Quarta Feira</option>
                      <option value="4">Quinta Feira</option>
                      <option value="5">Sexta Feira</option>
                      <option value="6">Sábado</option>
                      <option value="0">Domingo</option>
                    </Form.Control>
                  </Col>
                  <Col>
                    <p>Escolha o inicio</p>
                    <Row>
                      <Col>
                        <Form.Control
                          onChange={(e) => setHora(e.target.value)}
                          as="select"
                          custom
                          defaultValue={hora}
                          value={hora}
                          name="hora"
                        >
                          <option value="00">00</option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Control
                          onChange={(e) => setMinuto(e.target.value)}
                          as="select"
                          custom
                          defaultValue={minuto}
                          value={minuto}
                          name="minuto"
                        >
                          <option value="00">00</option>
                          <option value="15">15</option>
                          <option value="30">30</option>
                          <option value="45">45</option>
                        </Form.Control>
                      </Col>
                    </Row>
                  </Col>
                  <Col>
                    <p>Escolha o fim</p>
                    <Row>
                      <Col>
                        <Form.Control
                          onChange={(e) => setHora2(e.target.value)}
                          as="select"
                          custom
                          defaultValue={hora2}
                          value={hora2}
                          name="hora2"
                        >
                          <option value="00">00</option>
                          <option value="01">01</option>
                          <option value="02">02</option>
                          <option value="03">03</option>
                          <option value="04">04</option>
                          <option value="05">05</option>
                          <option value="06">06</option>
                          <option value="07">07</option>
                          <option value="08">08</option>
                          <option value="09">09</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                          <option value="12">12</option>
                          <option value="14">14</option>
                          <option value="15">15</option>
                          <option value="16">16</option>
                          <option value="17">17</option>
                          <option value="18">18</option>
                          <option value="19">19</option>
                          <option value="20">20</option>
                          <option value="21">21</option>
                          <option value="22">22</option>
                          <option value="23">23</option>
                          <option value="24">24</option>
                        </Form.Control>
                      </Col>
                      <Col>
                        <Form.Control
                          onChange={(e) => setMinuto2(e.target.value)}
                          as="select"
                          custom
                          defaultValue={minuto2}
                          value={minuto2}
                          name="minuto2"
                        >
                          <option value="00">00</option>
                          <option value="15">15</option>
                          <option value="30">30</option>
                          <option value="45">45</option>
                        </Form.Control>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <hr />
                    <Button onClick={editaHora}>Editar</Button>
                  </Col>
                </Row>
              </S.MainContent>

              <Row>
                <Col>
                  <S.MainContent>
                    <h3>Horários Cadastrados</h3>
                    <table className="table table-bordered">
                      <tr>
                        <td>
                          <strong>Monitor</strong>
                        </td>
                        <td>
                          <strong>Dia da Semana</strong>
                        </td>
                        <td>
                          <strong>Início</strong>
                        </td>
                        <td>
                          <strong>Fim</strong>
                        </td>
                        <td>
                          <strong>Ação</strong>
                        </td>
                      </tr>
                      {horas &&
                        horas.map((hora, idx) => (
                          <tr key={idx}>
                            <td>{hora.monitor}</td>
                            <td>{getDiaSemana(hora.dia)}</td>
                            <td>
                              {hora.hora}:{hora.minuto}
                            </td>
                            <td>
                              {hora.hora2}:{hora.minuto2}
                            </td>
                            <td>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Editar Monitoria
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="warning"
                                  data-id={hora.id}
                                  onClick={editarHora}
                                >
                                  Editar
                                </Button>
                              </OverlayTrigger>{" "}
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Deletar Monitoria
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="danger"
                                  data-id={hora.id}
                                  onClick={deletarHora}
                                >
                                  Deletar
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))}
                    </table>
                  </S.MainContent>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </S.MainWrapper>
    </Template>
  );
};

export default Monitores;
