import { useState, useEffect } from "react";
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { toast } from "react-toastify";
import api from "../../services/api";
import MostrarNota from "./MostrarNota";
import { useAuth } from "../../context/auth";

const ListaAlunos = ({ turma, id_turma }) => {
  const [loading, setLoading] = useState(true);

  const [atividades, setAtividades] = useState([]);

  const [alunosTurma, setAlunosTurma] = useState([]);

  const [temas, setTemas] = useState([]);
  const [idTema, setIdTema] = useState(0);

  const [offset, setOffset] = useState(1);

  const [paginas, setPaginas] = useState(1);

  const [alunos, setAlunos] = useState([]);
  const [id_aluno, setIdAluno] = useState();

  const { user } = useAuth();

  const getAlunos = async () => {
    try {
      let response = await api.get("/usuarios");

      setAlunos(response.data);
    } catch (err) {
    } finally {
      return false;
    }
  };

  const handleAddAluno = async () => {
    const data = {
      id_turma,
      id_aluno,
    };

    try {
      await api.post("/addAlunoTurma", data);

      toast.success("Aluno adicionado com sucesso!");
      setLoading(true);
      loadAlunos();
    } catch (err) {
      toast.error("Erro ao adicionar aluno!");
    }
  };

  const loadTemas = async () => {
    const data = {
      id: id_turma,
      tipo: "Todos",
      offset,
      temaId: idTema,
    };

    try {
      const response = await api.post(`/getAtividades`, data);
      setAtividades(response.data.atividades);
      setPaginas(response.data.paginas);
      setLoading(false);
    } catch (error) {
      toast.error("Erro as atividades da turma");
    }
  };

  const deletarAluno = async (id) => {
    try {
      await api.delete(`/deleteAlunoTurma/${id_turma}/${id}`);

      toast.success("Aluno deletado com sucesso!");
      setLoading(true);
      loadAlunos();
    } catch (err) {
      toast.error("Erro ao deletar aluno!");
    }
  };

  const getTemas = async () => {
    setLoading(true);
    try {
      let response = await api.get(`/pegarTemasGeral`);
      setTemas(response.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const loadAlunos = async () => {
    try {
      const response = await api.get(`/pegarAlunosPorTurma/${id_turma}`);

      setAlunosTurma(response.data);
      setLoading(false);
    } catch (err) {
      toast.error("Erro ao carregar alunos, tente novamente mais tarde!");
    }
  };

  useEffect(() => {
    console.log("idTema ", idTema);
    loadTemas();
    getAlunos();
  }, [idTema, offset]);

  useEffect(() => {
    loadAlunos();
    getTemas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Row>
        <Col>
          <div className="shadow-sm p-3 mb-4 bg-white rounded fdark">
            <strong>Exibindo Alunos: {turma}</strong>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className="shadow-sm p-3 mb-4 bg-white rounded fdark">
            {temas && temas.length >= 1 && (
              <p>
                <select
                  onChange={(e) => setIdTema(e.target.value)}
                  className="form-control"
                >
                  <option value="0">Filtrar por Tema</option>
                  <option value="0">Todos os temas</option>
                  {temas &&
                    temas.map((tema, index) => (
                      <option key={index} value={tema.id}>
                        {tema.nome}
                      </option>
                    ))}
                </select>
              </p>
            )}
          </div>
        </Col>
      </Row>
      {user.nivel === "a" && (
        <Row>
          <Col>
            <div className="shadow-sm p-3 mb-4 bg-white rounded fdark">
              Para adicionar aluno escolha na caixa ao lado e insira o aluno.
              {alunos && alunos.length >= 1 && (
                <p>
                  <select
                    onChange={(e) => setIdAluno(e.target.value)}
                    className="form-control"
                  >
                    <option value="1">Escolha um aluno cadastrado</option>
                    {alunos &&
                      alunos.map((aluno, index) => (
                        <option key={index} value={aluno.id}>
                          {aluno.name}
                        </option>
                      ))}
                  </select>
                </p>
              )}
              <button className="btn btn-info" onClick={() => handleAddAluno()}>
                Adicionar Aluno
              </button>
            </div>
          </Col>
        </Row>
      )}

      <Row>
        {loading ? (
          <>
            <Col className="text-center">
              <div className="shadow p-3 mb-4 bg-white rounded fdark flex-c">
                <div>
                  <div className="spinner-grow text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-secondary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-success" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-danger" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-warning" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                  <div className="spinner-grow text-info" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
                <div>
                  <strong>CARREGANDO</strong>
                </div>
              </div>
            </Col>
          </>
        ) : (
          <>
            <Col>
              <div className="shadow p-3 mb-4 bg-white rounded fdark">
                <div className="table-responsive">
                  {idTema == 0 && (
                    <p>
                      <strong>
                        Mostrando página {offset} de {paginas} de temas para a
                        classe. |{" "}
                        <button
                          className="btn btn-info"
                          onClick={() =>
                            setOffset(offset <= 1 ? 1 : offset - 1)
                          }
                        >
                          Anterior
                        </button>{" "}
                        <button
                          className="btn btn-info"
                          onClick={() =>
                            setOffset(offset >= paginas ? paginas : offset + 1)
                          }
                        >
                          Próxima
                        </button>
                      </strong>
                    </p>
                  )}

                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th className="th-xl">Aluno(a)</th>
                        {atividades &&
                          atividades.map((atividade) => (
                            <th className="th-grande">
                              {atividade.genero} {atividade.aula}:{" "}
                              {atividade.nome.substr(0, 15)}
                              ...
                              <br />
                              <hr />
                              de 1000
                            </th>
                          ))}
                      </tr>
                    </thead>
                    <tbody>
                      {alunosTurma &&
                        alunosTurma.map((aluno) => (
                          <tr>
                            <td>
                              <strong>
                                {aluno.name}{" "}
                                {user.nivel === "a" && (
                                  <button
                                    onClick={(e) => deletarAluno(aluno.id)}
                                  >
                                    Deletar
                                  </button>
                                )}
                              </strong>
                            </td>
                            {atividades &&
                              atividades.map((atividade) => (
                                <MostrarNota
                                  alunoId={aluno.id}
                                  atividadeId={atividade.tema_id}
                                  turmaId={id_turma}
                                />
                              ))}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </Col>
          </>
        )}
      </Row>
    </>
  );
};

export default ListaAlunos;
