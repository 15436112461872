import { Link, Redirect } from "react-router-dom";
import * as S from "./styled";
import { useAuth } from "../../context/auth";

import LogoTop from "../../assets/logo-topo.png";

import {
  Container,
  Row,
  Col,
  Image,
  Nav,
  Navbar,
  ListGroup,
} from "react-bootstrap";

function Menu() {
  const { user, setUser } = useAuth();

  function logout() {
    localStorage.removeItem("token");
    setUser({});
    <Redirect
      to={{
        pathname: "/",
      }}
    />;
  }

  return (
    <S.MenuSite>
      <S.Azul>
        <Container>
          <Row>
            <Col lg={5}>
              <Link to="/logado" className="nav-link">
                <Image src={LogoTop} fluid />
              </Link>
            </Col>
            <Col lg={7}>
              <ListGroup horizontal style={{ justifyContent: "flex-end" }}>
                <ListGroup.Item>
                  <Link to="/logado" className="nav-link">
                    <strong>Bem vindo(a) {user.nome}</strong>
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link to="/logado" className="nav-link">
                    Perfil
                  </Link>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Link onClick={logout} to="/" className="nav-link">
                    <S.LogoutIcon /> Sair
                  </Link>
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
        </Container>
      </S.Azul>
      <S.Branco>
        <Container>
          <Row>
            <Col xs={12}>
              <Navbar className="bg-blue" expand="lg">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto">
                    <Link to="/logado" className="nav-link">
                      <S.HomeIcon /> Home
                    </Link>
                    {(user.nivel === "a" ||
                      user.nivel === "c") && (
                      <Link to="/redacoes" className="nav-link">
                        <S.NewsPapperIcon /> Correção - Redações
                      </Link>
                    )}
                    {(user.nivel === "a" ||
                      user.nivel === "c") && (
                      <Link to="/competencias" className="nav-link">
                        <S.NewsPapperIcon /> Competências
                      </Link>
                    )}
                    {(user.nivel === "a") && (
                      <Link to="/corretores" className="nav-link">
                        <S.NewsPapperIcon /> Corretores
                      </Link>
                    )}
                    {(user.nivel === "a" || user.nivel === "e") && (
                      <>
                        <Link to="/classe" className="nav-link">
                          <S.ClassIcon /> Minha Classe
                        </Link>
                      </>
                    )}
                    {(user.nivel === "a" || user.nivel === "e") && (
                      <>
                        <Link to="/medias" className="nav-link">
                          <S.ClassIcon /> Minha Média
                        </Link>
                      </>
                    )}
                    {(user.nivel === "a" || user.nivel === "e") && (
                      <>
                        <Link to="/materiais" className="nav-link">
                          <S.ClassIcon /> Materiais
                        </Link>
                      </>
                    )}

                    {(user.nivel === "a" ||
                      user.nivel === "m" ||
                      user.nivel === "e") && (
                      <Link to="/mentoria" className="nav-link">
                        <S.ChatIcon /> Monitoria
                      </Link>
                    )}
                    {user.nivel === "a" && (
                      <Link to="/monitores" className="nav-link">
                        <S.ChatIcon /> Monitores
                      </Link>
                    )}
                    {(user.nivel === "a" || user.nivel === "m") && (
                      <Link to="/listamentoria" className="nav-link">
                        <S.ChatIcon /> Lista Monitoria
                      </Link>
                    )}

                    <hr />
                    {user.nivel === "a" && (
                      <Link to="/usuarios" className="nav-link">
                        <S.UsersIcon /> Usuários
                      </Link>
                    )}
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
            </Col>
          </Row>
        </Container>
      </S.Branco>
    </S.MenuSite>
  );
}

export default Menu;
