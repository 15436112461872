import styled from "styled-components";
import { Search } from "@styled-icons/boxicons-regular/Search";
import { Delete } from "@styled-icons/fluentui-system-filled/Delete";
import { EditAlt } from "@styled-icons/boxicons-regular/EditAlt";


export const MainWrapper = styled.div`
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  color: #fff;

  option:disabled {
    color: red !important;
    font-weight: 700;
    text-decoration: line-through !important;
  }

  option {
    color: green !important;
    font-weight: 700;
  }

  .dropdown-item {
    color: #333 !important;
  }

  .bgdark {
    background-color: #007bc6;
    margin-left: -15px;

    a {
      background-color: #05367f;
      margin-bottom: 1px;
      color: #fff;
    }

    hr{
      border-top: 1px solid #3b95f2;
      margin: 10px 0px;
    }
  }

  .btn-primary{
    background-color: #05367f;
    display: block;
    border: 0px;
    width: 100%;
  }

  .card {
    margin: 15px 0px;
  }
`;

export const MainContent = styled.div`
  background-color: #fff;
  padding: 15px;
  display: block;
  margin: 10px 0px;
  color: #333;

  .pull-right{
    position: relative;
    float: right;
  }

  &.hide{
    display: none !important;
  }
`;


export const SearchIcon = styled(Search)`
  color: #fff;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;


export const DeleteIcon = styled(Delete)`
  color: #fff;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;


export const EditIcon = styled(EditAlt)`
  color: #fff;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;

