import styled from "styled-components";

export const LoginWrapper = styled.div`
  background-color: #007bc6;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  min-height: 100vh;
  color: #fff;

  strong{
    cursor: pointer;
  }

  img{
    max-width: 100%;
    margin: 20px;
    margin-bottom: 40px;
  }
`;

export const FromWrapper = styled.div`
  background-color: #fff;
  width: 100%;
  min-height: 10vh;
  max-width: 400px;
  color: #007bc6;
  border-radius: 20px;
  padding: 25px;
  text-align: center;

  

  h1{
    font-size: 24px;
    margin: 0px;
    padding: 0px;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 20px;
  }

  input{
    width: 100%;
    border:1px solid #eee;
    margin: 5px;
    padding: 15px;
  }

  button{
    background-color: #fec900;
    border: 0px;
    padding: 5px 15px;
    text-align: center;  
    border-radius: 15px;
    margin: 5px;
    font-weight: 700;
  }
`;
