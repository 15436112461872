import { useState, useEffect } from "react";

import api from "../../services/api";

const CaixaDeCorrecao = ({
  hideTipAndSelection,
  addHighlight,
  content,
  position,
  doCallback,
  transformSelection
}) => {
  const [comentario, setComentario] = useState('');
  const [obs, setObs] = useState();
  const [competencia, setCompetencia] = useState();
  const [competencias, setCompetencias] = useState([]);

  useEffect(() => {
    console.log('position ', position);
    console.log('transformSelection ', transformSelection);
    
    doCallback(true);
  }, [])

  async function getCompetencias(competencia) {
    try {
      const response = await api.get(`/getCompetencia/${competencia}`);
      setCompetencias(response.data.competencias);
    } catch (err) {
    } finally {
    }
  }

  return (
    <div key="correcao" className="caixa-correcao">
      <select
        onChange={(e) => {
          setCompetencia(e.target.value);
          setCompetencias([]);
          getCompetencias(e.target.value);
          doCallback(true);
        }}
        className="form-control"
      >
        <option value="0">Selecione a Competência</option>
        <option value="1">Competência 1</option>
        <option value="2">Competência 2</option>
        <option value="3">Competência 3</option>
        <option value="4">Competência 4</option>
        <option value="5">Competência 5</option>
      </select>

      {competencia && (
        <select
          onChange={(e) => {
            setObs(e.target.value);
          }}
          className="form-control"
        >
          <option>Selecione abaixo</option>
          {competencias &&
            competencias.map((comp, index) => (
              <option key={index} value={comp.descricao}>{comp.descricao}</option>
            ))}
        </select>
      )}
      <textarea
        className="form-control"
        onChange={(e) => setComentario(e.target.value)}
        placeholder="Digite seu comentário"
      >
        {comentario}
      </textarea>
      <button
        className="btn btn-info btn-sm"
        onClick={() => {
          addHighlight({
            content,
            position,
            comment: {
              text: obs + ' ' + comentario,
              emoji: "",
            },
            competencia,
          });

          setComentario();
          setCompetencia();
          hideTipAndSelection();
          doCallback(false);
        }}
      >
        Salvar
      </button>
    </div>
  );
};

export default CaixaDeCorrecao;
