import { useState, useEffect } from "react";
import api from "../../services/api";
import * as S from "./styled";

import Logo from "../../assets/logo.png";
import { useAuth } from "../../context/auth";

function ValidarAluno(props) {

  // eslint-disable-next-line no-unused-vars
  const { user } = useAuth();

  const [aviso, setAviso] = useState("");
  const [enviado, setEnviado] = useState();

  const [nome, setNome] = useState("");
  const [cpf, setCPF] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [turma, setTurma] = useState("");

  useEffect(() => {



    async function getPerfil() {
      try {
        let response = await api.get(`/validarUsuario/${props.match.params.cpf}`);

        setNome(response.data[0].name);
        setEmail(response.data[0].email);
        setTelefone(response.data[0].phone);
        setCPF(response.data[0].cpf);
        setTurma(response.data[0].turma);
      } catch (err) {

      } finally {
        return false;
      }
    }

    getPerfil();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function cadastroHandler(e) {
    e.preventDefault();

    const dataEstudante = {
      cpf: props.match.params.cpf
    };

    let response = await api.post("/validarEstudante", dataEstudante);

    if (response.data.message === "Usuário editado com sucesso") {
      setAviso(`Cadastro validado`);
      setEnviado(true);
      return true;
    }

    alert('Não conseguimos completar sua requisição, entre em contato com o suporte.');
  }

  return (
    <S.LoginWrapper>
      <img src={Logo} alt="Curso Danielle Velascos - Login" />
      <S.FromWrapper>
        {!enviado && (
          <>
            <h1>Validar aluno(a)!</h1>
            <p>Confirme os dados abaixo e<br/>valide o cadastro do aluno.</p>
            <p>
              Nome: <strong>{nome}</strong>
            </p>
            <p>
              CPF: <strong>{cpf}</strong>
            </p>
            <p>
              Email: <strong>{email}</strong>
            </p>
            <p>
              Telefone: <strong>{telefone}</strong>
            </p>
            <p>
              Turma: <strong>{turma}</strong>
            </p>
            <form> 
              <button onClick={cadastroHandler}>Validar cadastro</button>
            </form>
          </>
        )}
        <h1>{aviso}</h1>
      </S.FromWrapper>
    </S.LoginWrapper>
  );
}

export default ValidarAluno;
