import React, { FormEvent, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Container,
  Row,
  Col,
  Alert,
  Form,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";

import api from "../../services/api";
import Menu from "../../components/Menu";

import * as S from "./styled";

import { useAuth } from "../../context/auth";

interface UsuarioAuth {
  user?: any;
  token?: String;
  nivel?: String;
  nome?: String;
  internet?: Number;
}

interface Tema {
  content: string;
  name: string;
  category: string;
  description: string;
  id: any;
}

function Temas() {

  const user: UsuarioAuth  = useAuth();

  const [themes, setThemes] = useState<Tema[]>([]);

  const [name, setName] = useState("");
  const [category, setCategory] = useState("");

  const [themeID, setThemeID] = useState(0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [reload, setReload] = useState("");

  const [busca, setBusca] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleShowEdit = () => setShowEdit(true);

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleShowDelete = () => setShowDelete(true);

  const [showSelect, setShowSelect] = useState(false);
  const handleCloseSelect = () => setShowSelect(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleShowSelect = () => setShowSelect(true);


  const [mostrarSelecao, setMostrarSelecao] = useState(false);

  const history = useHistory();

  async function handleAddTheme(e: FormEvent) {
    e.preventDefault();

    const dataTheme = {
      name: name,
      category: category,
    };

    let response = await api.post("/themes", dataTheme);

    handleClose();

    setName("");
    setCategory("");

    setReload(response.data);
  }

  function selectTheme(e) {
    setThemeID(e.target.dataset.id);
    setName(e.target.dataset.nome);
    setShowSelect(true);
  }

  async function handleSelectTheme(e: FormEvent) {
    e.preventDefault();

    const dataTheme = {
      theme_id: themeID,
      nome: name,
    };

    let response = await api.post("/redacoes", dataTheme);

    if (response.status === 200) {
      history.push("/redacoes");
    }

    handleCloseEdit();

    setName("");
    setCategory("");

    setReload(response.data);
  }

  function editTheme(e) {
    setThemeID(e.target.dataset.id);
    setName(e.target.dataset.nome);
    setShowEdit(true);
  }

  async function handleEditTheme(e: FormEvent) {
    e.preventDefault();

    const dataTheme = {
      id: themeID,
      name: name,
      category: category,
    };

    let response = await api.put("/themes", dataTheme);

    handleCloseEdit();

    setName("");
    setCategory("");

    setReload(response.data);
  }

  function deleteTheme(e) {
    setThemeID(e.target.dataset.id);
    setName(e.target.dataset.nome);
    setShowDelete(true);
  }

  async function handleDeleteTheme(e: FormEvent) {
    e.preventDefault();

    let response = await api.delete(`/themes/${themeID}`);

    handleCloseDelete();

    setName("");
    setCategory("");

    setReload(response.data);
  }

  async function handleBusca() {
    try {
      const dataBusca = {
        busca: busca,
      };

      let response = await api.post("/buscaTema", dataBusca);

      setThemes(response.data);
    } catch (err) {
    } finally {
      return false;
    }
  }
  



  const validarQuantidade = async (qtd) => {

    try {
      let response = await api.get(`/redacoes/t`);

      if(user.user?.internet === 1){
        if(response.data.length < qtd){
          setMostrarSelecao(true);
        }else{
          setMostrarSelecao(false);
        }
      }else{
        setMostrarSelecao(true);
      }

    } catch (err) {
      setMostrarSelecao(false);
    } 

  };

  useEffect(() => {
    async function getThemes() {
      try {
        let response = await api.get("/themes");

        setThemes(response.data);

        validarQuantidade(user.user.qtd);
      } catch (err) {

      } finally {
        return false;
      }
    }

    getThemes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <S.MainWrapper>
      <Container fluid>
        <Row>
          <Col sm={2}>
            <Menu />
          </Col>
          <Col sm={10}>
            {user.user.nivel === "a" && (
              <S.MainContent>
                <strong>Administração</strong>
                <Button
                  className="pull-right"
                  variant="success"
                  size="sm"
                  onClick={handleShow}
                >
                  Adicionar Tema
                </Button>

                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Adicionar Tema</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Label>Nome do tema</Form.Label>
                      <Form.Control
                        placeholder="Nome do Tema"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <hr />
                      <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Label>Tipo de categoria</Form.Label>
                        <Form.Control
                          defaultValue={category}
                          as="select"
                          custom
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option>Política</option>
                          <option>Econômia</option>
                          <option>Saúde</option>
                          <option>Segurança</option>
                          <option>Outros</option>
                        </Form.Control>
                      </Form.Group>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleAddTheme}>
                      Adicionar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </S.MainContent>
            )}

            <S.MainContent>
              <Form>
                <Form.Row>
                  <Col sm={10}>
                    <Form.Control
                      onChange={(e) => setBusca(e.target.value)}
                      value={busca}
                      placeholder="Procure um por tema desejado"
                    />
                  </Col>
                  <Col sm={2}>
                    <Button onClick={handleBusca}>
                      <S.SearchIcon />
                      {busca && busca !== "" ? (
                        <Button
                          onClick={() => {
                            setReload(new Date().toString());
                            setBusca("");
                          }}
                        >
                          Limpar Busca
                        </Button>
                      ) : (
                        ""
                      )}
                    </Button>
                  </Col>
                </Form.Row>
              </Form>
            </S.MainContent>

            <S.MainContent>
              <Row>
                <Col>
                  <legend>
                    <strong>Temas</strong>
                  </legend>
                </Col>
              </Row>

              {themes &&
                themes.map((theme, idx) => (
                  <Alert key={idx} variant="primary">
                  <strong>Tema:</strong> {theme.name}<br/>
                  <strong>Categoria:</strong> {theme.category}
                    <div className="pull-right">

                      {mostrarSelecao && 
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tooltip-disabled">
                            Selecionar Tema
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="success"
                          data-id={theme.id}
                          data-nome={theme.name}
                          onClick={selectTheme}
                          className="text-center"
                        >
                          Selecionar
                        </Button>
                      </OverlayTrigger>
                      }
                      
                      {" "}
                      {user.user.nivel === "a" && (
                        <>
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                Editar Tema
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="warning"
                              data-id={theme.id}
                              data-nome={theme.name}
                              onClick={editTheme}
                            >
                              Editar
                            </Button>
                          </OverlayTrigger>{" "}
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tooltip-disabled">
                                Deletar Tema
                              </Tooltip>
                            }
                          >
                            <Button
                              variant="danger"
                              data-id={theme.id}
                              data-nome={theme.name}
                              onClick={deleteTheme}
                            >
                              Deletar
                            </Button>
                          </OverlayTrigger>
                        </>
                      )}
                    </div>
                  </Alert>
                ))}
            </S.MainContent>
          </Col>
        </Row>
      </Container>

      <Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Tema</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label>Nome do tema</Form.Label>
            <Form.Control
              placeholder="Nome do Tema"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <hr />
            <Form.Group controlId="exampleForm.SelectCustom">
              <Form.Label>Tipo de categoria</Form.Label>
              <Form.Control
                defaultValue={category}
                as="select"
                custom
                onChange={(e) => setCategory(e.target.value)}
              >
                <option>Política</option>
                <option>Econômia</option>
                <option>Saúde</option>
                <option>Segurança</option>
                <option>Outros</option>
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseEdit}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleEditTheme}>
            Editar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Deletar Tema</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label>
              Deseja realmente deletar o tema <strong>{name}</strong>
            </Form.Label>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleDeleteTheme}>
            Sim, Deletar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showSelect} onHide={handleCloseSelect}>
        <Modal.Header closeButton>
          <Modal.Title>Seleção de Tema</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Label>
              Deseja realmente escolher o tema <strong>{name}</strong>
            </Form.Label>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseSelect}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={handleSelectTheme}>
            Sim, desejo escolher este tema
          </Button>
        </Modal.Footer>
      </Modal>
    </S.MainWrapper>
  );
}

export default Temas;
