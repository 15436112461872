import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";

import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css";
import api from "../../services/api";
import Template from "../../template";
import { toast } from "react-toastify";

import GravarAudio from "../../components/GravarAudio";

import * as S from "./styled";

import { useAuth } from "../../context/auth";

const Mentoria = (props) => {
  const { user } = useAuth();
  const { socket } = props;

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [horas, setHoras] = useState([]);

  const [dia, setDia] = useState(new Date());
  // eslint-disable-next-line no-unused-vars
  const [hoje, setHoje] = useState(new Date());
  // eslint-disable-next-line no-unused-vars
  const [semana, setSemana] = useState(new Date(Date.now() + 12096e5));

  const [hora, setHora] = useState("");

  const [minhasMonitorias, setMinhasMonitorias] = useState([]);

  const [vaiate, setVaiAte] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [myId, setMyId] = useState(user.id);

  const [totalAtual, setTotalAtual] = useState(0);

  const [monitoriaAtual, setMonitoriaAtual] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [tempo, setTempo] = useState("");

  const [validado, setValidado] = useState(false);

  const [arquivo, setArquivo] = useState(false);
  const [tipo, setTipo] = useState("m");

  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line no-unused-vars
  function validaTempo() {
    try {
      let fimHora = monitoriaAtual.hora.split(":");
      let dataEscolhida = new Date(
        monitoriaAtual.ano,
        monitoriaAtual.mes - 1,
        monitoriaAtual.dia,
        fimHora[0],
        fimHora[1]
      );
      let dataHoje = new Date();
      const newDate = new Date(dataEscolhida);
      newDate.setHours(newDate.getHours() + 2);

      let milliseconds = Math.abs(dataEscolhida - dataHoje);
      let hours = milliseconds / 36e5;

      if (dataHoje.getTime() >= dataEscolhida.getTime() && hours < 2) {
        setValidado(true);

        let distance = newDate.getTime() - dataHoje.getTime();
        // eslint-disable-next-line no-unused-vars
        let horas = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        // eslint-disable-next-line no-unused-vars
        let minutos = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        // eslint-disable-next-line no-unused-vars
        let segundos = Math.floor((distance % (1000 * 60)) / 1000);
        //    setTempo(horas + "h " + minutos + "m" + segundos + "s");
      } else {
        setValidado(false);
      }
    } catch (err) {
    } finally {
      return false;
    }
  }

  function converterData(data) {
    const option = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    var date = new Date(data);
    return date.toLocaleDateString("pt-BR", option);
  }

  async function getMonitoriaPrimeira() {
    try {
      let response = await api.get("/getMonitoriaPrimeira");
      let dadosFinais = response.data[0];

      setMonitoriaAtual(dadosFinais);

      let fimHora = dadosFinais.hora.split(":");
      let dataEscolhida = new Date(
        dadosFinais.ano,
        dadosFinais.mes - 1,
        dadosFinais.dia,
        fimHora[0],
        fimHora[1]
      );

      let dataHoje = new Date();

      const newDate = new Date(dataEscolhida);

      newDate.setHours(newDate.getHours() + 2);

      setVaiAte(newDate);

      let milliseconds = Math.abs(dataEscolhida - dataHoje);
      let hours = milliseconds / 36e5;

      let distance = newDate.getTime() - dataHoje.getTime();
      let horas = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutos = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let segundos = Math.floor((distance % (1000 * 60)) / 1000);
      setTempo(horas + "h " + minutos + "m" + segundos + "s");

      if (dataHoje.getTime() >= dataEscolhida.getTime() && hours < 2) {
        setValidado(true);
      } else {
        setValidado(false);
      }
    } catch (err) {
    } finally {
      return false;
    }
  }

  async function getMinhas() {
    try {
      let response = await api.get("/getMinhas");

      setMinhasMonitorias(response.data.minhas);
      setTotalAtual(response.data.total.length);
    } catch (err) {
    } finally {
      return false;
    }
  }

  async function saveMessage() {
    const dataMensagem = {
      user_id: myId,
      monitor_id: 0,
      message: message.trim(),
      view: 0,
      tipo,
      arquivo,
    };

    try {
      await api.post(`/saveMessage/`, dataMensagem);

      let dia = new Date();

      let arquivoFinal = arquivo ? `${arquivo}` : "";

      socket.emit("chat.message", {
        id: myId,
        user_id: myId,
        monitor_id: 0,
        message,
        view: 0,
        token: user.token,
        created_at: dia,
        arquivo: arquivoFinal,
        tipo,
      });

      setMessage("");
      setTipo("m");
      setLoading(false);
    } catch (err) {
    } finally {
      return false;
    }
  }

  async function uploadFile(e) {
    const data = new FormData();

    if (e.target.files[0]) {
      data.append("arquivo", e.target.files[0]);

      try {
        const response = await api.post("imagemUpload", data);
        setTipo("i");
        setArquivo(response.data.arquivo);
        setMessage("imagem");
        console.log("Arquivo enviado: ", response.data.arquivo);
      } catch (error) {}
    }
  }

  useEffect(() => {
    if (arquivo) {
      setLoading(true);
      setTimeout(() => {
        saveMessage();
      }, 1000);
    }
  }, [arquivo]);

  useEffect(() => {
    getMonitoriaPrimeira();
    validaHoras(new Date(), null);
    getMinhas();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket.on("connect", () => toast('Mentoria Usuário'));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleNewMessage = (newMessage) => {
      if (parseInt(myId) === parseInt(newMessage.user_id)) {
        setMessages([...messages, newMessage]);
      }
    };

    socket.on("chat.message", handleNewMessage);

    return () => socket.off("chat.message", handleNewMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const handleSubmitMessage = (e) => {
    e.preventDefault();

    let diaa = new Date();

    if (message.trim()) {
      saveMessage();

      socket.emit("chat.message", {
        id: myId,
        user_id: myId,
        monitor_id: 0,
        message,
        view: 0,
        token: user.token,
        created_at: diaa,
      });

      setMessage("");
    }
  };

  async function validarDia() {
    const dataHora = {
      dia: dia.getUTCDate(),
      mes: parseInt(dia.getUTCMonth()) + 1,
      ano: dia.getFullYear(),
      hora: hora,
      user_id: myId,
    };

    try {
      // eslint-disable-next-line no-unused-vars
      let response = await api.post("/saveMonitoria", dataHora);

      validaHoras(dia, null);
      getMinhas();
      setHora("");

      alert("Monitoria cadastrada com sucesso");
    } catch (err) {
    } finally {
      return false;
    }
  }

  async function getHorarios(diae = false) {
    let diaEscolhido = diae ? diae : dia;

    const dataHora = {
      dia: diaEscolhido.getDay(),
    };

    try {
      let response = await api.post(`/getHorarios/`, dataHora);

      setHoras(response.data);
    } catch (err) {
    } finally {
      return false;
    }
  }

  async function validaHoras(value, event) {
    setDia(value);
    setHora("");

    getHorarios(value);
  }

  return (
    <Template>
      <S.MainWrapper>
        <Container>
          <Row>
            <Col sm={12}>
              <S.MainContent>
                <div className="alert alert-primary" role="alert">
                  <strong>Monitoria</strong>
                </div>
              </S.MainContent>

              <S.MainContent>
                <Row>
                  <Col xs={12} sm={5}>
                    <p>
                      <strong>Selecione o dia para sua monitoria.</strong>
                    </p>
                    <Calendar
                      onChange={validaHoras}
                      value={dia}
                      minDate={hoje}
                      maxDate={semana}
                    />

                    {totalAtual < 3 ? (
                      <>
                        <p>
                          <strong>Horários Disponíveis</strong>
                        </p>
                        <p>
                          <Form.Control
                            onChange={(e) => setHora(e.target.value)}
                            as="select"
                            className="form-control"
                            custom
                            defaultValue={hora}
                            value={hora}
                          >
                            <option value="">
                              Selecione a hora disponível
                            </option>

                            {horas &&
                              horas.map((hora, index) => (
                                <option
                                  key={index}
                                  value={`${hora.hora}:${hora.minuto}`}
                                >{`${hora.hora}:${hora.minuto}`}</option>
                              ))}
                          </Form.Control>
                        </p>
                        {dia && hora.trim() !== "" && (
                          <p>
                            Você esta escolhendo sua monitoria para{" "}
                            <strong>
                              {dia.getUTCDate()}/
                              {parseInt(hoje.getUTCMonth()) + 1}/
                              {dia.getFullYear()} - {hora}
                            </strong>
                          </p>
                        )}

                        <Button variant="success" onClick={validarDia}>
                          Confirmar Período
                        </Button>
                      </>
                    ) : (
                      <>Limite de monitoria semanal atingido.</>
                    )}

                    <h5>Histórico de Monitórias Marcadas</h5>
                    <ul>
                      {minhasMonitorias &&
                        minhasMonitorias.map((mm, index) => (
                          <li key={index}>
                            {mm.dia}/{mm.mes}/{mm.ano} {mm.hora}
                          </li>
                        ))}
                    </ul>
                  </Col>

                  <Col xs={12} sm={7}>
                    {monitoriaAtual ? (
                      <>
                        {validado ? (
                          <>
                            <p>
                              Sua monitória começou, envie suas dúvidas para que
                              sejam respondidas. Monitor:{" "}
                              <strong className="badge badge-success">
                                ON-LINE
                              </strong>
                              | sua monitoria vai até {converterData(vaiate)}
                            </p>
                          </>
                        ) : (
                          <>
                            <p>
                              Suas mensagens serão enviadas apenas no dia e hora
                              marcados de sua monitoria para o monitor.{" "}
                              {monitoriaAtual.dia}/{monitoriaAtual.mes}/
                              {monitoriaAtual.ano} - {monitoriaAtual.hora}
                              <hr />
                              Monitor:{" "}
                              <strong className="badge badge-danger">
                                OFF-LINE
                              </strong>
                            </p>
                          </>
                        )}

{messages.map((m, index) => (
                          <div
                            className={
                              parseInt(m.monitor_id) === 0 ||
                              parseInt(m.monitorid) === 0
                                ? "alert alert-success"
                                : "alert alert-danger text-right"
                            }
                            key={index}
                          >
                            {m.dataf ? m.dataf : converterData(m.created_at)}
                            <br />

                            {m.tipo === "a" ? (
                              <>
                                {parseInt(m.monitor_id) === 0 ||
                                parseInt(m.monitorid) === 0
                                  ? `Aluno:`
                                  : `Suporte:`}
                                <br />
                                <br />
                                <audio controls="controls">
                                  <source
                                    src={`https://redacoes.nyc3.digitaloceanspaces.com/${m.arquivo}`}
                                    type="audio/mp3"
                                  />
                                  Seu navegador não tem suporte para áudio.
                                </audio>
                              </>
                            ) : (
                              <>
                                {m.tipo === "i" ? (
                                  <>
                                    {parseInt(m.monitor_id) === 0 ||
                                    parseInt(m.monitorid) === 0
                                      ? `Aluno:`
                                      : `Suporte:`}
                                    <br />
                                    <br />
                                    <img
                                      loading="lazy"
                                      src={`https://redacoes.nyc3.digitaloceanspaces.com/${m.arquivo}`}
                                      alt={m.monitorid}
                                      className="img-fluid"
                                    />
                                  </>
                                ) : (
                                  <>
                                    {parseInt(m.monitor_id) === 0 ||
                                    parseInt(m.monitorid) === 0
                                      ? `Aluno: ${m.message}`
                                      : `Suporte: ${m.message}`}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        ))}

                        <Row>
                          <Col>
                            <p>
                              <input
                                type="text"
                                placeholder="Digite sua menasgem / dúvida"
                                onChange={(e) => setMessage(e.target.value)}
                                className="form-control"
                                value={message}
                              />
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={9}>
                            <button
                              onClick={saveMessage}
                              className="btn btn-primary"
                              type="button"
                            >
                              Enviar Mensagem
                            </button>
                          </Col>
                          <Col xs={3} className="text-right">
                            <label>
                              <input
                                onChange={uploadFile}
                                onClick={() => setTipo("i")}
                                className="hideinput"
                                type="file"
                              />
                              <div className="btn btn-info text-center">
                                <S.AttachmentIcon />
                              </div>
                            </label>{" "}
                            <GravarAudio
                              loading={loading}
                              setArquivo={setArquivo}
                              setTipo={setTipo}
                            />
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <p>
                        Você não possui nenhuma monitoria agendada para os
                        próximos dias.
                      </p>
                    )}
                  </Col>
                </Row>
              </S.MainContent>
            </Col>
          </Row>
        </Container>
      </S.MainWrapper>
    </Template>
  );
};

export default Mentoria;
