import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Container, Row, Col, Button } from "react-bootstrap";
import api from "../../services/api";
import Template from "../../template";
import * as S from "./styled";
import InputMask from "react-input-mask";
import { useAuth } from "../../context/auth";
import Grafico from "./Grafico";
import Grafico2 from "./Grafico2";

function Medias(props) {
  const date1 = new Date();

  const { user } = useAuth();

  const [mes, setMes] = useState(date1.getMonth() + 1);
  const [ano, setAno] = useState(date1.getFullYear());
  const [loading, setLoading] = useState(true);
  const [media, setMedia] = useState([]);
  const [medias, setMedias] = useState([]);

  const [pessoa, setPessoa] = useState(user.nome);

  const [media1, setMedia1] = useState(0);
  const [media2, setMedia2] = useState(0);
  const [media3, setMedia3] = useState(0);
  const [media4, setMedia4] = useState(0);
  const [media5, setMedia5] = useState(0);
  const [media6, setMedia6] = useState(0);
  const [media11, setMedia11] = useState(0);
  const [media12, setMedia12] = useState(0);
  const [media13, setMedia13] = useState(0);
  const [media14, setMedia14] = useState(0);
  const [media15, setMedia15] = useState(0);
  const [media16, setMedia16] = useState(0);

  const [nota1, setNota1] = useState(false);
  const [nota2, setNota2] = useState(false);

  const [idAluno, setIdAluno] = useState(user.id);

  const [dataEntrega, setDataEntrega] = useState("");

  const [alunos, setAlunos] = useState();

  async function pegarUser() {
    const data = { id: idAluno };

    try {
      const response = await api.post(`/pegarUser`, data);
      setPessoa(response.data[0].name);
    } catch (error) {
      toast.error("Erro ao carregar aluno");
    }
  }

  function mostrarDataNova() {
    let novadata = dataEntrega.split("/");
    setMes(parseInt(novadata[0]));
    setAno(novadata[1]);
    pegarUser();

    toast.info("Carregando média mensal...");

    getMedias();
    getMedias2();
  }

  useEffect(() => {
    async function getAlunos() {
      try {
        const retorno = await api.get(`/usuarios`);

        setAlunos(retorno.data);
        setLoading(false);
      } catch (err) {
        toast.error("Erro ao recuperar alunos!");
      }
    }

    getAlunos();
  }, []);

  async function getMedias() {

    console.log('id aluno ', idAluno);

    try {
      let response = await api.get(`/getMedia/${ano}/${mes}/${idAluno}/0`);


      if(response.data.length > 0){
        setNota1(true);
      }

      let nota1 = 0;
      let nota2 = 0;
      let nota3 = 0;
      let nota4 = 0;
      let nota5 = 0;
      let nota6 = 0;
      let qtd = 0;

      response.data.forEach((redacao) => {
        qtd = qtd + 1;
        if (
          redacao.competencia1 &&
          redacao.competencia2 &&
          redacao.competencia3 &&
          redacao.competencia4 &&
          redacao.competencia5
        ) {
          nota1 = parseInt(redacao.competencia1) + nota1;
          nota2 = parseInt(redacao.competencia2) + nota2;
          nota3 = parseInt(redacao.competencia3) + nota3;
          nota4 = parseInt(redacao.competencia4) + nota4;
          nota5 = parseInt(redacao.competencia5) + nota5;
          nota6 =
            parseInt(redacao.competencia1) +
            parseInt(redacao.competencia2) +
            parseInt(redacao.competencia3) +
            parseInt(redacao.competencia4) +
            parseInt(redacao.competencia5) +
            nota6;
        } else {
          nota1 = 0;
          nota2 = 0;
          nota3 = 0;
          nota4 = 0;
          nota5 = 0;
          nota6 = 0;
        }
      });

      setMedia1(Math.round(nota1 / qtd));
      setMedia2(Math.round(nota2 / qtd));
      setMedia3(Math.round(nota3 / qtd));
      setMedia4(Math.round(nota4 / qtd));
      setMedia5(Math.round(nota5 / qtd));
      setMedia6(Math.round(nota6 / qtd));

      setLoading(false);
    } catch (err) {
    } finally {
      return false;
    }
  }

  async function getMedias2() {
    toast.info("Carregando média mensal...");

    try {
      let response = await api.get(`/getMedia/${ano}/${mes}/${idAluno}/1`);

      if(response.data.length > 0){
        setNota2(true);
      }

      let nota1 = 0;
      let nota2 = 0;
      let nota3 = 0;
      let nota4 = 0;
      let nota5 = 0;
      let nota6 = 0;
      let qtd = 0;

      response.data.forEach((redacao) => {
        qtd = qtd + 1;
        if (
          redacao.competencia1 &&
          redacao.competencia2 &&
          redacao.competencia3 &&
          redacao.competencia4 &&
          redacao.competencia5
        ) {
          nota1 = parseInt(redacao.competencia1) + nota1;
          nota2 = parseInt(redacao.competencia2) + nota2;
          nota3 = parseInt(redacao.competencia3) + nota3;
          nota4 = parseInt(redacao.competencia4) + nota4;
          nota5 = parseInt(redacao.competencia5) + nota5;
          nota6 =
            parseInt(redacao.competencia1) +
            parseInt(redacao.competencia2) +
            parseInt(redacao.competencia3) +
            parseInt(redacao.competencia4) +
            parseInt(redacao.competencia5) +
            nota6;
        } else {
          nota1 = 0;
          nota2 = 0;
          nota3 = 0;
          nota4 = 0;
          nota5 = 0;
          nota6 = 0;
        }
      });

      setMedia11(Math.round(nota1 / qtd));
      setMedia12(Math.round(nota2 / qtd));
      setMedia13(Math.round(nota3 / qtd));
      setMedia14(Math.round(nota4 / qtd));
      setMedia15(Math.round(nota5 / qtd));
      setMedia16(Math.round(nota6 / qtd));

      setLoading(false);
    } catch (err) {
    } finally {
      return false;
    }
  }

  useEffect(() => {
    toast.info("Carregando média mensal...");
    getMedias();
    getMedias2();
  }, []);

  return (
    <Template>
      <S.MainWrapper>
        <Container>
          {loading ? (
            <Row>
              <Col className="text-center">
                <div className="shadow p-3 mb-4 bg-white rounded fdark flex-c">
                  <div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-secondary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-success" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-danger" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-warning" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-info" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div>
                    <strong>CARREGANDO</strong>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <>
              {media && (
                <>
                  <Row>
                    <Col>
                      <div className="shadow-sm p-3 mt-4 bg-white rounded fdark">
                        <strong>
                          Média final de <strong>{pessoa}</strong> no mês/ano (
                          {mes}/{ano})
                        </strong>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="shadow-sm p-3 mb-44 bg-white rounded fdark">
                        {user.nivel === "a" && (
                          <>
                            <select
                              onChange={(e) => setIdAluno(e.target.value)}
                              className="form-control"
                            >
                              <option value="0">Filtrar por Aluno</option>
                              {alunos &&
                                alunos.map((aluno, index) => (
                                  <option key={index} value={aluno.id}>
                                    {aluno.name}
                                  </option>
                                ))}
                            </select>
                          </>
                        )}
                        <InputMask
                          className="form-control"
                          placeholder="Escolha a data mês e ano **/****"
                          mask="99/9999"
                          maskChar={null}
                          value={dataEntrega}
                          onChange={(e) => setDataEntrega(e.target.value)}
                        />
                        <Button
                          onClick={(e) => {
                            mostrarDataNova();
                            setLoading(true);
                          }}
                        >
                          Mostrar
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <div className="shadow-sm p-3 mb-4 bg-white rounded fdark">
                        Cada competência tem o valor máximo de 200 pontos,
                        abaixo você poderá ver qual sua média de pontos em cada
                        competência
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    {nota1 && (
                          <Col xs={12} lg={6}>
                            <legend>Minha média para o ENEM</legend>
                            <div className="shadow-sm p-3 mb-4 bg-white rounded fdark">
                              <Grafico
                                media1={media1}
                                media2={media2}
                                media3={media3}
                                media4={media4}
                                media5={media5}
                              />
                            </div>
                          </Col>
                        )}
                    {nota2 && (
                          <Col xs={12} lg={6}>
                            <legend>Minha média</legend>
                            <div className="shadow-sm p-3 mb-4 bg-white rounded fdark">
                              <Grafico2
                                media1={media11}
                                media2={media12}
                                media3={media13}
                                media4={media14}
                                media5={media15}
                              />
                            </div>
                          </Col>
                        )}
                  </Row>
                </>
              )}
            </>
          )}
        </Container>
      </S.MainWrapper>
    </Template>
  );
}

export default Medias;
