import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import api from "../../services/api";
import Template from "../../template";

import { useAuth } from "../../context/auth";

import CarregarPDFAluno from "../../components/CarregarPDFAluno";
import { toast } from "react-toastify";

import * as S from "./styled";

function CheckRedacoes(props) {
  const { id } = props.match.params;

  const { user } = useAuth();

  const [dadosRedacao, setDadosRedacao] = useState([]);
  const [initialHeight, setInitialHiehgt] = useState();
  const [highlights, setHighlights] = useState([]);


  async function getFirstCorrection(){
    try {
      const retorno = await api.get(`/pegar-primeira-correcao/${id}`);

      const valores = retorno.data.map((item, idx) => {
        const conteudo = JSON.parse(item.conteudo);
        return conteudo.position.boundingRect.height;
      });

      setInitialHiehgt(valores[0]);
      getCorrecaoNova(valores[0]);
    } catch (err) {
    } finally {
      
    }
  }

  async function getCorrecaoNova(heightInicial = false) {
    try {
      const retorno = await api.get(`/pegar-correcao/${id}`);

      const valores = retorno.data.map((item, idx) => {
        const conteudo = JSON.parse(item.conteudo);
        conteudo.id = item.id;
        if(heightInicial){
          conteudo.position.boundingRect.height = heightInicial + 25;
        }

        if(initialHeight){
          conteudo.position.boundingRect.height = initialHeight + 25;
        }
        return conteudo;
      });

    
      setHighlights(valores);
    } catch (err) {
    } finally {
      return false;
    }
  }

  useEffect(() => {
    
    async function getPDF() {
      try {
        const data = {
          id: id,
        };

        const retorno = await api.post("/getCorrecao", data);
        console.log(user.nivel);
       
        if(retorno.data.dadosRedacao[0].user_id !== user.id && user.nivel != 'a'){
          toast.error('Você não tem permissão para acessar essa página');
          setDadosRedacao([]);
        }else{
          setDadosRedacao(retorno.data.dadosRedacao);
        }

        
      } catch (error) {}
    }

    getPDF();
    getFirstCorrection();
 
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Template>
      <S.MainWrapper>
        <Container>
          <Row>
            <Col sm={12}>
              <S.MainContent>
                <Row>
                  <Col>
                    <>
                      {dadosRedacao.length >= 1 && (
                        <CarregarPDFAluno
                          pdf={`${dadosRedacao[0].pdf}`}
                          id={id}
                          dadosRedacao={dadosRedacao}
                          highlights={highlights}
                        />
                      )}
                    </>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {dadosRedacao[0] && (
                      <>
                        <table className="table table-striped table-bordered">
                          <tr>
                            <td
                              className={`clicavel ${
                                dadosRedacao[0] &&
                                dadosRedacao[0].falta === "1" &&
                                "marcado"
                              }`}
                            >
                              Texto Insuficiente
                            </td>
                            <td
                              className={`clicavel ${
                                dadosRedacao[0] &&
                                dadosRedacao[0].falta === "2" &&
                                "marcado"
                              }`}
                            >
                              Cópia
                            </td>
                            <td
                              className={`clicavel ${
                                dadosRedacao[0] &&
                                dadosRedacao[0].falta === "3" &&
                                "marcado"
                              }`}
                            >
                              Fuga ao Tema
                            </td>
                            <td
                              className={`clicavel ${
                                dadosRedacao[0] &&
                                dadosRedacao[0].falta === "4" &&
                                "marcado"
                              }`}
                            >
                              Fuga ao Gênero
                            </td>
                            <td
                              className={`clicavel ${
                                dadosRedacao[0] &&
                                dadosRedacao[0].falta === "5" &&
                                "marcado"
                              }`}
                            >
                              Fere Direitos Humanos
                            </td>
                            <td
                              className={`clicavel ${
                                dadosRedacao[0] &&
                                dadosRedacao[0].falta === "6" &&
                                "marcado"
                              }`}
                            >
                              Em Branco
                            </td>
                            <td
                              className={`clicavel ${
                                dadosRedacao[0] &&
                                dadosRedacao[0].falta === "7" &&
                                "marcado"
                              }`}
                            >
                              Outras Formas de Anulação
                            </td>
                          </tr>
                        </table>
                        <table className="table table-striped table-bordered">
                          <tr>
                            <td>
                              <h3>RESERVADO AO CORRETOR</h3>
                            </td>
                            <td>
                              N<sup>0</sup>
                              <br />0
                            </td>
                            <td>
                              N<sup>1</sup>
                              <br />
                              40
                            </td>
                            <td>
                              N<sup>2</sup>
                              <br />
                              80
                            </td>
                            <td>
                              N<sup>3</sup>
                              <br />
                              120
                            </td>
                            <td>
                              N<sup>4</sup>
                              <br />
                              160
                            </td>
                            <td>
                              N<sup>5</sup>
                              <br />
                              200
                            </td>
                          </tr>
                          <tr>
                            <td>
                              1 - Demonstrar domínio da norma culta da língua
                              escrita.{" "}
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia1) === 0
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia1) === 0 &&
                                  `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia1) === 40
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia1) ===
                                  40 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia1) === 80
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia1) ===
                                  80 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia1) === 120
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia1) ===
                                  120 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia1) === 160
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia1) ===
                                  160 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia1) === 200
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia1) ===
                                  200 && `X`}
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              2 - Compreender a proposta de redação e aplicar
                              conceitos de várias áreas de conhecimento para
                              desenvolver o tema, dentro dos limites estruturais
                              do texto dissertativo-argumentativo.
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia2) === 0
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia2) === 0 &&
                                  `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia2) === 40
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia2) ===
                                  40 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia2) === 80
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia2) ===
                                  80 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia2) === 120
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia2) ===
                                  120 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia2) === 160
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia2) ===
                                  160 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia2) === 200
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia2) ===
                                  200 && `X`}
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              3 - Selecionar, relacionar, organizar, e
                              interpretar informações, fatos, opiniões e
                              argumentos em defesa do ponto de vista.
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia3) === 0
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia3) === 0 &&
                                  `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia3) === 40
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia3) ===
                                  40 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia3) === 80
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia3) ===
                                  80 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia3) === 120
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia3) ===
                                  120 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia3) === 160
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia3) ===
                                  160 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia3) === 200
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia3) ===
                                  200 && `X`}
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              4 - Demonstrar conhecimento dos mecanismos
                              linguísticos necessários para a construção da
                              argumentação
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia4) === 0
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia4) === 0 &&
                                  `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia4) === 40
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia4) ===
                                  40 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia4) === 80
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia4) ===
                                  80 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia4) === 120
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia4) ===
                                  120 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia4) === 160
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia4) ===
                                  160 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia4) === 200
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia4) ===
                                  200 && `X`}
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              5 - Elaborar proposta de solução para o problema
                              abordado, mostrando respeito aos valores humanos e
                              considerando a diversidade sociocultural.
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia5) === 0
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia5) === 0 &&
                                  `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia5) === 40
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia5) ===
                                  40 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia5) === 80
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia5) ===
                                  80 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia5) === 120
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia5) ===
                                  120 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia5) === 160
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia5) ===
                                  160 && `X`}
                              </button>
                            </td>
                            <td>
                              <button
                                className={
                                  parseInt(dadosRedacao[0].competencia5) === 200
                                    ? `botaoNota ativo`
                                    : "botaoNota"
                                }
                              >
                                {parseInt(dadosRedacao[0].competencia5) ===
                                  200 && `X`}
                              </button>
                            </td>
                          </tr>
                        </table>

                        <hr />

                        <table className="table table-striped table-bordered">
                          <tr>
                            <td className="text-right">
                              <h3>
                                <strong>Total: </strong>{" "}
                                {dadosRedacao[0] && dadosRedacao[0].falta ? (
                                  <>0</>
                                ) : (
                                  <>
                                    {parseInt(dadosRedacao[0].competencia1) +
                                      parseInt(dadosRedacao[0].competencia2) +
                                      parseInt(dadosRedacao[0].competencia3) +
                                      parseInt(dadosRedacao[0].competencia4) +
                                      parseInt(dadosRedacao[0].competencia5)}
                                  </>
                                )}
                              </h3>
                            </td>
                          </tr>
                        </table>

                        <hr />

                        <table className="table table-striped table-bordered">
                          <tr>
                            <td>{dadosRedacao[0].observacao}</td>
                          </tr>
                        </table>

                        <h4 className="text-center">
                          Rua Gavião Peixoto 124- Salas 508 e 509 -Edifício
                          Manhattan - 2601-0195/ 98701-0195
                          <br />
                          cursodaniellevelasco@gmail.com{" "}
                        </h4>
                      </>
                    )}
                  </Col>
                </Row>
              </S.MainContent>
            </Col>
          </Row>
        </Container>
      </S.MainWrapper>
    </Template>
  );
}

export default CheckRedacoes;
