import React, { useState, useEffect, FormEvent } from "react";
import { useHistory } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Tooltip,
  Button,
  Form,
  Modal,
  OverlayTrigger,
} from "react-bootstrap";

import api from "../../services/api";
import Template from "../../template";
import { toast } from "react-toastify";

import * as S from "./styled";

import { useAuth } from "../../context/auth";

interface UsuarioAuth {
  user?: any;
  token?: String;
  nivel?: String;
  nome?: String;
  telefone?: String;
  cpf?: String | Number;
}

interface Usuario {
  name: string;
  password: string;
  email: string;
  phone: string;
  type: string;
  turma: string;
  telefone?: string | Number;
  cpf?: string | Number;
  id: number;
  ativado: number;
}

interface Turmas {
  type: string;
  name: string;
  description: string;
  id: number;
}

interface Pack {
  nome: string;
  qtd: string;
  sobra: string;
  id: number;
}

interface Redacao {
  aluno: string;
  nome: string;
  competencia1?: any;
  competencia2?: any;
  competencia3?: any;
  competencia4?: any;
  competencia5?: any;
}

function Usuarios() {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const user: UsuarioAuth = useAuth();
  const [usuarios, setUsuarios] = useState<Usuario[]>([]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const history = useHistory();

  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCPF] = useState("");
  const [phone, setPhone] = useState("");
  const [type, setType] = useState("");
  const [userID, setUserId] = useState("");
  const [ativado, setAtivado] = useState(1);

  const [busca, setBusca] = useState("");

  const [reload, setReload] = useState("");

  const [turmaID, setTurmaID] = useState("");
  const [filtraTurma, setFiltraTurma] = useState("");
  const [turmas, setTurmas] = useState<Turmas[]>([]);

  const [pacotes, setPacotes] = useState<Pack[]>([]);
  const [redacoes, setRedacoes] = useState<Redacao[]>([]);

  const [media1, setMedia1] = useState(0);
  const [media2, setMedia2] = useState(0);
  const [media3, setMedia3] = useState(0);
  const [media4, setMedia4] = useState(0);
  const [media5, setMedia5] = useState(0);
  const [media6, setMedia6] = useState(0);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showEdit, setShowEdit] = useState(false);
  const handleCloseEdit = () => setShowEdit(false);

  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);

  const [showPacote, setShowPacote] = useState(false);
  const handleClosePacote = () => setShowPacote(false);

  const [showRedacao, setShowRedacao] = useState(false);
  const handleCloseRedacao = () => setShowRedacao(false);

  async function handleAddUser(e: FormEvent) {
    e.preventDefault();

    if (password.length <= 3 && passwordConfirm.length <= 3) {
      toast.error(
        "Preencha a senha e sua confirmação, elas devem ser identicas"
      );
      return false;
    }

    if (password && passwordConfirm) {
      alert("As senhas não podem ser em branco");
      return false;
    }

    const dataUser = {
      name: name,
      password: password,
      email: email,
      phone: phone,
      type: type,
      cpf: cpf,
      turma_id: turmaID,
      ativado: ativado,
    };

    let response = await api.post("/usuarios", dataUser);

    handleClose();

    setName("");
    setPassword("");
    setEmail("");
    setPhone("");
    setType("");
    setCPF("");
    setTurmaID("");

    setReload(response.data);
  }

  const buscarPorTurma = async () => {
    const dataFiltro = {
      id_turma: filtraTurma,
    };

    try {
      let response = await api.post("/filtrar-alunos", dataFiltro);

      setUsuarios(response.data);
    } catch (err) {
    } finally {
      return false;
    }
  };

  async function filterUser(tipo) {
    const dataFiltro = {
      filtro: tipo,
    };

    try {
      let response = await api.post("/filterUsuarios", dataFiltro);

      setUsuarios(response.data);
    } catch (err) {
    } finally {
      return false;
    }
  }

  async function handleBusca() {
    try {
      const dataBusca = {
        busca: busca,
      };

      let response = await api.post("/buscaUser", dataBusca);

      setUsuarios(response.data);
    } catch (err) {
    } finally {
      return false;
    }
  }

  async function getPacote(e) {
    const dataUserId = {
      id: e.target.dataset.id,
    };

    let response = await api.post("/getPacote", dataUserId);

    setPacotes(response.data);
    setShowPacote(true);
  }

  async function getRedacoes(e) {
    const dataUserId = {
      id: e.target.dataset.id,
    };

    let response = await api.post("/getRedacoes", dataUserId);

    toast("Apenas redações já selecionadas pelo aluno serão exibidas.");

    let nota1 = 0;
    let nota2 = 0;
    let nota3 = 0;
    let nota4 = 0;
    let nota5 = 0;
    let nota6 = 0;
    let qtd = 0;

    response.data.redacoes.forEach((redacao) => {
      qtd = qtd + 1;
      if (
        redacao.competencia1 &&
        redacao.competencia2 &&
        redacao.competencia3 &&
        redacao.competencia4 &&
        redacao.competencia5
      ) {
        nota1 = parseInt(redacao.competencia1) + nota1;
        nota2 = parseInt(redacao.competencia2) + nota2;
        nota3 = parseInt(redacao.competencia3) + nota3;
        nota4 = parseInt(redacao.competencia4) + nota4;
        nota5 = parseInt(redacao.competencia5) + nota5;
        nota6 =
          parseInt(redacao.competencia1) +
          parseInt(redacao.competencia2) +
          parseInt(redacao.competencia3) +
          parseInt(redacao.competencia4) +
          parseInt(redacao.competencia5) +
          nota6;
      } else {
        nota1 = 0;
        nota2 = 0;
        nota3 = 0;
        nota4 = 0;
        nota5 = 0;
        nota6 = 0;
      }
    });

    setMedia1(Math.round(nota1 / qtd));
    setMedia2(Math.round(nota2 / qtd));
    setMedia3(Math.round(nota3 / qtd));
    setMedia4(Math.round(nota4 / qtd));
    setMedia5(Math.round(nota5 / qtd));
    setMedia6(Math.round(nota6 / qtd));

    setRedacoes(response.data.redacoes);
    setShowRedacao(true);
  }

  async function editUser(e) {
    setUserId(e.target.dataset.id);

    const dataUserId = {
      id: e.target.dataset.id,
    };

    let response = await api.post("/pegarUser", dataUserId);

    setName(response.data[0].name);
    setEmail(response.data[0].email);
    setPhone(response.data[0].phone);
    setType(response.data[0].type);
    setTurmaID(response.data[0].turma_id);
    setAtivado(response.data[0].ativado);

    setShowEdit(true);
  }

  async function handleEditUser(e: FormEvent) {
    e.preventDefault();

    let dataUser = {};

    if (password === "" && passwordConfirm === "") {
      dataUser = {
        name: name,
        email: email,
        phone: phone,
        type: type,
        id: userID,
        turma_id: turmaID,
        ativado: ativado,
      };
    } else if (
      password === passwordConfirm &&
      password.length >= 3 &&
      passwordConfirm.length >= 3
    ) {
      dataUser = {
        name: name,
        password: password,
        email: email,
        phone: phone,
        type: type,
        id: userID,
        turma_id: turmaID,
        ativado: ativado,
      };
    }

    let response = await api.put("/editUser", dataUser);

    handleCloseEdit();

    setName("");
    setPassword("");
    setPasswordConfirm("");
    setEmail("");
    setPhone("");
    setType("");

    setReload(response.data);
  }

  function deleteUser(e) {
    setUserId(e.target.dataset.id);
    setName(e.target.dataset.nome);
    setShowDelete(true);
  }

  async function handleDeleteUser(e: FormEvent) {
    e.preventDefault();

    let response = await api.delete(`/deleteUser/${userID}`);

    handleCloseDelete();

    setName("");

    setReload(response.data);
  }

  function getType(type) {
    if (type === "a") {
      return "Admin";
    } else if (type === "m") {
      return "Monitor";
    } else if (type === "s") {
      return "Suporte";
    } else if (type === "c") {
      return "Corretor";
    } else {
      return "Estudante";
    }
  }

  useEffect(() => {
    async function getUsuarios() {
      try {
        let response = await api.get("/usuarios");

        setUsuarios(response.data);
      } catch (err) {
      } finally {
        return false;
      }
    }

    async function getTurmas() {
      try {
        let response = await api.get("/classes");

        setTurmas(response.data);
      } catch (err) {
      } finally {
        return false;
      }
    }
    getTurmas();
    getUsuarios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <Template>
      <S.MainWrapper>
        <Container>
          <Row>
            <Col sm={12}>
              <S.MainContent>
                <strong>Administração</strong>
                <Button
                  className="pull-right"
                  variant="success"
                  size="sm"
                  onClick={handleShow}
                >
                  Adicionar Usuário
                </Button>

                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Adicionar Usuário</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Control
                        placeholder="Nome do Usuário"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <hr />

                      <Form.Control
                        onChange={(e) => setType(e.target.value)}
                        as="select"
                        custom
                      >
                        <option value="Nível de Acesso">Nível de Acesso</option>
                        <option value="a">Admin</option>
                        <option value="m">Monitor</option>
                        <option value="e">Estudante</option>
                        <option value="s">Suporte</option>
                        <option value="c">Corretor</option>
                      </Form.Control>

                      <hr />

                      <Form.Control
                        onChange={(e) => setAtivado(parseInt(e.target.value))}
                        as="select"
                        custom
                        defaultValue={ativado}
                      >
                        <option value="Ativo">Ativar/Desativar aluno</option>
                        <option value="1">Ativo</option>
                        <option value="0">Inativo</option>
                      </Form.Control>

                      <hr />

                      <Form.Control
                        onChange={(e) => setTurmaID(e.target.value)}
                        as="select"
                        custom
                      >
                        <option value="Turmas">Turmas</option>
                        {turmas &&
                          turmas.map((turma, idx) => (
                            <option value={turma.id}>{turma.name}</option>
                          ))}
                      </Form.Control>

                      <hr />

                      <Form.Control
                        placeholder="E-mail do Usuário"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                      <hr />

                      <Form.Control
                        placeholder="Telefone do Usuário"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                      <hr />

                      <Form.Control
                        placeholder="CPF do Usuário - Sem pontos, sem traços!"
                        value={cpf}
                        onChange={(e) => setCPF(e.target.value)}
                      />
                      <hr />

                      <Form.Control
                        placeholder="Senha do Usuário"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <hr />
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleAddUser}>
                      Adicionar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </S.MainContent>

              <S.MainContent>
                <Form>
                  <Form.Row>
                    <Col sm={10}>
                      <Form.Control
                        onChange={(e) => setBusca(e.target.value)}
                        value={busca}
                        placeholder="Procure por um usuário digitando seu nome ou cpf"
                      />
                    </Col>
                    <Col sm={2}>
                      <Button onClick={handleBusca}>
                        <S.SearchIcon />
                        {busca && busca !== "" ? (
                          <Button
                            onClick={() => {
                              setReload(new Date().toString());
                              setBusca("");
                            }}
                          >
                            Limpar Busca
                          </Button>
                        ) : (
                          ""
                        )}
                      </Button>
                    </Col>
                  </Form.Row>
                </Form>
              </S.MainContent>

              <S.MainContent>
                <Form>
                  <Form.Row>
                    <Col sm={10}>
                      <select
                        onChange={(e) => setFiltraTurma(e.target.value)}
                        className="form-control"
                      >
                        {turmas && turmas.length > 1 && (
                          <option>Filtrar por turma</option>
                        )}
                        {turmas &&
                          turmas.map((turma, index) => (
                            <option key={index} value={turma.id}>
                              {turma.name}
                            </option>
                          ))}
                      </select>
                    </Col>
                    <Col sm={2}>
                      <Button onClick={buscarPorTurma}>
                        <S.SearchIcon />
                        Exibir alunos
                      </Button>
                    </Col>
                  </Form.Row>
                </Form>
              </S.MainContent>

              <S.MainContent>
                <Row>
                  <Col>
                    <legend>
                      <strong>Usuários</strong>
                      <span className="pull-right">
                        <strong>Filtro:</strong>{" "}
                        <span
                          onClick={() => filterUser("a")}
                          className="btn btn-info"
                        >
                          Admin
                        </span>{" "}
                        <span
                          onClick={() => filterUser("m")}
                          className="btn btn-info"
                        >
                          Monitor
                        </span>{" "}
                        <span
                          onClick={() => filterUser("s")}
                          className="btn btn-info"
                        >
                          Suporte
                        </span>{" "}
                        <span
                          onClick={() => filterUser("c")}
                          className="btn btn-info"
                        >
                          Corretor
                        </span>{" "}
                        <span
                          onClick={() => filterUser("e")}
                          className="btn btn-info"
                        >
                          Estudante
                        </span>{" "}
                        <span
                          onClick={() => setReload(reload + 1)}
                          className="btn btn-info"
                        >
                          Todos
                        </span>{" "}
                        <span
                          onClick={() => filterUser("nao")}
                          className="btn btn-info"
                        >
                          Não Ativos
                        </span>
                      </span>
                    </legend>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <hr />
                    <table className="table table-bordered">
                      <tr>
                        <td>Nome</td>
                        <td>E-mail</td>
                        <td>Nível de Acesso</td>
                        <td>Turma</td>
                        <td>CPF</td>
                        <td>Telefone</td>
                        <td>Status</td>
                        <td>Ações</td>
                      </tr>
                      {usuarios &&
                        usuarios.map((usuario, idx) => (
                          <tr>
                            <td>
                              <strong>{usuario.name}</strong>
                            </td>
                            <td>
                              <strong>{usuario.email}</strong>
                            </td>
                            <td>
                              <strong>{getType(usuario.type)}</strong>
                            </td>
                            <td>
                              <strong>{usuario.turma}</strong>
                            </td>
                            <td>
                              <strong>{usuario.cpf}</strong>
                            </td>
                            <td>
                              <strong>{usuario.phone}</strong>
                            </td>
                            <td>
                              <strong>
                                {usuario.ativado === 1 ? "Ativo" : "Inativo"}
                              </strong>
                            </td>
                            <td>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Redações do Aluno
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="success"
                                  data-id={usuario.id}
                                  onClick={getRedacoes}
                                >
                                  Redações
                                </Button>
                              </OverlayTrigger>
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Pacotes do Usuário
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="success"
                                  data-id={usuario.id}
                                  onClick={getPacote}
                                >
                                  Pacotes
                                </Button>
                              </OverlayTrigger>{" "}
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Editar Usuário
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="warning"
                                  data-id={usuario.id}
                                  onClick={editUser}
                                >
                                  Editar
                                </Button>
                              </OverlayTrigger>{" "}
                              <OverlayTrigger
                                overlay={
                                  <Tooltip id="tooltip-disabled">
                                    Deletar Usuário
                                  </Tooltip>
                                }
                              >
                                <Button
                                  variant="danger"
                                  data-id={usuario.id}
                                  data-nome={usuario.name}
                                  onClick={deleteUser}
                                >
                                  Deletar
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))}
                    </table>
                  </Col>
                </Row>
              </S.MainContent>
            </Col>
          </Row>
        </Container>

        <Modal show={showRedacao} onHide={handleCloseRedacao}>
          <Modal.Header closeButton>
            <Modal.Title>Redações do Aluno</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {redacoes.length >= 1 && (
              <>
                <table className="table">
                  <tbody>
                    {redacoes.map((redacao, idx) => (
                      <>
                        <tr>
                          <th colSpan={7}>{redacao.nome}</th>
                        </tr>
                        <tr>
                          <th scope="col">Aluno</th>
                          <th scope="col">C1</th>
                          <th scope="col">C2</th>
                          <th scope="col">C3</th>
                          <th scope="col">C4</th>
                          <th scope="col">C5</th>
                          <th scope="col">TOTAL</th>
                        </tr>
                        <tr key={idx}>
                          <th scope="row">{redacao.aluno}</th>
                          <td>{redacao.competencia1}</td>
                          <td>{redacao.competencia2}</td>
                          <td>{redacao.competencia3}</td>
                          <td>{redacao.competencia4}</td>
                          <td>{redacao.competencia5}</td>
                          {redacao.competencia1 &&
                            redacao.competencia2 &&
                            redacao.competencia3 &&
                            redacao.competencia4 &&
                            redacao.competencia5 && (
                              <>
                                <td>
                                  {parseInt(redacao.competencia1) +
                                    parseInt(redacao.competencia2) +
                                    parseInt(redacao.competencia3) +
                                    parseInt(redacao.competencia4) +
                                    parseInt(redacao.competencia5)}
                                </td>
                              </>
                            )}
                        </tr>
                        <tr>
                          <td colSpan={7}>&nbsp;</td>
                        </tr>
                      </>
                    ))}
                   
                  </tbody>
                </table>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseRedacao}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showPacote} onHide={handleClosePacote}>
          <Modal.Header closeButton>
            <Modal.Title>Pacotes Contratados</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <table className="table table-bordered">
              <tr>
                <td>Pacote Contratado</td>
                <td>Quantidade</td>
                <td>Disponível</td>
              </tr>
              {pacotes &&
                pacotes.map((pacote, idx) => (
                  <tr>
                    <td>{pacote.nome}</td>
                    <td>{pacote.qtd}</td>
                    <td>{pacote.sobra}</td>
                  </tr>
                ))}
            </table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClosePacote}>
              Fechar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showDelete} onHide={handleCloseDelete}>
          <Modal.Header closeButton>
            <Modal.Title>Deletar User</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Label>
                Deseja realmente deletar o usuário <strong>{name}</strong>
              </Form.Label>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseDelete}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleDeleteUser}>
              Sim, Deletar
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showEdit} onHide={handleCloseEdit}>
          <Modal.Header closeButton>
            <Modal.Title>Editar Usuário</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Label>Nome do Usuário</Form.Label>
              <Form.Control
                placeholder="Nome do Usuário"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              <hr />

              <Form.Control
                onChange={(e) => setType(e.target.value)}
                as="select"
                custom
                defaultValue={type}
              >
                <option value="Nível de Acesso">Nível de Acesso</option>
                <option value="a">Admin</option>
                <option value="m">Monitor</option>
                <option value="e">Estudante</option>
                <option value="s">Suporte</option>
                <option value="c">Corretor</option>
              </Form.Control>

              <hr />

              <Form.Control
                onChange={(e) => setAtivado(parseInt(e.target.value))}
                as="select"
                custom
                defaultValue={ativado}
              >
                <option value="Ativo">Ativar/Desativar aluno</option>
                <option value="1">Ativo</option>
                <option value="0">Inativo</option>
              </Form.Control>

              <hr />

              <Form.Control
                onChange={(e) => setTurmaID(e.target.value)}
                as="select"
                custom
                defaultValue={turmaID}
              >
                <option value="Turmas">Turmas</option>
                {turmas &&
                  turmas.map((turma, idx) => (
                    <option value={turma.id}>{turma.name}</option>
                  ))}
              </Form.Control>

              <hr />

              <Form.Control
                placeholder="E-mail do Usuário"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <hr />

              <Form.Control
                placeholder="Telefone do Usuário"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <hr />

              <Form.Control
                placeholder="Senha do Usuário"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <hr />

              <Form.Control
                placeholder="Confirmar Senha do Usuário"
                value={passwordConfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
              />
              <hr />
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseEdit}>
              Cancelar
            </Button>
            <Button variant="primary" onClick={handleEditUser}>
              Editar
            </Button>
          </Modal.Footer>
        </Modal>
      </S.MainWrapper>
    </Template>
  );
}

export default Usuarios;
