import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Form,
  Modal,
} from "react-bootstrap";

import api from "../../services/api";
import * as S from "./styled";
import { useAuth } from "../../context/auth";
import Template from "../../template";

function Classes() {
 
  const [classes, setClasses] = useState([]);

  const [name, setName] = useState("");
  const [type, setType] = useState("");
  const [description, setDescription] = useState("");

  const [reload, setReload] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { user }  = useAuth();

  async function handleAddClasse(e) {
    e.preventDefault();

    const dataClasse = {
      name: name,
      type: type,
      description: description,
    };

    let response = await api.post("/classes", dataClasse);

    handleClose();

    setName("");
    setType("");
    setDescription("");

    setReload(response.data);
  }

 
  useEffect(() => {
    async function getClasses() {
      try {
        let response = await api.get("/getTurmas");

        setClasses(response.data);
      } catch (err) {

      } finally {
        return false;
      }
    }

    getClasses();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <Template>
    <S.MainWrapper>
      <Container fluid>
        <Row>
          <Col sm={12}>
            {user.nivel === "a" && (
              <>
                <S.MainContent>
                  <strong>Administração</strong>
                  <Button
                    className="pull-right"
                    variant="success"
                    size="sm"
                    onClick={handleShow}
                  >
                    Adicionar Classe
                  </Button>

                  <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                      <Modal.Title>Adicionar Classe</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <Form>
                        <Form.Label>Nome da Classe</Form.Label>
                        <Form.Control
                          placeholder="Nome da Classe"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                        <hr />

                        <Form.Control
                          onChange={(e) => setType(e.target.value)}
                          as="select"
                          custom
                        >
                          <option value="Selecione o tipo da Classe">
                            Selecione o tipo da Classe
                          </option>
                          <option value="Presencial">Presencial</option>
                          <option value="Internet">Internet</option>
                        </Form.Control>

                        <hr />
                        <Form.Control
                          placeholder="Descrição da Classe"
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                        <hr />
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button variant="secondary" onClick={handleClose}>
                        Cancelar
                      </Button>
                      <Button variant="primary" onClick={handleAddClasse}>
                        Adicionar
                      </Button>
                    </Modal.Footer>
                  </Modal>
                </S.MainContent>


              </>
            )}

            <S.MainContent>
              <Row>
                <Col>
                  <legend>
                    <strong>Minha(s) Classe(s)</strong>
                  </legend>
                </Col>
              </Row>

              <Row>
                {classes &&
                  classes.map((classe, idx) => (
                    <Col sm={3}>
                      <Card>
                        <Card.Header>{classe.type}</Card.Header>
                        <Card.Body>
                          <Card.Title>{classe.name}</Card.Title>
                          <Card.Text>{classe.description}</Card.Text>
                          <Link
                            className="btn btn-primary"
                            to={`/minhaclasse/${classe.id}`}
                          >
                            Visualizar
                          </Link>
                        </Card.Body>
                      </Card>
                    </Col>
                  ))}
              </Row>
            </S.MainContent>
          </Col>
        </Row>
      </Container>
    </S.MainWrapper>
    </Template>
  );
}

export default Classes;
