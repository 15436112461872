import { useState } from "react";
import api from "../../services/api";
import * as S from "./styled";

import Logo from "../../assets/logo.png";

function CadastroAluno(props) {
  const [nome, setNome] = useState("");
  const [cpf, setCPF] = useState("");
  const [telefone, setTelefone] = useState("");
  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");
  const [pass2, setPass2] = useState("");
  const [aviso, setAviso] = useState("");
  const [aviso2, setAviso2] = useState("");
  const [enviado, setEnviado] = useState();

  async function cadastroHandler(e) {
    e.preventDefault();

    if(pass !== pass2){
      alert('As senhas precisam ser iguais, tente novamente.');
      setPass('');
      setPass2('');
      return false;
    }

    if(nome === ''){
      alert('Campo nome obrigatório');
      return false;
    }

    if(email === ''){
      alert('Campo email obrigatório');
      return false;
    }

    if(cpf === ''){
      alert('Campo cpf obrigatório');
      return false;
    }

    if(pass === ''){
      alert('Campo senha obrigatório');
      return false;
    }

    if(pass2 === ''){
      alert('Campo confirmação de senha obrigatório');
      return false;
    }

    const dataEstudante = {
      name: nome,
      email,
      cpf,
      password: pass,
      phone: telefone,
      chave: props.match.params.chave
    };

    let response = await api.post("/cadastroEstudante", dataEstudante);

    if (response.data.message === "CPF Existente") {
      setAviso(
        "CPF já cadastrado, aguarde a aprovação de sua conta, avisaremos por email."
      );
      return false;
    }

    if (response.data.message === "Turma não encontrada") {
      setAviso(
        "O cadastro para esta turma não existe."
      );
      setEnviado(true);
      return false;
    }

    if (response.data.message === "CPF/Email Existente") {
      setAviso(
        "CPF/Email já cadastrado, aguarde a aprovação de sua conta, avisaremos por email."
      );
      return false;
    }

    if (response.data.message === "Cadastrado com sucesso") {
      setAviso(`Obrigado ${nome}!`);
      setAviso2(
        `Seu cadastro realizado, aguarde a aprovação de sua conta, avisaremos por email.`
      );

      setEnviado(true);

      return true;
    }

    alert('Não conseguimos completar sua requisição, entre em contato com o suporte.');
  }

  return (
    <S.LoginWrapper>
      <img src={Logo} alt="Curso Danielle Velascos - Login" />
      <S.FromWrapper>
        {!enviado && (
          <>
            <h1>Seja bem vindo(a)!</h1>
            <p>Efetue seu cadastro abaixo e aguarde a aprovação.</p>

            <form>
              <input
                placeholder="Nome"
                value={nome}
                onChange={(e) => setNome(e.target.value)}
                required
              />
              <input
                placeholder="CPF (sem traços ou pontos)"
                value={cpf}
                type="number"
                onChange={(e) => setCPF(e.target.value)}
                required
              />
              <input
                placeholder="Telefone/Celular"
                value={telefone}
                onChange={(e) => setTelefone(e.target.value)}
                required
              />
              <input
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                placeholder="Senha"
                type="password"
                value={pass}
                onChange={(e) => setPass(e.target.value)}
                required
              />
              <input
                placeholder="Confirme sua Senha"
                type="password"
                value={pass2}
                onChange={(e) => setPass2(e.target.value)}
                required
              />
              <button onClick={cadastroHandler}>Efetuar Cadastrar</button>
            </form>
          </>
        )}
        <h1>{aviso}</h1>
        <p>{aviso2}</p>
      </S.FromWrapper>
    </S.LoginWrapper>
  );
}

export default CadastroAluno;
