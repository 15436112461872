import { useState, useEffect, useRef } from "react";
import { usePdf } from "@mikecousins/react-pdf";
import { Row, Col } from "react-bootstrap";
import axios from "axios";
import config from '../../services/config';
import GlobalStyles from "../../assets/css/global";


function RedacaoPdf(props) {

  const api = axios.create({
    baseURL: config.server.hostname,
  });
  
  const { id, pdf } = props.match.params;

  const canvasRef = useRef(null);
  const canvasRefWrapper = useRef(null);
  const [elementos, setElementos] = useState([]);
  const [redacao, setDados] = useState({});
// eslint-disable-next-line no-unused-vars
  const { pdfDocument, pdfPage } = usePdf({
    file: `https://redacoes.nyc3.digitaloceanspaces.com/${pdf}`,
    page: 1,
    canvasRef,
    scale: 2.4,
  });

  useEffect(() => {
    async function getPDF() {
      try {
        const { data } = await api.get(`/gerarpdfredacao/${id}`, config);

        setDados(data);
      } catch (error) {}
    }

    getPDF();

    async function getElementos() {
      try {
        let response = await api.get(`/getpoint/${id}`, config);
        setElementos(response.data);
      } catch (err) {
      } finally {
        return false;
      }
    }

    getElementos();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <GlobalStyles />
      <Col>
        <Row>
          <Col>
            <div id="canvas-wrapper" ref={canvasRefWrapper}>
              <canvas ref={canvasRef} />
              {elementos &&
                elementos.map((elemento, idx) => (
                  <div
                    className="rectangle"
                    style={{
                      backgroundColor: elemento.rgb,
                      left: elemento.iniciox,
                      top: elemento.inicioy,
                      width: elemento.largura,
                      height: elemento.altura,
                      padding: "5px",
                      color: "#fff",
                    }}
                  >
                    {idx}
                  </div>
                ))}
            </div>
          </Col>
        </Row>

        <Row>
          <Col>
            <table className="table table-striped table-bordered">
              <tr>
                <td className={redacao.falta === "1" ? `marcado` : ''}>
                  Texto Insuficiente
                </td>
                <td className={redacao.falta === "2" ? `marcado` : ''}>Cópia</td>
                <td className={redacao.falta === "3" ? `marcado` : ''}>
                  Fuga ao Tema
                </td>
                <td className={redacao.falta === "4" ? `marcado` : ''}>
                  Fuga ao Gênero
                </td>
                <td className={redacao.falta === "5" ? `marcado` : ''}>
                  Fere Direitos Humanos
                </td>
                <td className={redacao.falta === "6" ? `marcado` : ''}>
                  Em Branco
                </td>
                <td className={redacao.falta === "7" ? `marcado` : ''}>
                  Outras Formas de Anulação
                </td>
              </tr>
            </table>
            <table className="table table-striped table-bordered">
              <tr>
                <td>
                  <h3>RESERVADO AO CORRETOR</h3>
                </td>
                <td>
                  N<sup>0</sup>
                  <br />0
                </td>
                <td>
                  N<sup>1</sup>
                  <br />
                  40
                </td>
                <td>
                  N<sup>2</sup>
                  <br />
                  80
                </td>
                <td>
                  N<sup>3</sup>
                  <br />
                  120
                </td>
                <td>
                  N<sup>4</sup>
                  <br />
                  160
                </td>
                <td>
                  N<sup>5</sup>
                  <br />
                  200
                </td>
              </tr>
              <tr>
                <td>
                  1 - Demonstrar domínio da norma culta da língua escrita.{" "}
                </td>
                <td>{redacao.competencia1 === "0" && `X`}</td>
                <td>{redacao.competencia1 === "40" && `X`}</td>
                <td>{redacao.competencia1 === "80" && `X`}</td>
                <td>{redacao.competencia1 === "120" && `X`}</td>
                <td>{redacao.competencia1 === "160" && `X`}</td>
                <td>{redacao.competencia1 === "200" && `X`}</td>
              </tr>
              <tr>
                <td>
                  2 - Compreender a proposta de redação e aplicar conceitos de
                  várias áreas de conhecimento para desenvolver o tema, dentro
                  dos limites estruturais do texto dissertativo-argumentativo.
                </td>
                <td>{redacao.competencia2 === "0" && `X`}</td>
                <td>{redacao.competencia2 === "40" && `X`}</td>
                <td>{redacao.competencia2 === "80" && `X`}</td>
                <td>{redacao.competencia2 === "120" && `X`}</td>
                <td>{redacao.competencia2 === "160" && `X`}</td>
                <td>{redacao.competencia2 === "200" && `X`}</td>
              </tr>
              <tr>
                <td>
                  3 - Selecionar, relacionar, organizar, e interpretar
                  informações, fatos, opiniões e argumentos em defesa do ponto
                  de vista.
                </td>
                <td>{redacao.competencia3 === "0" && `X`}</td>
                <td>{redacao.competencia3 === "40" && `X`}</td>
                <td>{redacao.competencia3 === "80" && `X`}</td>
                <td>{redacao.competencia3 === "120" && `X`}</td>
                <td>{redacao.competencia3 === "160" && `X`}</td>
                <td>{redacao.competencia3 === "200" && `X`}</td>
              </tr>
              <tr>
                <td>
                  4 - Demonstrar conhecimento dos mecanismos linguísticos
                  necessários para a construção da argumentação
                </td>
                <td>{redacao.competencia4 === "0" && `X`}</td>
                <td>{redacao.competencia4 === "40" && `X`}</td>
                <td>{redacao.competencia4 === "80" && `X`}</td>
                <td>{redacao.competencia4 === "120" && `X`}</td>
                <td>{redacao.competencia4 === "160" && `X`}</td>
                <td>{redacao.competencia4 === "200" && `X`}</td>
              </tr>
              <tr>
                <td>
                  5 - Elaborar proposta de solução para o problema abordado,
                  mostrando respeito aos valores humanos e considerando a
                  diversidade sociocultural.
                </td>
                <td>{redacao.competencia5 === "0" && `X`}</td>
                <td>{redacao.competencia5 === "40" && `X`}</td>
                <td>{redacao.competencia5 === "80" && `X`}</td>
                <td>{redacao.competencia5 === "120" && `X`}</td>
                <td>{redacao.competencia5 === "160" && `X`}</td>
                <td>{redacao.competencia5 === "200" && `X`}</td>
              </tr>
            </table>

            <hr />

            <table className="table table-striped table-bordered">
              <tr>
                <td width="70%">
                  <h3>
                    <strong>Observações do Corretor</strong>
                  </h3>
                  {redacao.observacao}
                </td>
                <td width="30%" className="text-right">
                  <h3>
                    <strong>Total: </strong>{" "}
                    {parseFloat(redacao?.competencia1) +
                      parseFloat(redacao?.competencia2) +
                      parseFloat(redacao?.competencia3) +
                      parseFloat(redacao?.competencia4) +
                      parseFloat(redacao?.competencia5)}
                  </h3>
                </td>
              </tr>
            </table>
          </Col>
        </Row>
      </Col>
    </>
  );
}

export default RedacaoPdf;
