import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Correction from "../Correction";

import api from "../../services/api";
import Template from "../../template";

import * as S from "./styled";

function FazerCorrecao(props) {
  const { id } = props.match.params;

  const [dados, setDados] = useState([]);
  const [arquivo, setArquivo] = useState("");

  useEffect(() => {
    async function getPDF() {
      try {
        const { data } = await api.get(`/getRedacao/${id}`);

        setArquivo(data[0].pdf);
        setDados(data);
      } catch (error) {

      }
    }

    getPDF();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Template>
    <S.MainWrapper>
      <Container>
        <Row>
          <Col sm={12}>
            <S.MainContent>
              <Row>
                <Col>
                <p>&nbsp;</p>
                  <legend>
                    <strong>Correção da Redação</strong> {dados[0] && dados[0].nome}
                  </legend>
                </Col>
              </Row>

              <Row>
                <Correction dados={dados} id={id} arquivo={arquivo} />
              </Row>
            </S.MainContent>
          </Col>
        </Row>
      </Container>
    </S.MainWrapper>
    </Template>
  );
}

export default FazerCorrecao;
