import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import InputMask from "react-input-mask";
import { Container, Row, Col, Button, Form, Modal } from "react-bootstrap";

import api from "../../services/api";
import * as S from "./styled";
import { useAuth } from "../../context/auth";
import Template from "../../template";

function Atividades(props) {
  const { user } = useAuth();

  const { id } = props.match.params;

  const [nomeAtividade, setNomeAtividade] = useState("");
  const [generoAtividade, setGeneroAtividade] = useState("");
  const [observacaoAtividade, setObservacaoAtividade] = useState("");

  const [entrega, setEntrega] = useState(false);

  const [atrelarParaTurma, setAtrelarParaTurma] = useState(0);

  const [atividades, setAtividades] = useState([]);
  const [turma, setNomeTurma] = useState("");
  const [busca, setBusca] = useState("");

  const [dataEntrega, setDataEntrega] = useState("");
  const [horaEntrega, setHoraEntrega] = useState("");
  const [numeroAula, setNumeroAula] = useState("");
  
  const [lgShow, setLgShow] = useState(false);
  const [smShow, setSmShow] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [editShow2, setEditShow2] = useState(false);

  const [idAtividade, setIdAtividade] = useState(0);
  const [idAtividadeRedacao, setIdAtividadeRedacao] = useState(0);

  const [link, setLink] = useState("");

  const [arquivo, setArquivo] = useState("");
  const [descricao, setDescricao] = useState("");

  /* Filtrar as atividades */
  async function handleBusca() {
    try {
      const dataBusca = {
        busca: busca,
        id,
      };

      let response = await api.post("/buscaTema", dataBusca);
      const itens = response.data.temas.filter(function (value, index, arr) {
        return response.data.existentes.map((existente) => {
          return value.id === existente.id
            ? ((value.classe_id = existente.classe_id),
              (value.entrega = existente.entrega),
              (value.hora = existente.hora),
              (value.id_atividade = existente.id_atividade),
              (value.arquivo = existente.arquivo),
              (value.descricao = existente.descricao))
            : false;
        });
      });

      setAtividades(itens);
    } catch (err) {
    } finally {
      return false;
    }
  }

  /* cadastrar atividade */
  const cadastrarAtividade = async () => {
    try {
      const data = {
        nome: nomeAtividade,
        genero: generoAtividade,
        aula: numeroAula,
        tipo: 1,
        descricao: observacaoAtividade,
        arquivo: link,
      };

      const retorno = await api.post("/cadastrarAtividade", data);

      setGeneroAtividade("");
      setObservacaoAtividade("");
      setLink("");

      if (retorno.data.id) {
        setAtrelarParaTurma(retorno.data.id);
        toast.success("Atividade cadastrada com sucesso!");
      } else {
        toast.error("Erro ao cadastrar atividade!");
      }
    } catch (err) {
      toast.error("Erro ao cadastrar atividade!");
    } finally {
      return false;
    }
  };

  /* editar atividade */
  const editarAtividade = async () => {
    try {
      const data = {
        nome: nomeAtividade,
        genero: generoAtividade,
        aula: numeroAula,
        tipo: 1,
        descricao: observacaoAtividade,
        arquivo: link,
        id: idAtividade,
      };

      await api.put("/editarAtividade", data);

      setNomeAtividade("");
      setGeneroAtividade("");
      setObservacaoAtividade("");
      setNumeroAula("");
      setLink("");
      setEditShow(false);

      getAtividades();

      toast.success("Atividade editada com sucesso!");
    } catch (err) {
      toast.error("Erro ao editar atividade!");
    } finally {
      return false;
    }
  };

  /* editar data atividade */
  const editarDataAtividade = async () => {
    if (horaEntrega && dataEntrega) {
      try {
        const data = {
          hora: horaEntrega,
          entrega: dataEntrega.split("/").reverse().join("-"),
          id: idAtividadeRedacao,
        };

        await api.put("/editarDataAtividade", data);

        setHoraEntrega("");
        setDataEntrega("");
        setIdAtividadeRedacao(0);
        setIdAtividade(0);
        setEditShow2(false);

        getAtividades();

        toast.success("Entrega da atividade editada com sucesso!");
      } catch (err) {
        toast.error("Erro ao editar atividade!");
      } finally {
        return false;
      }
    } else {
      toast.error("Uma nova hora e data devem ser preenchidas");
      return false;
    }
  };

  /* Formatar Data de entrega */
  const formatarData = (dataTurma) => {
    if (dataTurma) {
      return dataTurma.split("-").reverse().join("/");
    }

    return false;
  };

  /* carregar tema para edição */
  const carregarTema = async (id_tema) => {
    try {
      const response = await api.get(`/carregarTema/${id_tema}`);

      setNomeAtividade(response.data[0].nome);
      setGeneroAtividade(response.data[0].genero);
      setNumeroAula(response.data[0].aula);
      setObservacaoAtividade(response.data[0].descricao);
      setLink(response.data[0].arquivo);

      setIdAtividade(id_tema);
    } catch (err) {
    } finally {
      return false;
    }
  };

  /* envio de arquivo */
  async function sendFile(e) {
    const data = new FormData();

    if (e.target.files[0]) {
      data.append("arquivo", e.target.files[0]);
    }
    const response = await api.post("arquivoUpload", data);

    setLink(`/${response.data.arquivo}`);
  }

  /* atrelar atividade a turma */
  async function adicionarAtividadeTurma() {


    const dataAtividade = {
      classe_id: id,
      entrega: dataEntrega.split("/").reverse().join("-"),
      hora: horaEntrega,
      tema_id: atrelarParaTurma,
      atividade: nomeAtividade,
    };

    await api.post("/adicionarAtividade", dataAtividade);

    setDataEntrega("");
    setHoraEntrega("");
    setAtrelarParaTurma(0);
    setLink("");
    setNomeAtividade("");
    setLgShow(false);

    getAtividades();

    toast.success("Atividade cadastrada para a turma com sucesso!");
  }

  async function getAtividades() {
    try {
      let response = await api.get(`/mostrar-temas/${id}`);

      const itens = response.data.temas.filter(function (value, index, arr) {
        return response.data.existentes.map((existente) => {
          return value.id === existente.id
            ? ((value.classe_id = existente.classe_id),
              (value.entrega = existente.entrega),
              (value.hora = existente.hora),
              (value.arquivo = existente.arquivo),
              (value.id_atividade = existente.id_atividade),
              (value.descricao = existente.descricao))
            : false;
        });
      });

      setNomeTurma(response.data.turma[0].nome);
      setAtividades(itens);
    } catch (err) {
    } finally {
      return false;
    }
  }

  useEffect(() => {
    getAtividades();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Template>
      <S.MainWrapper>
        <Container fluid>
          <Row>
            <Col sm={12}>
              {user.nivel === "a" && (
                <>
                  <S.MainContent>
                    <strong>Administração</strong>
                    <Button
                      className="pull-right"
                      variant="success"
                      size="sm"
                      onClick={() => setLgShow(true)}
                    >
                      Adicionar Atividade
                    </Button>
                  </S.MainContent>

                  {/* modal para cadastro de atividade */}
                  <Modal
                    size="lg"
                    show={lgShow}
                    onHide={() => setLgShow(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="example-modal-sizes-title-lg">
                        Adicionar Atividade
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {atrelarParaTurma ? (
                        <>
                          <p>
                            Agora que você tem o tema selecionado, deseja
                            aplicar ela para a turma <strong>{turma}</strong>?
                          </p>
                          <hr />
                          {!entrega ? (
                            <>
                              <p className="text-center">
                                <button
                                  onClick={() => setEntrega(true)}
                                  className="btn btn-info"
                                >
                                  Sim
                                </button>{" "}
                                <button
                                  onClick={() => {
                                    setLgShow(false);
                                    getAtividades();
                                    setNomeAtividade("");
                                  }}
                                  className="btn btn-danger"
                                >
                                  Não
                                </button>
                              </p>
                            </>
                          ) : (
                            <>
                              <p>
                                <InputMask
                                  className="form-control"
                                  placeholder="Confirme a data de entrega **/**/****"
                                  mask="99/99/9999"
                                  maskChar={null}
                                  value={dataEntrega}
                                  onChange={(e) =>
                                    setDataEntrega(e.target.value)
                                  }
                                />
                              </p>
                              <p>
                                <InputMask
                                  className="form-control"
                                  placeholder="Confirme a hora de entrega **:**"
                                  mask="99:99"
                                  maskChar={null}
                                  value={horaEntrega}
                                  onChange={(e) =>
                                    setHoraEntrega(e.target.value)
                                  }
                                />
                              </p>
                              <button
                                onClick={() => adicionarAtividadeTurma()}
                                className="btn btn-info"
                              >
                                Finalizar
                              </button>

                              <button
                                onClick={() => {
                                  setLgShow(false);
                                  setNomeAtividade("");
                                }}
                                className="btn btn-danger"
                              >
                                Cancelar
                              </button>
                            </>
                          )}
                        </>
                      ) : (
                        <form>
                          <p>
                            Antes de cadastrar um novo tema certifique-se de que
                            ele já não existe com algum nome similar, isso
                            evitará poluição e conflitos de mensagem conforme o
                            crescimento dos itens!
                          </p>
                          <hr />
                          <p>
                            <strong>Nome da atividade</strong>
                            <br />
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Nome da atividade "
                              value={nomeAtividade}
                              onChange={(e) => setNomeAtividade(e.target.value)}
                            />
                          </p>

                          <p>
                            <strong>Número da Aula</strong>
                            <br />
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Número da Aula"
                              value={numeroAula}
                              onChange={(e) => setNumeroAula(e.target.value)}
                            />
                          </p>

                          <p>
                            <strong>Tipo da atividade</strong>
                            <br />
                            <select
                              type="text"
                              className="form-control"
                              onChange={(e) =>
                                setGeneroAtividade(e.target.value)
                              }
                            >
                              <option value="">
                                Selecione o tipo da atividade
                              </option>
                              <option value="Tema">Tema</option>
                              <option value="Tema Bônus">Tema Bônus</option>
                              <option value="Simulado">Simulado</option>
                            </select>
                          </p>

                          <p>
                            <strong>Material de apoio (arquivo)</strong>
                            <br />
                            <Form.File
                              name="arquivo"
                              className="form-control"
                              onChange={sendFile}
                            />
                          </p>

                          <p>
                            <strong>Material de apoio (Observações)</strong>
                            <br />
                            <textarea
                              className="form-control"
                              rows={5}
                              placeholder="Descreva aqui informações úteis para a atividade"
                              value={observacaoAtividade}
                              onChange={(e) =>
                                setObservacaoAtividade(e.target.value)
                              }
                            />
                          </p>

                          <p className="text-right">
                            <button
                              className="btn btn-info"
                              onClick={(e) => {
                                e.preventDefault();
                                cadastrarAtividade();
                              }}
                            >
                              Cadastrar
                            </button>
                          </p>
                        </form>
                      )}
                    </Modal.Body>
                  </Modal>

                  {/* Modal para editar atividade */}
                  <Modal
                    size="lg"
                    show={editShow}
                    onHide={() => setEditShow(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="example-modal-sizes-title-lg">
                        Editar Atividade
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <form>
                        <p>
                          A edição do item irá se refletir automaticamente para
                          todas as classes que possuirem esta atividade em
                          desenvolvimento
                        </p>
                        <hr />
                        <p>
                          <strong>Nome da atividade</strong>
                          <br />
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Nome da atividade "
                            value={nomeAtividade}
                            onChange={(e) => setNomeAtividade(e.target.value)}
                          />
                        </p>

                        <p>
                            <strong>Número da Aula</strong>
                            <br />
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Número da Aula"
                              value={numeroAula}
                              onChange={(e) => setNumeroAula(e.target.value)}
                            />
                          </p>

                        <p>
                          <strong>Tipo da atividade</strong>
                          <br />
                          <select
                            type="text"
                            className="form-control"
                            value={generoAtividade}
                            onChange={(e) => setGeneroAtividade(e.target.value)}
                          >
                            <option value="">
                              Selecione o tipo da atividade
                            </option>
                            <option value="Tema">Tema</option>
                            <option value="Tema Bônus">Tema Bônus</option>
                            <option value="Simulado">Simulado</option>
                          </select>
                        </p>

                        <p>
                          <strong>Material de apoio (arquivo)</strong>
                          <br />
                          <Form.File
                            name="arquivo"
                            className="form-control"
                            onChange={sendFile}
                          />
                        </p>

                        <p>
                          <strong>Material de apoio (Observações)</strong>
                          <br />
                          <textarea
                            className="form-control"
                            rows={5}
                            placeholder="Descreva aqui informações úteis para a atividade"
                            value={observacaoAtividade}
                            onChange={(e) =>
                              setObservacaoAtividade(e.target.value)
                            }
                          />
                        </p>

                        <p className="text-right">
                          <button
                            className="btn btn-info"
                            onClick={(e) => {
                              e.preventDefault();
                              editarAtividade();
                            }}
                          >
                            Editar
                          </button>
                        </p>
                      </form>
                    </Modal.Body>
                  </Modal>

                  {/* Modal para editar data */}
                  <Modal
                    size="lg"
                    show={editShow2}
                    onHide={() => setEditShow2(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="example-modal-sizes-title-lg">
                        Editar Entrega Atividade
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <form>
                        <p>
                          Esta edição de data será aplicada APENAS na classe em
                          que esta ocorrendo a mudança.
                        </p>
                        <hr />
                        <p>
                          <InputMask
                            className="form-control"
                            placeholder="Confirme a data de entrega **/**/****"
                            mask="99/99/9999"
                            maskChar={null}
                            value={dataEntrega}
                            onChange={(e) => setDataEntrega(e.target.value)}
                          />
                        </p>
                        <p>
                          <InputMask
                            className="form-control"
                            placeholder="Confirme a hora de entrega **:**"
                            mask="99:99"
                            maskChar={null}
                            value={horaEntrega}
                            onChange={(e) => setHoraEntrega(e.target.value)}
                          />
                        </p>

                        <p className="text-right">
                          <button
                            className="btn btn-info"
                            onClick={(e) => {
                              e.preventDefault();
                              editarDataAtividade();
                            }}
                          >
                            Editar
                          </button>
                        </p>
                      </form>
                    </Modal.Body>
                  </Modal>

                  {/* material de apoio */}
                  <Modal
                    size="lg"
                    show={smShow}
                    onHide={() => setSmShow(false)}
                    aria-labelledby="example-modal-sizes-title-lg"
                  >
                    <Modal.Header closeButton>
                      <Modal.Title id="example-modal-sizes-title-lg">
                        Material de Apoio
                      </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      {descricao}
                      <hr />
                      <a
                        href={`https://redacoes.nyc3.digitaloceanspaces.com${arquivo}`}
                        title="material de apoio"
                        rel="noreferrer"
                        target="_blank"
                      >
                        Visualizar arquivo de apoio
                      </a>
                    </Modal.Body>
                  </Modal>

                  <S.MainContent>
                    <Form>
                      <Form.Row>
                        <Col sm={10}>
                          <Form.Control
                            onChange={(e) => setBusca(e.target.value)}
                            value={busca}
                            placeholder="Procure um por uma atividade previamente cadastrada"
                          />
                        </Col>
                        <Col sm={2}>
                          <Button onClick={handleBusca}>
                            <S.SearchIcon />
                            {busca && busca !== "" ? (
                              <Button
                                onClick={() => {
                                  setBusca("");
                                }}
                              >
                                Limpar Busca
                              </Button>
                            ) : (
                              ""
                            )}
                          </Button>
                        </Col>
                      </Form.Row>
                    </Form>
                  </S.MainContent>
                </>
              )}

              <S.MainContent>
                <Row>
                  <Col>
                    {turma ? (
                      <>
                        <legend>
                          Atividade para turma <strong>{turma}</strong>{" "}
                          <Link
                            to={`/minhaclasse/${id}`}
                            className="btn btn-info pull-right"
                          >
                            Voltar para classe {turma}
                          </Link>
                        </legend>

                        <small>
                          Abaixo você confere todas as atividades que existem no
                          sistema e quais esta turma já possui, caso desejar
                          você pode criar uma nova atividade.
                        </small>
                        <hr />
                      </>
                    ) : (
                      <legend>
                        <strong>Atividades para turmas presênciais</strong>{" "}
                        <br />
                        <small>Ordenado por ordem alfabética</small>
                      </legend>
                    )}
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <table className="table table-striped table-bordered">
                      <tr>
                        <td>
                          <strong>Tipo</strong>
                        </td>
                        <td>
                          <strong>Aula</strong>
                        </td>
                        <td>
                          <strong>Tema</strong>
                        </td>
                        <td>
                          <strong>Data P/ Entrega</strong>
                        </td>
                        <td>
                          <strong>Material</strong>
                        </td>
                        <td>
                          <strong>Adicionar</strong>
                        </td>
                        <td>
                          <strong>Editar</strong>
                        </td>
                      </tr>

                      {atividades &&
                        atividades.map((atividade, idx) => (
                          <tr>
                            <td>
                              <strong>{atividade.genero}</strong>
                            </td>
                            <td>{atividade.aula}</td>
                            <td>{atividade.nome}</td>
                            <td>
                              {formatarData(atividade.entrega)} {atividade.hora}{" "}
                              {atividade.classe_id == id && (
                                <S.EditIcon2
                                  onClick={() => {
                                    carregarTema(atividade.id);
                                    setIdAtividadeRedacao(
                                      atividade.id_atividade
                                    );
                                    setEditShow2(true);
                                  }}
                                />
                              )}
                            </td>
                            <td>
                              <button
                                className="btn btn-warning"
                                type="button"
                                onClick={() => {
                                  setArquivo(atividade.arquivo);
                                  setDescricao(atividade.descricao);
                                  setSmShow(true);
                                }}
                              >
                                Apoio
                              </button>
                            </td>
                            <td>
                              <strong>
                                {atividade.classe_id == id ? (
                                  <>
                                    <div className="btn btn-danger">
                                      Já em uso
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      className="btn btn-primary"
                                      type="button"
                                      onClick={() => {
                                        carregarTema(atividade.id);
                                        setAtrelarParaTurma(atividade.id);
                                        setLgShow(true);
                                      }}
                                    >
                                      Selecionar
                                    </button>
                                  </>
                                )}
                              </strong>
                            </td>
                            <td>
                              <button
                                className="btn btn-info"
                                type="button"
                                onClick={() => {
                                  carregarTema(atividade.id);
                                  setEditShow(true);
                                }}
                              >
                                Editar
                              </button>
                            </td>
                          </tr>
                        ))}
                    </table>
                  </Col>
                </Row>
              </S.MainContent>
            </Col>
          </Row>
        </Container>
      </S.MainWrapper>
    </Template>
  );
}

export default Atividades;
