import React, { useState } from "react";
import { useHistory, Link } from "react-router-dom";

import ReactQuill from "react-quill";
import "react-quill/lib";
import "react-quill/dist/quill.snow.css";

import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import * as S from "./styled";

import api from "../../services/api";
import Menu from "../../components/Menu";
import GlobalStyles from "../../assets/css/global";

import { useAuth } from "../../context/auth";

function RedacaoCriar(props) {
  const { id } = props.match.params;
// eslint-disable-next-line no-unused-vars
  const { user } = useAuth();


  const history = useHistory();
  const [redacao, setRedacao] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  function setarValor(e) {
    setRedacao(e);
  }

  function confirmarRedacao() {
    setShow(true);
  }

  async function salvarRedacao(e) {
    e.preventDefault();

    const dataRedacao = {
      id: id,
      redacao: redacao,
    };

    await api.put("/salvarRedacao", dataRedacao);

    setShow(false);
    setRedacao("");
    history.push("/redacoes");
  }

  return (
    <>
      <GlobalStyles />
      <S.MainWrapper>
        <Container fluid>
          <Row>
            <Col sm={2}>
              <Menu />
            </Col>
            <Col sm={10}>
              <S.MainContent>
                <Row>
                  <Col>
                    <legend>
                      <strong>Escrever Redação</strong>
                      <Link to="/redacoes" className="pull-right">
                        <Button variant="success">Cancelar</Button>
                      </Link>
                    </legend>
                    <hr />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <ReactQuill
                      theme="bubble"
                      value={redacao}
                      placeholder="Digite aqui sua redação, lembrando que ao salvar, não será possível editar."
                      onChange={setarValor}
                    />
                    <hr />
                    <Button variant="info" onClick={confirmarRedacao}>
                      SALVAR
                    </Button>
                  </Col>
                </Row>
              </S.MainContent>
            </Col>
          </Row>
        </Container>
      </S.MainWrapper>

      <Modal show={show} onHide={handleClose}>
        <Modal.Body>
          <h4>
            Confirmo que minha redação, não é plágio, conforme lei federal
            plagio é crime
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancelar
          </Button>
          <Button variant="primary" onClick={salvarRedacao}>
            Confirmo
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RedacaoCriar;
