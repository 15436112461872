import { useState, useEffect, useCallback, useRef } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  PdfLoader,
  PdfHighlighter,
  Tip,
  Highlight,
  Popup,
  AreaHighlight,
} from "react-pdf-highlighter";
import { Spinner } from "./Spinner";
import { Sidebar } from "./Sidebar";

import { Row, Col, Modal, Button as RButton } from "react-bootstrap";

import api from "../../services/api";
import { toast } from "react-toastify";
import { useCompetencia } from "../../context/competencia";

import CaixaDeCorrecao from "./CaixaDeCorrecao";
import { useAuth } from "../../context/auth";
import { Navigate } from "styled-icons/ionicons-solid";

const HighlightPopup = ({ comment }) =>
  comment.text ? <div className="Highlight__popup">{comment.text}</div> : null;

const CarregarPDF = (props) => {
  const { pdf, setFixo, competencia, id, tipo } = props;

  const history = useHistory();

  const { user } = useAuth();

  const { setCompetencia } = useCompetencia();

  const [att, setAtt] = useState(new Date());
  const [highlights, setHighlights] = useState([]);
  const [show, setShow] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [show2, setShow2] = useState(true);
  const [aberto, setAberto] = useState(false);

  const [anterior, setAnterior] = useState();
  const [proximo, setProximo] = useState();

  const [initialHeight, setInitialHiehgt] = useState();

  const inputEl = useRef(null);

  function handleClose() {
    document.body.classList.remove("shidden");
    setShow(false);
    setFixo(false);
  }

  function addHighlight(highlight) {
    salvarItem(highlight);
  }

  const pegarRedacaoAnterior = () => {
    history.push(`/corrigir/redacao/${anterior}`);
  };

  const pegarRedacaoProxima = () => {
    history.push(`/corrigir/redacao/${proximo}`);
  };

  const pegarTodosAlunosComTema = async (tema_id, turma_id) => {
    try {
      const retorno = await api.get(
        `/getTurmaTemaRedacoes/${tema_id}/${turma_id}`
      );

      const len = retorno.data.length;

      retorno.data.map((item, index) => {
        if (item.id == id) {
          console.log("index ", index);
          setAnterior(retorno.data[(index + len - 1) % len].id);
          setProximo(retorno.data[(index + 1) % len].id);

          console.log("Anterior ", retorno.data[(index + len - 1) % len].id);
          console.log("Próximo ", retorno.data[(index + 1) % len].id);
        }

        //return item.id === id ? true : false;
      });

      console.log("retorno pegarTodosAlunosComTema ", retorno.data);
    } catch (err) {
    } finally {
    }
  };

  const pegarRedacaoDoAluno = async () => {
    try {
      const retorno = await api.get(`/getRedacao/${id}`);

      // console.log('retorno pegarRedacaoDoAluno ', retorno);
      pegarTodosAlunosComTema(
        retorno.data[0].theme_id,
        retorno.data[0].turma_id
      );
    } catch (err) {
    } finally {
    }
  };

  async function handleSaveCorrection() {
    console.log("handleSaveCorrection ", highlights);

    try {
      const data = {
        id: id,
        correcao: JSON.stringify(highlights),
      };

      await api.post("/salvarCorrecao", data);

      document.body.classList.remove("shidden");
      setShow(false);
      setFixo(false);
      toast.success("Correção de pdf salva com sucesso.");
    } catch (err) {
    } finally {
      return false;
    }
  }

  async function getFirstCorrection() {
    try {
      const retorno = await api.get(`/pegar-primeira-correcao/${id}`);

      const valores = retorno.data.map((item, idx) => {
        const conteudo = JSON.parse(item.conteudo);
        return conteudo.position.boundingRect.height;
      });

      setInitialHiehgt(valores[0]);
      getCorrecaoNova(valores[0]);
    } catch (err) {
    } finally {
    }
  }

  async function getCorrecaoNova(heightInicial = false) {
    try {
      const retorno = await api.get(`/pegar-correcao/${id}`);

      const valores = retorno.data.map((item, idx) => {
        const conteudo = JSON.parse(item.conteudo);
        conteudo.id = item.id;
        if (heightInicial) {
          conteudo.position.boundingRect.height = heightInicial + 25;
        }

        if (initialHeight) {
          conteudo.position.boundingRect.height = initialHeight + 25;
        }
        return conteudo;
      });

      setHighlights(valores);
    } catch (err) {
    } finally {
      return false;
    }
  }

  function updateHighlight(highlightId, position, content) {
    atualizarItem(highlightId, position, content);
  }

  const removerItem = async (item) => {
    try {
      await api.put(`/deletar-correcao/${item}`);

      setAtt(new Date());

      toast.success("Item de correção deletado com sucesso.");
    } catch (err) {
    } finally {
      return false;
    }
  };

  const atualizarItem = async (highlightId, position, content) => {
    const data = {
      id: highlightId,
      position: position,
      content: content,
    };

    try {
      await api.put(`/atualizar-correcao`, data);

      setAtt(new Date());

      toast.success("Item de correção atualizado com sucesso.");
    } catch (err) {
    } finally {
      return false;
    }
  };

  const salvarItem = async (highlight) => {
    console.log("highlight salvo ", highlight);

    const data = {
      redacao_id: id,
      conteudo: JSON.stringify(highlight),
    };

    try {
      await api.post(`/salvar-correcao`, data);

      setAtt(new Date());

      toast.success("Item de correção inserido com sucesso.");
    } catch (err) {
    } finally {
      return false;
    }
  };

  const doCallback = useCallback((valor) => {
    setAberto(valor);
  }, []);

  function habilitarSelecao(event) {
    return event.buttons;
  }

  useEffect(() => {
    document.body.classList.add("shidden");
    getFirstCorrection();
    pegarRedacaoDoAluno();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCorrecaoNova();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [att]);

  function showCoords(event) {
    var x = event.pageX;
    var y = event.pageY;
    var coor = "X coords: " + x + ", Y coords: " + y;
    console.log("coor ", coor);
    //document.getElementById("carregarPdf").innerHTML = coor;
  }

  return (
    <>
      <Row>
        <Col xs={12} md>
          <p>
            <div
              className="btn btn-info"
              onClick={() => {
                document.body.classList.add("shidden");
                setShow(true);
                setFixo(true);
              }}
            >
              Abrir PDF para correção da redação
            </div>
          </p>
        </Col>
        <Col xs={12} md>
          <p className="text-right">
            <Link className="btn btn-success" to={`/redacoes`}>
              Voltar para correções
            </Link>
          </p>
        </Col>
      </Row>

      <div
        className={`App conteudoPDF ${show}`}
        style={{ display: "flex", height: "100vh" }}
      >
        <div>
          <RButton
            className="joga-direita"
            variant="warning"
            onClick={handleClose}
          >
            CANCELAR CORREÇÃO PDF
          </RButton>
          <hr />
          <a
            href={`/corrigir/redacao/${anterior}`}
            className="btn btn-info"
            title="Correção anterior"
          >
            Mostrar Anterior
          </a>
          <a
            href={`/corrigir/redacao/${proximo}`}
            className="btn btn-info"
            title="Correção Próximo"
          >
            Mostrar Próximo
          </a>
          <hr />
          <RButton variant="success" onClick={handleSaveCorrection}>
            SALVAR CORREÇÃO PDF
          </RButton>
          <hr />
          <Modal.Title>Correção da redação - PDF </Modal.Title>
          <hr />

          <Sidebar
            tipo={tipo}
            removerItem={removerItem}
            highlights={highlights}
          />
        </div>
        <div
          style={{
            height: "100vh",
            width: "75vw",
            position: "relative",
          }}
        >
          <PdfLoader ref={inputEl} url={pdf} beforeLoad={<Spinner />}>
            {(pdfDocument) => (
              <>
                <PdfHighlighter
                  onMouseMove={() => showCoords}
                  pdfDocument={pdfDocument}
                  enableAreaSelection={habilitarSelecao}
                  pdfScaleValue="page-width"
                  afterSelection={() => console.log("Terminou a seleção?")}
                  onSelectionFinished={(
                    position,
                    content,
                    hideTipAndSelection,
                    transformSelection
                  ) => (
                    <>
                      <CaixaDeCorrecao
                        position={position}
                        content={content}
                        doCallback={doCallback}
                        addHighlight={addHighlight}
                        hideTipAndSelection={hideTipAndSelection}
                        onOpen={() => transformSelection}
                      />
                      <Tip />
                    </>
                  )}
                  highlightTransform={(
                    highlight,
                    index,
                    setTip,
                    hideTip,
                    viewportToScaled,
                    screenshot,
                    isScrolledTo
                  ) => {
                    const isTextHighlight = !Boolean(
                      highlight.content && highlight.content.image
                    );

                    const component = isTextHighlight ? (
                      <Highlight
                        isScrolledTo={isScrolledTo}
                        position={highlight.position}
                        comment={highlight.comment}
                      />
                    ) : (
                      <div className={`competencia_${highlight.competencia}`}>
                        <AreaHighlight
                          isScrolledTo={isScrolledTo}
                          highlight={highlight}
                          onChange={(boundingRect) => {
                            console.log(
                              "boundingRect ",
                              viewportToScaled(boundingRect)
                            );
                            updateHighlight(
                              highlight.id,
                              {
                                boundingRect: viewportToScaled(boundingRect),
                              },
                              { image: highlight.content.image }
                            );
                          }}
                        />
                      </div>
                    );

                    return (
                      <>
                        <Popup
                          popupContent={<HighlightPopup {...highlight} />}
                          onMouseOver={(popupContent) => {
                            setTip(highlight, (highlight) => popupContent);
                          }}
                          onMouseOut={hideTip}
                          key={index}
                          children={component}
                        />
                      </>
                    );
                  }}
                  highlights={highlights}
                />
              </>
            )}
          </PdfLoader>
        </div>
      </div>

      <Modal show={false} size="lg" className="grandao">
        <Modal.Header>
          <Modal.Title className="text-center" style={{ width: "100%" }}>
            Selecione uma competência
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="text-center">
          <p>
            Antes de iniciar a correção, você precisa escolher a competência
            para iniciar.
          </p>
          <div
            className={`botao cor`}
            style={{
              backgroundColor: `${
                competencia === 1
                  ? "rgba(177,0,168, 1)"
                  : "rgba(177,0,168, 0.3)"
              }`,
              color: `${competencia === 1 ? "#fff" : "#000"}`,
              fontWeight: `${competencia === 1 ? "bold" : ""}`,
            }}
            onClick={() => {
              setCompetencia(1);
              setShow2(false);
            }}
          >
            {tipo == 1 ? "Modalidade culta da língua" : "Competência 1"}
          </div>
          <div
            className={`botao cor`}
            style={{
              backgroundColor: `${
                competencia === 2 ? "rgba(255,0,0,1)" : "rgba(255,0,0,0.3)"
              }`,
              color: `${competencia === 2 ? "#fff" : "#000"}`,
              fontWeight: `${competencia === 2 ? "bold" : ""}`,
            }}
            onClick={() => {
              setCompetencia(2);
              setShow2(false);
            }}
          >
            {tipo == 1 ? "Tipologia textual" : "Competência 2"}
          </div>
          <div
            className={`botao cor`}
            style={{
              backgroundColor: `${
                competencia === 3 ? "rgba(29,29,245, 1)" : "rgba(29,29,245,0.3)"
              }`,
              color: `${competencia === 3 ? "#fff" : "#000"}`,
              fontWeight: `${competencia === 3 ? "bold" : ""}`,
            }}
            onClick={() => {
              setCompetencia(3);
              setShow2(false);
            }}
          >
            {tipo == 1 ? "Argumentação" : "Competência 3"}
          </div>
          <div
            className={`botao cor`}
            style={{
              backgroundColor: `${
                competencia === 4 ? "rgba(10,208,0, 1)" : "rgba(10,208,0,0.3)"
              }`,
              color: `${competencia === 4 ? "#fff" : "#000"}`,
              fontWeight: `${competencia === 4 ? "bold" : ""}`,
            }}
            onClick={() => {
              setCompetencia(4);
              setShow2(false);
            }}
          >
            {tipo == 1 ? "Coesão e Estrura do período" : "Competência 4"}
          </div>
          <div
            className={`botao cor`}
            style={{
              backgroundColor: `${
                competencia === 5 ? "rgba(208,201,0, 1)" : "rgba(208,201,0,0.3)"
              }`,
              color: `${competencia === 5 ? "#fff" : "#000"}`,
              fontWeight: `${competencia === 5 ? "bold" : ""}`,
            }}
            onClick={() => {
              setCompetencia(5);
              setShow2(false);
            }}
          >
            {tipo == 1 ? "Adequação ao tema" : "Competência 5"}
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default CarregarPDF;
