import { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  PdfLoader,
  PdfHighlighter,
  Popup,
  AreaHighlight,
} from "react-pdf-highlighter";
import { Spinner } from "./Spinner";
import { Sidebar } from "./Sidebar";

import { Row, Col } from "react-bootstrap";

import api from "../../services/api";
import { useAuth } from "../../context/auth";

const resetHash = () => {
  document.location.hash = "";
};

const HighlightPopup = ({ comment }) =>
  comment.text ? (
    <div className="Highlight__popup">
      {comment.emoji} {comment.text}
    </div>
  ) : null;

const nomeComp = (tipo, competencia) =>{
  if(competencia == 1){
    return tipo != 1 ? `Competência ${competencia}` : `Modalidade culta da língua`;
  }else if(competencia == 2){
    return tipo != 1 ? `Competência ${competencia}` : `Tipologia textual`;
  }else if(competencia == 3){
    return tipo != 1 ? `Competência ${competencia}` : `Argumentação`;
  }else if(competencia == 4){
    return tipo != 1 ? `Competência ${competencia}` : `Coesão e Estrura do período`;
  }else if(competencia == 5){
    return tipo != 1 ? `Competência ${competencia}` : `Adequação ao tema`;
  }else{
    return 'Observação';
  }
}

const CaixaDadosDeCorrecao = ({ highlight }, tipo) =>
  highlight ? (
    <div key="correcao" className="caixa-correcao">
      <p>
        <strong>{nomeComp(tipo, highlight.competencia)}</strong>
      </p>
      <p>{highlight.comment.text.replace("undefined", "")}</p>
    </div>
  ) : null;

const CarregarPDFAluno = (props) => {
  const { pdf, preview, setArquivo, id, highlights, dadosRedacao } = props;
  const { user } = useAuth();
  
  const tipo = dadosRedacao[0]?.tipo ? dadosRedacao[0]?.tipo : 0;
  
  const history = useHistory();

  useEffect(() => {
    if (!preview) {

    }
   // console.log(highlights);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function confirmarPDF(id) {
    const retorno = await api.get(`/validarArquivo/${id}`);

    if (retorno.data.message === "Arquivo validado") {
      history.push(`/correcao/${id}`);
    }
    
  }

  async function deletarPDF(pdf) {
    const retorno = await api.get(`/deletarArquivo/${pdf}`);

    if (retorno.data.message === "Arquivo deletado") {
      setArquivo("")
    }
  }

  return (
    <>
      <Row>
        <Col xs={12} md>
          {!preview ? (
            <p className="text-right">
              <Link className="btn btn-success" to={`/minhaclasse/${user.turma}`} >
                Voltar para minha classe
              </Link>
            </p>
          ) : (
            <Row>
              <Col xs={12} lg>
                <p>
                  <button
                    className="btn btn-success"
                    onClick={() => confirmarPDF(id)}
                  >
                    Se o arquivo esta correto, por favor CLIQUE AQUI para
                    continuar
                  </button>
                </p>
              </Col>
              <Col xs={12} lg>
                <p>
                  <button
                    className="btn btn-danger"
                    onClick={() => deletarPDF(pdf)}
                  >
                    Caso contrário clique aqui para enviar um novo arquivo
                  </button>
                </p>
              </Col>
            </Row>
          )}

          <div className="App" style={{ display: "flex" }}>
            <div
              style={{
                height: "85vh",
                width: "100%",
                position: "relative",
              }}
            >
              <PdfLoader
                url={`https://redacoes.nyc3.digitaloceanspaces.com/${pdf}`}
                beforeLoad={<Spinner />}
              >
                {(pdfDocument) => (
                  <>
                    <PdfHighlighter
                      pdfDocument={pdfDocument}
                      enableAreaSelection={false}
                      onScrollChange={resetHash}
                      pdfScaleValue="page-width"
                      scrollRef={(scrollTo) => {}}
                      onSelectionFinished={(
                        position,
                        content,
                        hideTipAndSelection,
                        transformSelection
                      ) => (
                        transformSelection(null)
                        
                      )}
                      highlightTransform={(
                        highlight,
                        index,
                        setTip,
                        hideTip,
                        viewportToScaled,
                        screenshot,
                        isScrolledTo
                      ) => {
                        const isTextHighlight = !Boolean(
                          highlight.content && highlight.content.image
                        );

                        const component = isTextHighlight ? (
                          <></>
                        ) : (
                          <div
                            className={`competencia_${highlight.competencia}`}
                          >
                            <AreaHighlight
                              isScrolledTo={isScrolledTo}
                              highlight={highlight}
                              onChange={() => null}
                            />
                          </div>
                        );

                        return (
                          <>
                            <Popup
                              popupContent={<HighlightPopup {...highlight} />}
                              onMouseOver={(popupContent) => {
                                CaixaDadosDeCorrecao(highlight, tipo);
                                setTip(highlight, (highlight) => popupContent);
                              }}
                              onMouseOut={hideTip}
                              key={index}
                              children={component}
                            />
                          </>
                        );
                      }}
                      highlights={highlights}
                    />
                  </>
                )}
              </PdfLoader>
            </div>
          </div>

          {!preview && (
            <Row>
              <Col>
                <div>
                  <hr />
                  <div className="alert alert-primary" role="alert">
                    <strong>Anotações em seu PDF</strong>
                  </div>
                  <hr />

                  <Sidebar tipo={tipo} highlights={highlights} />
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </>
  );
};

export default CarregarPDFAluno;
