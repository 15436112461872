import { useEffect, useState } from "react";
import MicRecorder from "mic-recorder-to-mp3";
import api from "../../services/api";
import * as S from "./styled";

import loadingIcon from '../../assets/img/loading.gif';

const Mp3Recorder = new MicRecorder({ bitRate: 128 });

function GravarAudio({ setArquivo, setTipo, loading }) {
  const [gravando, setGravando] = useState(false);
  const [bloqueio, setBloqueio] = useState(false);

  const start = () => {
    if (bloqueio) {
      console.log("Permission Denied");
    } else {
      Mp3Recorder.start()
        .then(() => {
          setGravando(true);
        })
        .catch((e) => console.error(e));
    }
  };

  const stop = () => {
    Mp3Recorder.stop()
      .getMp3()
      .then(([buffer, blob]) => {
        setGravando(false);

        const file = new File(buffer, `${Date.now()}.mp3`, {
          type: blob.type,
          lastModified: Date.now(),
        });

        // console.log("criando arquivo ", file.name);

        sendFile(file);
      })
      .catch((e) => console.log(e));
  };

  const sendFile = async (audio) => {
    const data = new FormData();
    data.append("audio", audio);

    try {
      await api.post("audioUpload", data);
    } catch (err) {
    } finally {
      setTipo("a");
      setArquivo(audio.name);
    }
  };

  useEffect(() => {
    navigator.getUserMedia(
      { audio: true },
      () => {
        console.log("Permission Granted");
        setBloqueio(false);
      },
      () => {
        console.log("Permission Denied");
        setBloqueio(true);
      }
    );
  }, []);

  return (
    <>
      {gravando ? (
        <button
          className="btn btn-danger text-center"
          onClick={stop}
          disabled={!gravando}
        >
          {loading ? <img src={loadingIcon} className="carregando" alt="Carregando Audio" /> : <S.MicIcon />}
        </button>
      ) : (
        <button
          className="btn btn-success text-center"
          onClick={start}
          disabled={gravando}
        >
          <S.MicIcon />
        </button>
      )}
    </>
  );
}

export default GravarAudio;
