import React from "react";

import {
  Chart as ChartJS,
  RadialLinearScale,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

import { PolarArea } from "react-chartjs-2";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

export default function Grafico({media1, media2, media3, media4, media5}) {
  const data = {
    labels: [
      "Competência 1",
      "Competência 2",
      "Competência 3",
      "Competência 4",
      "Competência 5",
    ],
    datasets: [
      {
        label: "Média Geral",
        data: [media1, media2, media3, media4, media5],
        backgroundColor: [
          "rgba(255, 99, 132, 0.5)",
          "rgba(54, 162, 235, 0.5)",
          "rgba(255, 206, 86, 0.5)",
          "rgba(75, 192, 192, 0.5)",
          "rgba(153, 102, 255, 0.5)",
          "rgba(255, 159, 64, 0.5)",
        ],
        borderWidth: 2,
      },
    ],
  };
  return <PolarArea data={data} />;
}
