import { useState, useEffect, FormEvent } from "react";
import { useHistory, Link } from "react-router-dom";

import { Container, Row, Col, Modal, Form, Button } from "react-bootstrap";

import api from "../../services/api";
import Menu from "../../components/Menu";

import * as S from "./styled";

import Template from "../../template";

import { useAuth } from "../../context/auth";

interface UsuarioAuth {
  user?: any;
  token?: String;
  nivel?: String;
  nome?: String;
}

interface Material {
  category: string;
  name: string;
  description: string;
  id: number;
}

interface Materiais {
  nome: string;
  tipo: string;
  categoria: string;
  link: string;
  id: number;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
function Material(props) {
  const { id } = props.match.params;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const history = useHistory();

  const user: UsuarioAuth  = useAuth();

// eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [materiais, setMateriais] = useState<Material[]>([]);

  const [name, setName] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [content, setContent] = useState("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [description, setDescription] = useState("");

  const [category, setCategory] = useState("");
  const [link, setLink] = useState("");

  const [videos, setVideos] = useState<Materiais[]>([]);
  const [noticias, setNoticias] = useState<Materiais[]>([]);
  const [arquivos, setArquivos] = useState<Materiais[]>([]);

  const [reload, setReload] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  async function handleAddConteudo(e: FormEvent) {
    e.preventDefault();

    const dataMaterial = {
      nome: name,
      categoria: category,
      link: link,
      material_id: id,
    };

    let response = await api.post("/materialAdd", dataMaterial);

    handleClose();

    setName("");
    setCategory("");
    setDescription("");
    setLink("");

    setReload(response.data);
  }

  async function deleteLink(e) {
    const { id } = e.target.dataset;

    let response = await api.delete(`/materialDelete/${id}`);

    setReload(response.data);
  }

  async function sendFile(e) {
    const data = new FormData();

    data.append("id", id);

    if (e.target.files[0]) {
      data.append("arquivo", e.target.files[0]);
    }
    const response = await api.post("arquivoUpload", data);

    setLink(`/${response.data.arquivo}`);
  }

  useEffect(() => {
    async function getVideos() {
      try {
        let response = await api.get(`/materialGet/${id}/video`);

        setVideos(response.data);
      } catch (err) {

      } finally {
        return false;
      }
    }

    async function getNoticias() {
      try {
        let response = await api.get(`/materialGet/${id}/noticia`);

        setNoticias(response.data);
      } catch (err) {
      } finally {
        return false;
      }
    }

    async function getArquivos() {
      try {
        let response = await api.get(`/materialGet/${id}/arquivo`);

        setArquivos(response.data);
      } catch (err) {
      } finally {
        return false;
      }
    }

    getArquivos();
    getVideos();
    getNoticias();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload]);

  return (
    <Template>
      <S.MainWrapper>
      <Container>
        <Row>
     
          <Col>
            <S.MainContent>
              <strong>Bem vindo ao material de apoio</strong>
              <Link to="/materiais" className="pull-right btn btn-success">
                Voltar aos Materiais
              </Link>
            </S.MainContent>
            {user.user.nivel === "a" && (
              <S.MainContent>
                <strong>Administração</strong>
                <Button
                  className="pull-right"
                  variant="success"
                  size="sm"
                  onClick={handleShow}
                >
                  Adicionar Conteúdo
                </Button>

                <Modal show={show} onHide={handleClose}>
                  <Modal.Header closeButton>
                    <Modal.Title>Adicionar Conteúdo</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <Form.Label>Nome do conteúdo</Form.Label>
                      <Form.Control
                        placeholder="Nome do conteúdo"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <hr />
                      <Form.Group controlId="exampleForm.SelectCustom">
                        <Form.Label>Tipo de Conteúdo</Form.Label>
                        <Form.Control
                          defaultValue={category}
                          as="select"
                          custom
                          onChange={(e) => setCategory(e.target.value)}
                        >
                          <option value="video">
                            Selecione o tipo do Conteúdo
                          </option>
                          <option value="video">Vídeo</option>
                          <option value="noticia">Notícia</option>
                          <option value="arquivo">Arquivo</option>
                        </Form.Control>
                      </Form.Group>
                      <hr />
                      <Form.Label>Link do conteúdo</Form.Label>
                      <Form.Control
                        placeholder="Link do conteúdo"
                        value={link}
                        onChange={(e) => setLink(e.target.value)}
                      />
                      <hr />
                      <Form.Label>Arquivo</Form.Label>
                      <Form.File name="arquivo" onChange={sendFile} />
                    </Form>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                      Cancelar
                    </Button>
                    <Button variant="primary" onClick={handleAddConteudo}>
                      Adicionar
                    </Button>
                  </Modal.Footer>
                </Modal>
              </S.MainContent>
            )}

            <S.MainContent>

              <Row>
                <Col sm={4}>
                  <h5>Vídeos</h5>
                  <ul>
                    {videos &&
                      videos.map((video, idx) => (
                        <li className="alert alert-info" key={idx}>
                          <a href={video.link}>{video.nome}</a>{" "}
                          {user.user.nivel === "a" && (
                            <span
                              onClick={deleteLink}
                              data-id={video.id}
                              className="pull-right"
                            >
                              Delete
                            </span>
                          )}
                        </li>
                      ))}
                  </ul>
                </Col>
                <Col sm={4}>
                  <h5>Notícias</h5>
                  <ul>
                    {noticias &&
                      noticias.map((noticia, idx) => (
                        <li className="alert alert-info" key={idx}>
                          <a href={noticia.link}>{noticia.nome}</a>{" "}
                          {user.user.nivel === "a" && (
                            <span
                              onClick={deleteLink}
                              data-id={noticia.id}
                              className="pull-right"
                            >
                              Delete
                            </span>
                          )}
                        </li>
                      ))}
                  </ul>
                </Col>
                <Col sm={4}>
                  <h5>Arquivos</h5>
                  <ul>
                    {arquivos &&
                      arquivos.map((arquivo, idx) => (
                        <li className="alert alert-info" key={idx}>
                          
                          <a
                            rel="noreferrer"
                            target="_blank"
                            href={`https://redacoes.nyc3.digitaloceanspaces.com${arquivo.link}`}
                          >
                            {arquivo.nome}
                          </a>{" "}
                          {user.user.nivel === "a" && (
                            <span
                              onClick={deleteLink}
                              data-id={arquivo.id}
                              className="pull-right"
                            >
                              Delete
                            </span>
                          )}
                        </li>
                      ))}
                  </ul>
                </Col>
              </Row>
            </S.MainContent>
          </Col>
        </Row>
      </Container>
    </S.MainWrapper>
    </Template>
  );
}

export default Material;
