import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import api from "../../services/api";
import GlobalStyles from "../../assets/css/global";

import { toast } from 'react-toastify';

import CarregarPDF from "../../components/CarregarPDF";

import { useCompetencia } from "../../context/competencia";

function Correction(props) {
  // eslint-disable-next-line no-unused-vars
  const { arquivo, id } = props;
  const history = useHistory();
  const { competencia, setCompetencia } = useCompetencia();

  const [dadosRedacao, setDadosRedacao] = useState([]);
  const [nota1, setNota1] = useState(0);
  const [nota2, setNota2] = useState(0);
  const [nota3, setNota3] = useState(0);
  const [nota4, setNota4] = useState(0);
  const [nota5, setNota5] = useState(0);
  
  // eslint-disable-next-line no-unused-vars
  const [fixo, setFixo] = useState(true);
  const [falta, setFalta] = useState(false);
  const [observacao, setObservacao] = useState();
  const [texto, setTexto] = useState("FINALIZAR CORREÇÃO");

  async function finalizarCorrecao() {

    try {
      const dataCorrection = {
        competencia1: nota1,
        competencia2: nota2,
        competencia3: nota3,
        competencia4: nota4,
        competencia5: nota5,
        falta: falta,
        id: id,
        observacao: observacao
      };

      let response = await api.post("/redacaoUpdate", dataCorrection);


      if (response.data.message === "Redação atualizada com sucesso") {
          toast.success("Correção efetuada, você será redirecionado para a próxima correção de mesmo tema e turma.");
          setCompetencia(0);
          window.location.href = `/corrigir/redacao/${response.data.id}`;
          return false;
      }

      if (response.data.message === "Redação finalizada") {
        toast("Correção efetuada, você não possui mais correções de mesmo tema/turma, você voltará para a página de redações.");
        history.push(`/redacoes`);
        return false;
      }
    } catch (err) {
    } finally {
      return true;
    }
  }

  useEffect(() => {
    async function getCorrecao(){
        const data = {
          id: id
        };
    
        const retorno = await api.post("/getCorrecao", data);
        setDadosRedacao(retorno.data.dadosRedacao);
  
        setNota1(retorno.data.dadosRedacao[0] ? parseInt(retorno.data.dadosRedacao[0].competencia1) : 0 );
        setNota2(retorno.data.dadosRedacao[0] ? parseInt(retorno.data.dadosRedacao[0].competencia2) : 0 );
        setNota3(retorno.data.dadosRedacao[0] ? parseInt(retorno.data.dadosRedacao[0].competencia3) : 0 );
        setNota4(retorno.data.dadosRedacao[0] ? parseInt(retorno.data.dadosRedacao[0].competencia4) : 0 );
        setNota5(retorno.data.dadosRedacao[0] ? parseInt(retorno.data.dadosRedacao[0].competencia5) : 0 );
        setFalta(retorno.data.dadosRedacao[0] ? retorno.data.dadosRedacao[0].falta : false );
        
    }

    getCorrecao();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <GlobalStyles />
      <Col className="correcoes">
        <Row>
          <Col xs={12} md>
            {arquivo && (
              <CarregarPDF
                pdf={`https://redacoes.nyc3.digitaloceanspaces.com/${arquivo}`}
                setFixo={setFixo}
                competencia={competencia}
                id={id}
                dadosRedacao={dadosRedacao}
              />
            )}
            <hr/>
            <p>
              <strong>
                Uma vez que a correção com as observações da redação/pdf forem
                finalizadas, você agora poderá marcar as observações e pontuação
                da redação para finalizar a correção.
              </strong>
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <table className="table table-striped table-bordered">
              <tr>
                <td
                  onClick={() => setFalta(falta === "1" ? false : "1")}
                  className={`clicavel ${falta === "1" && "marcado"}`}
                >
                  Texto Insuficiente
                </td>
                <td
                  onClick={() => setFalta(falta === "2" ? false : "2")}
                  className={`clicavel ${falta === "2" && "marcado"}`}
                >
                  Cópia
                </td>
                <td
                  onClick={() => setFalta(falta === "3" ? false : "3")}
                  className={`clicavel ${falta === "3" && "marcado"}`}
                >
                  Fuga ao Tema
                </td>
                <td
                  onClick={() => setFalta(falta === "4" ? false : "4")}
                  className={`clicavel ${falta === "4" && "marcado"}`}
                >
                  Fuga ao Gênero
                </td>
                <td
                  onClick={() => setFalta(falta === "5" ? false : "5")}
                  className={`clicavel ${falta === "5" && "marcado"}`}
                >
                  Fere Direitos Humanos
                </td>
                <td
                  onClick={() => setFalta(falta === "6" ? false : "6")}
                  className={`clicavel ${falta === "6" && "marcado"}`}
                >
                  Em Branco
                </td>
                <td
                  onClick={() => setFalta(falta === "7" ? false : "7")}
                  className={`clicavel ${falta === "7" && "marcado"}`}
                >
                  Outras Formas de Anulação
                </td>
              </tr>
            </table>
            <table className="table table-striped table-bordered">
              <tr>
                <td>
                  <h3>RESERVADO AO CORRETOR</h3>
                </td>
                <td>
                  N<sup>0</sup>
                  <br />0
                </td>
                <td>
                  N<sup>1</sup>
                  <br />
                  40
                </td>
                <td>
                  N<sup>2</sup>
                  <br />
                  80
                </td>
                <td>
                  N<sup>3</sup>
                  <br />
                  120
                </td>
                <td>
                  N<sup>4</sup>
                  <br />
                  160
                </td>
                <td>
                  N<sup>5</sup>
                  <br />
                  200
                </td>
              </tr>
              <tr>
                <td>
                  1 - Demonstrar domínio da norma culta da língua escrita.{" "}
                </td>
                <td>
                  <button
                    className={nota1 === 0 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota1(0)}
                  >
                    {nota1 === 0 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota1 === 40 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota1(40)}
                  >
                    {nota1 === 40 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota1 === 80 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota1(80)}
                  >
                    {nota1 === 80 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota1 === 120 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota1(120)}
                  >
                    {nota1 === 120 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota1 === 160 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota1(160)}
                  >
                    {nota1 === 160 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota1 === 200 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota1(200)}
                  >
                    {nota1 === 200 && `X`}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  2 - Compreender a proposta de redação e aplicar conceitos de
                  várias áreas de conhecimento para desenvolver o tema, dentro
                  dos limites estruturais do texto dissertativo-argumentativo.
                </td>
                <td>
                  <button
                    className={nota2 === 0 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota2(0)}
                  >
                    {nota2 === 0 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota2 === 40 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota2(40)}
                  >
                    {nota2 === 40 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota2 === 80 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota2(80)}
                  >
                    {nota2 === 80 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota2 === 120 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota2(120)}
                  >
                    {nota2 === 120 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota2 === 160 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota2(160)}
                  >
                    {nota2 === 160 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota2 === 200 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota2(200)}
                  >
                    {nota2 === 200 && `X`}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  3 - Selecionar, relacionar, organizar, e interpretar
                  informações, fatos, opiniões e argumentos em defesa do ponto
                  de vista.
                </td>
                <td>
                  <button
                    className={nota3 === 0 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota3(0)}
                  >
                    {nota3 === 0 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota3 === 40 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota3(40)}
                  >
                    {nota3 === 40 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota3 === 80 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota3(80)}
                  >
                    {nota3 === 80 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota3 === 120 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota3(120)}
                  >
                    {nota3 === 120 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota3 === 160 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota3(160)}
                  >
                    {nota3 === 160 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota3 === 200 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota3(200)}
                  >
                    {nota3 === 200 && `X`}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  4 - Demonstrar conhecimento dos mecanismos linguísticos
                  necessários para a construção da argumentação
                </td>
                <td>
                  <button
                    className={nota4 === 0 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota4(0)}
                  >
                    {nota4 === 0 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota4 === 40 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota4(40)}
                  >
                    {nota4 === 40 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota4 === 80 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota4(80)}
                  >
                    {nota4 === 80 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota4 === 120 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota4(120)}
                  >
                    {nota4 === 120 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota4 === 160 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota4(160)}
                  >
                    {nota4 === 160 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota4 === 200 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota4(200)}
                  >
                    {nota4 === 200 && `X`}
                  </button>
                </td>
              </tr>
              <tr>
                <td>
                  5 - Elaborar proposta de solução para o problema abordado,
                  mostrando respeito aos valores humanos e considerando a
                  diversidade sociocultural.
                </td>
                <td>
                  <button
                    className={nota5 === 0 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota5(0)}
                  >
                    {nota5 === 0 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota5 === 40 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota5(40)}
                  >
                    {nota5 === 40 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota5 === 80 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota5(80)}
                  >
                    {nota5 === 80 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota5 === 120 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota5(120)}
                  >
                    {nota5 === 120 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota5 === 160 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota5(160)}
                  >
                    {nota5 === 160 && `X`}
                  </button>
                </td>
                <td>
                  <button
                    className={nota5 === 200 ? `botaoNota ativo` : "botaoNota"}
                    onClick={() => setNota5(200)}
                  >
                    {nota5 === 200 && `X`}
                  </button>
                </td>
              </tr>
            </table>

            <hr />

            <table className="table table-striped table-bordered">
              <tr>
                <td className="text-right">
                  <h3>
                    <strong>Total: </strong>{" "}
                    {falta ? (
                      <>0</>
                    ) : (
                      <>{nota1 + nota2 + nota3 + nota4 + nota5}</>
                    )}
                  </h3>
                </td>
              </tr>
            </table>

            <hr />

            <table className="table table-striped table-bordered">
              <tr>
                <td>
                  <textarea
                    onChange={(e) => setObservacao(e.target.value)}
                    className="form-control"
                    placeholder="Digite sua observação ao aluno"
                    value={observacao}
                  ></textarea>
                  <hr />

                  <button
                    onClick={() => {
                      finalizarCorrecao();
                      setTexto("REDAÇÃO FINALIZADA, VOCÊ JÁ PODE SAIR");
                    }}
                    className="btn btn-danger pull-right"
                  >
                    {texto}
                  </button>
                </td>
              </tr>
            </table>

            <h4 className="text-center">
              Rua Gavião Peixoto 124- Salas 508 e 509 -Edifício Manhattan -
              2601-0195/ 98701-0195
              <br />
              cursodaniellevelasco@gmail.com{" "}
            </h4>
          </Col>
        </Row>
      </Col>

      <div className={`fixar none`}>
        <div
          className={`botao cor`}
          style={{
            backgroundColor: `${
              competencia === 1 ? "rgba(177,0,168, 1)" : "rgba(177,0,168, 0.3)"
            }`,
            color: `${competencia === 1 ? "#fff" : "#000"}`,
            fontWeight: `${competencia === 1 ? "bold" : ""}`,
          }}
          onClick={() => {
            setCompetencia(1);
          }}
        >
          {dadosRedacao.tipo == 1 ? 'Modalidade culta da língua' :'Competência 1'}
        </div>
        <div
          className={`botao cor`}
          style={{
            backgroundColor: `${
              competencia === 2 ? "rgba(255,0,0,1)" : "rgba(255,0,0,0.3)"
            }`,
            color: `${competencia === 2 ? "#fff" : "#000"}`,
            fontWeight: `${competencia === 2 ? "bold" : ""}`,
          }}
          onClick={() => {
            setCompetencia(2);
          }}
        >
          {dadosRedacao.tipo == 1 ? 'Tipologia textual' :'Competência 2'}
        </div>
        <div
          className={`botao cor`}
          style={{
            backgroundColor: `${
              competencia === 3 ? "rgba(29,29,245, 1)" : "rgba(29,29,245,0.3)"
            }`,
            color: `${competencia === 3 ? "#fff" : "#000"}`,
            fontWeight: `${competencia === 3 ? "bold" : ""}`,
          }}
          onClick={() => {
            setCompetencia(3);
          }}
        >
          {dadosRedacao.tipo == 1 ? 'Argumentação' :'Competência 3'}
        </div>
        <div
          className={`botao cor`}
          style={{
            backgroundColor: `${
              competencia === 4 ? "rgba(10,208,0, 1)" : "rgba(10,208,0,0.3)"
            }`,
            color: `${competencia === 4 ? "#fff" : "#000"}`,
            fontWeight: `${competencia === 4 ? "bold" : ""}`,
          }}
          onClick={() => {
            setCompetencia(4);
          }}
        >
          {dadosRedacao.tipo == 1 ? 'Coesão e Estrura do período' :'Competência 4'}
        </div>
        <div
          className={`botao cor`}
          style={{
            backgroundColor: `${
              competencia === 5 ? "rgba(208,201,0, 1)" : "rgba(208,201,0,0.3)"
            }`,
            color: `${competencia === 5 ? "#fff" : "#000"}`,
            fontWeight: `${competencia === 5 ? "bold" : ""}`,
          }}
          onClick={() => {
            setCompetencia(5);
          }}
        >
          {dadosRedacao.tipo == 1 ? 'Adequação ao tema' :'Competência 5'}
        </div>
      </div>
    </>
  );
}

export default Correction;
