import { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import { Container, Row, Col } from "react-bootstrap";

import api from "../../services/api";

import * as S from "./styled";

import Template from "../../template";
import { useAuth } from "../../context/auth";

function Redacoes() {
  const [loading, setLoading] = useState(true);

  const [redacoes, setRedacoes] = useState([]);
  const [temas, setTemas] = useState([]);
  const [turmas, setTurmas] = useState([]);

  const [filtro, setFiltro] = useState(0);

  const [temaId, setTemaId] = useState(0);
  const [turmaId, setTurmaId] = useState(0);

  const history = useHistory();

  const user = useAuth();

  if(user.user.nivel === "e") {
    history.push("/classe");
  }

  

  const getRedacoes = async (tema_id) => {
    try {
      let response = await api.get(`/redacoes/${tema_id}/${filtro}/${turmaId}`);
      setRedacoes(response.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const getTemas = async () => {
    try {
      let response = await api.get(`/pegarTemasGeral`);
      setTemas(response.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const getTurmas = async () => {
    try {

      let response = await api.get("/classes");

      setTurmas(response.data);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  const carregarCorretor = (id_corretor) => {
    let nome;


    if(id_corretor === 633){
      nome = "Corretor";
    }else if(id_corretor === 650){
      nome = "Corretor 01";
    }else if(id_corretor === 725){
      nome = "Corretor 02";
    }else if(id_corretor === 657){
      nome = "Corretor 03";
    }else if(id_corretor === 656){
      nome = "Corretor 04";
    }else if(id_corretor === 654){
      nome = "Corretor 05";
    }else if(id_corretor === 655){
      nome = "Corretora 07";
    }else if(id_corretor === 652){
      nome = "Corretor 08";
    }

    return nome;
  }

  useEffect(() => {
    setLoading(true);
    getTemas();
    getTurmas();
  }, []);

  useEffect(() => {
    setLoading(true);
    getRedacoes(temaId);
  }, [filtro, temaId, turmaId]);

  return (
    <Template>
      <S.MainWrapper>
        <Container fluid>
          <Row>
            <Col sm={12}>
              <S.MainContent>
                <Row>
                  <Col>
                    <div className="alert alert-primary" role="alert">
                      <strong>Correção - Redações</strong>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md>
                    {temas && temas.length >= 1 && (
                      <p>
                        <select
                          onChange={(e) => setTemaId(e.target.value)}
                          className="form-control"
                        >
                          <option>
                            Deseja filtrar por tema? escolha abaixo.
                          </option>
                          {temas &&
                            temas.map((tema, index) => (
                              <option key={index} value={tema.id}>
                                {tema.genero} {tema.aula} - {tema.nome}
                              </option>
                            ))}
                        </select>
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md>
                    {turmas && turmas.length >= 1 && (
                      <p>
                        <select
                          onChange={(e) => setTurmaId(e.target.value)}
                          className="form-control"
                        >
                          <option>
                            Deseja filtrar por turma? escolha abaixo.
                          </option>
                          {turmas &&
                            turmas.map((turma, index) => (
                              <option key={index} value={turma.id}>
                                {turma.name}
                              </option>
                            ))}
                        </select>
                      </p>
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md>
                    <p>
                      <select
                        onChange={(e) => setFiltro(e.target.value)}
                        className="form-control"
                      >
                        <option>Deseja filtrar por Status?</option>
                        <option value="1">Corrigida</option>
                        <option value="0">Pendentes</option>
                        <option value="2">Todas</option>
                      </select>
                    </p>
                  </Col>
                </Row>
                {loading ? (
                  <>
                    <Col className="text-center">
                      <div className="shadow p-3 mb-4 bg-white rounded fdark flex-c">
                        <div>
                          <div className="spinner-grow text-primary" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          <div
                            className="spinner-grow text-secondary"
                            role="status"
                          >
                            <span className="sr-only">Loading...</span>
                          </div>
                          <div className="spinner-grow text-success" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          <div className="spinner-grow text-danger" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          <div className="spinner-grow text-warning" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                          <div className="spinner-grow text-info" role="status">
                            <span className="sr-only">Loading...</span>
                          </div>
                        </div>
                        <div>
                          <strong>CARREGANDO</strong>
                        </div>
                      </div>
                    </Col>
                  </>
                ) : (
                  <>
                    <div className="table-responsive">
                      <table className="table table-bordered table-striped">
                        <thead>
                          <tr>
                            <th className="th-xl">Aluno(a)</th>
                            <th className="th-xl">Tema</th>
                            <th className="th-grande">Corretor</th>
                            <th className="th-grande">Turma</th>
                            <th className="th-grande">Aula/Bônus</th>
                            <th className="th-grande">Status</th>
                            <th className="th-grande">Ação</th>
                          </tr>
                        </thead>
                        <tbody>
                          {redacoes &&
                            redacoes.map((redacao, idx) => (
                              <>
                                <tr>
                                  <td>{redacao.aluno}</td>
                                  <td>{redacao.nome_redacao}</td>
                                  <td>{carregarCorretor(redacao.id_corretor)}</td>
                                  <td>{redacao.turma}</td>
                                  <td>Aula {redacao.aula}</td>
                                  <td>
                            
                                  {!redacao.finalizada ? (<>
                                    <span className="badge badge-pill badge-success">
                                        Envio Pendente do Aluno
                                      </span>
                                      </>) : (<>
                                    {redacao.competencia1 ? (
                                      <span className="badge badge-pill badge-success">
                                        Corrigida
                                      </span>
                                    ) : (
                                      <span className="badge badge-pill badge-warning">
                                        Pendente
                                      </span>
                                    )}
                                    </>)}
                                  </td>
                                  <td>
                                  {!redacao.finalizada ? (<>
                                    <span className="badge badge-pill badge-indo">
                                        Aguardando Aluno
                                      </span>
                                      </>) : (<>
                                    {redacao.competencia1 ? (
                                      <Link
                                        to={`/corrigir/redacao/${redacao.id}`}
                                        className="btn btn-sm btn-warning"
                                      >
                                        Editar Correção
                                      </Link>
                                    ) : (
                                      <Link
                                        to={`/corrigir/redacao/${redacao.id}`}
                                        className="btn btn-sm btn-danger"
                                      >
                                        Fazer Correção
                                      </Link>
                                    )}
                                    </>)}
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                )}
              </S.MainContent>
            </Col>
          </Row>
        </Container>
      </S.MainWrapper>
    </Template>
  );
}

export default Redacoes;
