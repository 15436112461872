import styled from "styled-components";
import { Search } from "@styled-icons/boxicons-regular/Search";
import { Delete } from "@styled-icons/fluentui-system-filled/Delete";
import { EditAlt } from "@styled-icons/boxicons-regular/EditAlt";

export const MainWrapper = styled.div`
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  color: #fff;

  .dropdown-item {
    color: #333 !important;
  }

  .bgdark {
    background-color: #007bc6;
    margin-left: -15px;

    a {
      background-color: #05367f;
      margin-bottom: 1px;
      color: #fff;
    }

    hr {
      border-top: 1px solid #3b95f2;
      margin: 10px 0px;
    }
  }

  .btn-primary {
    background-color: #05367f;
    display: block;
    border: 0px;
    width: 100%;
  }

  .card {
    margin: 15px 0px;
  }
`;

export const MainContent = styled.div`
  background-color: #fff;
  padding: 15px;
  display: block;
  margin: 10px 0px;
  color: #333;

  .drawcanvas{
    position: absolute;
    z-index: 999;
    width: 100%;
    height: 100%;
    max-width: 1071px;
    max-height: 1515px;
  }

  canvas{
    position: relative;
  }

  .rectangle{
    z-index: 999;
    position: absolute;
  }

  .pull-right {
    position: relative;
    float: right;
  }

  .dropzone {
    width: 100%;
    min-height: 300px;
    display: flex;
    align-items: center;
    align-content: center;
    text-align: center;
    border:1px solid #333;
    background-color: #eee;

    p {
      text-align: center;
      display: block;
      width: 100%;
    }
  }
`;

export const SearchIcon = styled(Search)`
  color: #fff;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;

export const DeleteIcon = styled(Delete)`
  color: #fff;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;

export const EditIcon = styled(EditAlt)`
  color: #fff;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;

export const NewsPapperIcon = styled(EditAlt)`
  color: #fff;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;
