import styled from "styled-components";
import { Search } from "@styled-icons/boxicons-regular/Search";
import { Delete } from "@styled-icons/fluentui-system-filled/Delete";
import { EditAlt } from "@styled-icons/boxicons-regular/EditAlt";
import { Category } from "@styled-icons/boxicons-regular/Category";
import { School } from "@styled-icons/ionicons-outline/School";
import { Newspaper } from "@styled-icons/ionicons-outline/Newspaper";
import { PaperPlane } from "@styled-icons/ionicons-outline/PaperPlane";
import { DeleteDismiss } from "@styled-icons/fluentui-system-regular/DeleteDismiss";
import { LearningApp } from "@styled-icons/fluentui-system-regular/LearningApp";
import { Check2Circle } from "@styled-icons/bootstrap/Check2Circle";
import { Create } from "@styled-icons/ionicons-outline/Create";
import { PencilSquare } from "@styled-icons/bootstrap/PencilSquare";


import Foto from "../../assets/img/topo.jpg";

export const MainWrapper = styled.div`
  background-color: #f5f5f5;
  width: 100%;
  min-height: 100vh;
  color: #fff;

  .dropdown-item {
    color: #333 !important;
  }

  .bgdark {
    background-color: #007bc6;
    margin-left: -15px;

    a {
      background-color: #05367f;
      margin-bottom: 1px;
      color: #fff;
    }

    hr {
      border-top: 1px solid #3b95f2;
      margin: 10px 0px;
    }
  }

  .btn-primary {
    background-color: #05367f;
    display: block;
    border: 0px;
    width: 100%;
  }

  .card {
    margin: 15px 0px;
  }
`;

export const WrapperTurma = styled.div`
  background-image: url("${Foto}");
  background-position: bottom center;
  background-size: cover;
  display: flex;
  width: 100%;
  height: 250px;
  line-height: 12px;
  font-size: 12px;
  padding: 40px;
  color: #eee;
  flex-direction: column;
  align-content: flex-end;
  justify-content: flex-end;
  margin: 15px 0px;
  border-radius: 10px;

  strong {
    display: inline-block;
    font-size: 32px;
    color: #fff;
    border-bottom: 1px solid #fff;
    padding-bottom: 20px;
    margin-bottom: 0px;
  }
`;

export const MainContent = styled.div`
  background-color: #fff;
  padding: 15px;
  display: block;
  margin: 10px 0px;
  color: #333;

  .pull-right {
    position: relative;
    float: right;
  }
`;

export const TopButton = styled.div`
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 9;
  cursor: pointer;
`;

export const TipoAviso = styled.div`
  width: 60px;
  min-width: 60px;
  height: 60px;
  min-height: 60px;
  margin-right: 20px;
  margin-bottom: 15px;
  background-color: #007bc6;
  color: #fff;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CaixaTexto = styled.div`
  display: block;
  width: 100%;
  position: relative;
  min-height: 90px;
  overflow: hidden;
  padding: 10px 0px;
`;

export const SearchIcon = styled(Search)`
  color: #fff;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;

export const DeleteIcon = styled(Delete)`
  color: #fff;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;

export const EditIcon = styled(EditAlt)`
  color: #fff;
  height: 18px;
  width: 18px;
  margin-right: 10px;
  margin-top: -5px;
`;

export const CategoryIcon = styled(Category)`
  color: #fff;
  height: 32px;
  width: 32px;
`;

export const SchoolIcon = styled(School)`
  color: #fff;
  height: 32px;
  width: 32px;
`;

export const NewspaperIcon = styled(Newspaper)`
  color: #fff;
  height: 32px;
  width: 32px;
`;

export const PaperPlaneIcon = styled(PaperPlane)`
  color: #fff;
  height: 32px;
  width: 32px;
`;

export const DeletarIcone = styled(DeleteDismiss)`
  color: red;
  height: 24px;
  width: 24px;
`;

export const LearningAppIcon = styled(LearningApp)`
  color: #fff;
  height: 32px;
  width: 32px;
`;

export const CheckIcon = styled(Check2Circle)`
  color: #007bc6;
  height: 16px;
  width: 16px;
`;

export const CreateIcon = styled(Create)`
  color: #fff;
  height: 32px;
  width: 32px;
`;

export const PencilSquareIcon = styled(PencilSquare)`
  color: #fff;
  height: 32px;
  width: 32px;
`;
