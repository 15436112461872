import styled from "styled-components";
import { Mic } from "@styled-icons/bootstrap/Mic";
import { Attachment } from "@styled-icons/entypo/Attachment";

export const MicIcon = styled(Mic)`
  color: #fff;
  height: 18px;
  width: 18px;
`;

export const AttachmentIcon = styled(Attachment)`
  color: #fff;
  height: 18px;
  width: 18px;
`;
