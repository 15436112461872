import React from 'react';
import { BrowserRouter, Route } from "react-router-dom";
import Login from "./pages/Login";
import CadastroAluno from "./pages/CadastroAluno";
import ValidarAluno from "./pages/ValidarAluno";
import Main from "./pages/Main";
import Redacoes from "./pages/Redacoes";
import RedacaoCriar from "./pages/RedacaoCriar";
import UploadRedacoes from "./pages/UploadRedacoes";
import FazerCorrecao from "./pages/FazerCorrecao";
import CheckRedacoes from "./pages/CheckRedacoes";
import RedacaoPdf from "./pages/RedacaoPdf";
import Classes from "./pages/Classes";
import Classe from "./pages/Classe";
import Medias from "./pages/Medias";
import Temas from "./pages/Temas";
import Materiais from "./pages/Materiais";
import Material from "./pages/Material";
import Suporte from "./pages/Suporte";
import Usuarios from "./pages/Usuarios";
import Mentoria from "./pages/Mentoria";
import Monitores from "./pages/Monitores";
import ListaMentoria from "./pages/ListaMentoria";
import Atividades from "./pages/Atividades";
import Corretores from "./pages/Corretores";
import Competencias from "./pages/Competencias";

export const PrivateRoute = ({ component: Component, socket, ...rest }) => (
  <Route
    {...rest}
    render={(props) => <Component socket={socket} {...props} />}
  />
);

function Routes(props) {
  const { socket } = props;

  return (
    <BrowserRouter>
      <Route path="/" exact component={Login} />
      <Route path="/recadaopdf/:id/:pdf" exact component={RedacaoPdf} />
      <Route path="/novo-cadastro/:chave" exact component={CadastroAluno} />
      <PrivateRoute
        path="/validar/cadastro/:cpf"
        exact
        component={ValidarAluno}
      />
      <PrivateRoute path="/logado" component={Main} />
      <PrivateRoute
        path="/escrever/redacao/:id"
        exact
        component={RedacaoCriar}
      />
      <PrivateRoute path="/redacao/:id" exact component={UploadRedacoes} />
      <PrivateRoute
        path="/corrigir/:redacao/:id"
        exact
        component={FazerCorrecao}
      />
      <PrivateRoute path="/correcao/:id" exact component={CheckRedacoes} />
      <PrivateRoute path="/redacoes" component={Redacoes} />
      <PrivateRoute path="/atividades" exact component={Atividades} />
      <PrivateRoute path="/atividades/:id" exact component={Atividades} />
      <PrivateRoute path="/minhaclasse/:id" component={Classe} />
      <PrivateRoute path="/medias" component={Medias} />
      <PrivateRoute path="/cadastro/:id" component={Classe} />
      <PrivateRoute path="/classe" component={Classes} />
      <PrivateRoute path="/temas" component={Temas} />
      <PrivateRoute path="/corretores" component={Corretores} />
      <PrivateRoute path="/material/:id" component={Material} />
      <PrivateRoute path="/materiais" component={Materiais} />
      <PrivateRoute path="/suporte" socket={socket} component={Suporte} />
      <PrivateRoute path="/usuarios" component={Usuarios} />
      <PrivateRoute path="/competencias" component={Competencias} />
      <PrivateRoute path="/mentoria" socket={socket} component={Mentoria} />
      <PrivateRoute path="/monitores" component={Monitores} />
      <PrivateRoute
        path="/listamentoria"
        socket={socket}
        component={ListaMentoria}
      />
    </BrowserRouter>
  );
}

export default Routes;
