import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Container,
  Row,
  Col,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import api from "../../services/api";
import { useAuth } from "../../context/auth";
import Template from "../../template";

import ListaAlunos from "../ListaAlunos";
import ListaRedacoes from "../ListaRedacoes";

import * as S from "./styled";

function Classe(props) {
  const { user } = useAuth();
  const date1 = new Date();

  const { id } = props.match.params;

  const [loading, setLoading] = useState(true);
  const [classe, setClasse] = useState([]);

  const [lgShow, setLgShow] = useState(false);

  const [apoio, setApoio] = useState("");
  const [arquivo, setArquivo] = useState("");
  const [nomeAtividade, setNomeAtividade] = useState("");

  const [aviso, setAviso] = useState("");
  const [avisos, setAvisos] = useState([]);
  const [showAvisos, setShowAviso] = useState(true);

  const [showRedacoes, setShowRedacoes] = useState(false);

  const [atividades, setAtividades] = useState([]);
  const [showAtividades, setShowAtividades] = useState(false);
  const [disponiveis, setDisponiveis] = useState(true);
  const [tipo, setTipo] = useState("Todos");

  // eslint-disable-next-line no-unused-vars
  const [alunos, setAlunos] = useState([]);
  const [showAlunos, setShowAlunos] = useState(false);

  const [clicado, setClicado] = useState(false);

  const [tipoRedacao, setTipoRedacao] = useState(0);

  /* cadastrar aviso */
  const handleAviso = async () => {
    const data = {
      aviso,
      turma_id: id,
      data: date1,
    };

    try {
      const retorno = await api.post(`/classe/aviso`, data);

      setAvisos([
        {
          id: retorno.data.id,
          aviso,
          turma_id: id,
          data: date1,
        },
        ...avisos,
      ]);

      setAviso("");
      toast.success("Aviso enviado com sucesso!");
    } catch (err) {
      toast.error("Erro ao enviar aviso!");
    }
  };

  /* deletar aviso */
  const deleteAviso = async (id) => {
    try {
      await api.delete(`/deleteAviso/${id}`);

      var avisosFiltrados = avisos.filter(function (value, index, arr) {
        return value.id !== id;
      });

      setAvisos(avisosFiltrados);

      toast.success("Aviso deletado com sucesso!");
    } catch (err) {
      toast.error("Erro ao deletar aviso!");
    }
  };

  /* deletar atividade */
  const deleteAtividade = async (id) => {
    try {
      await api.delete(`/deleteAtividade/${id}`);

      var avisosFiltrados = avisos.filter(function (value, index, arr) {
        return value.id !== id;
      });

      setAvisos(avisosFiltrados);

      toast.success("Aviso deletado com sucesso!");
    } catch (err) {
      toast.error("Erro ao deletar aviso!");
    }
  };

  /* configuração para exibição de itens */
  const mostrarItens = (item) => {
    setLoading(true);
    if (item === "avisos") {
      setShowAviso(true);
      setShowAtividades(false);
      setShowAlunos(false);
      setShowRedacoes(false);
      setLoading(false);
    } else if (item === "atividades") {
      getAtividades();
      setShowAviso(false);
      setShowAtividades(true);
      setShowAlunos(false);
      setShowRedacoes(false);
    } else if (item === "alunos") {
      getAlunos();
      setShowAviso(false);
      setShowAtividades(false);
      setShowAlunos(true);
      setShowRedacoes(false);
    } else if (item === "redacoes") {
      setShowAviso(false);
      setShowAtividades(false);
      setShowAlunos(false);
      setShowRedacoes(true);
      setLoading(false);
    }
  };

  /* recuperar termas/atividades da classe */
  const getAtividades = async () => {
    const data = {
      id,
      tipo,
      disponiveis,
      temaId: 0,
    };

    try {
      const retorno = await api.post(`/getAtividades/`, data);

      setAtividades(retorno.data.atividades);
      setLoading(false);
    } catch (err) {
      toast.error("Erro ao recuperar atividades!");
    }
  };

  /* recuperar alunos e redações da classe */
  const getAlunos = async () => {
    try {
      const retorno = await api.get(`/getAlunos/${id}`);

      setAlunos(retorno.data);
      setLoading(false);
    } catch (err) {
      toast.error("Erro ao recuperar alunos!");
    }
  };

  /* Validar datas se esta ou não expirado */
  const validaDataClasse = (dataTema, horaTema) => {
    let strData = dataTema;
    let srtHora = horaTema;

    var arr = `${strData} ${srtHora}`.split(/[- :]/);
    const validarData = new Date(
      arr[0],
      arr[1] - 1,
      arr[2],
      arr[3],
      arr[4],
      arr[5]
    );

    if (validarData > date1) {
      return false;
    } else {
      return true;
    }
  };

  /* formatar data para o padrão BR */
  const formatarData = (dataTurma) => {
    if (dataTurma) {
      let dataFinal = dataTurma.split("T");

      return dataFinal[0].split("-").reverse().join("/");
    }
  };

  const getTypeClass = async () => {
    
    try {
      const retorno = await api.get(`/getClasse/${id}`);
      console.log(retorno);

      
      return retorno.data[0].type === 'Internet' ? 1 : 0;

    }catch(err){
      toast.error("Erro ao recuperar tipo da classe!");
    }

  };

  /* criar redação/atividade */
  const criarRedacao = async (id, rc_id, turma_id) => {
    

    const dataTheme = {
      id,
      rc_id,
      turma_id,
      tipo: tipoRedacao
    };

    let response = await api.post("/redacoes", dataTheme);

    if (
      response.data.message === "Já existe uma redação cadastrada com este tema"
    ) {
      toast.error(
        "Você já tem uma redação com este tema, iremos lhe redirecionar para suas redações"
      );
    } else if (response.status === 200) {
      toast.success(
        "Redação adicionada com sucesso, vamos mover você até sua área de redações"
      );
    }

    setShowAviso(false);
    setShowAtividades(false);
    setShowAlunos(false);
    setShowRedacoes(true);
    setClicado(false);
  };

  useEffect(() => {


    /* pegar a classe que esta ao carregar o componente */
    async function getClasse() {
      try {
        let response = await api.get(`/getClasse/${id}`);
     
        setTipoRedacao(response.data[0].type === 'Internet' ? 1 : 0)
        setClasse(response.data[0]);
      } catch (err) {
      } finally {
        return false;
      }
    }

    /* já recuperamos os avisos que existem para a turma */
    const getAvisos = async () => {
      try {
        let response = await api.get(`/getAvisos/${id}`);

        setAvisos(response.data);
      } catch (err) {
      } finally {
        return false;
      }
    };

    /* confirmar se existe turma */
    if (id) {
      getClasse();
      getAvisos();

      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [id]);

  useEffect(() => {
    setLoading(true);
    try {
      getAtividades();
    } catch (err) {
      toast.error("Erro ao enviar aviso!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tipo, disponiveis]);

  return (
    <Template>
      <S.MainWrapper>
        <Container fluid>
          <Row>
            <Col sm={12} md={8}>
              <S.WrapperTurma>
                <strong>{classe.name}</strong>
                <br />
                {classe && formatarData(classe?.created_at)}
              </S.WrapperTurma>
            </Col>
            <Col sm={12} md={4}>
              <Row>
                <Col sm={12}>
                  <div
                    className="alert alert-info alinhar-icone-texto"
                    role="alert"
                    onClick={() => mostrarItens("avisos")}
                  >
                    <S.NewspaperIcon /> Mostrar Avisos
                  </div>
                </Col>
                <Col sm={12}>
                  <div
                    className="alert alert-info alinhar-icone-texto"
                    role="alert"
                    onClick={() => mostrarItens("redacoes")}
                  >
                    <S.PencilSquareIcon /> Minhas Redações
                  </div>
                </Col>
                {user.nivel === "a" && (
                  <Col sm={12}>
                    <div
                      className="alert alert-info alinhar-icone-texto"
                      role="alert"
                      onClick={() => mostrarItens("alunos")}
                    >
                      <S.SchoolIcon /> Mostrar Alunos
                    </div>
                  </Col>
                )}
                <Col sm={12}>
                  <div
                    className="alert alert-info alinhar-icone-texto"
                    role="alert"
                    onClick={() => mostrarItens("atividades")}
                  >
                    <S.CategoryIcon /> Mostrar Atividades
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>

          {loading ? (
            <Row>
              <Col className="text-center">
                <div className="shadow p-3 mb-4 bg-white rounded fdark flex-c">
                  <div>
                    <div className="spinner-grow text-primary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-secondary" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-success" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-danger" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-warning" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                    <div className="spinner-grow text-info" role="status">
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                  <div>
                    <strong>CARREGANDO</strong>
                  </div>
                </div>
              </Col>
            </Row>
          ) : (
            <>
              {showAvisos && (
                <>
                  <Row>
                    <Col>
                      <div className="shadow-sm p-3 mb-4 bg-white rounded fdark">
                        <strong>Mural de Avisos</strong>
                      </div>
                    </Col>
                  </Row>
                  {user.nivel === "a" && (
                    <Row>
                      <Col>
                        <div className="shadow p-3 mb-4 bg-white rounded fdark">
                          <S.TipoAviso>
                            <S.PaperPlaneIcon />
                          </S.TipoAviso>
                          <div className="flex100 flex-c">
                            <textarea
                              className="form-control"
                              onChange={(e) => setAviso(e.target.value)}
                              placeholder="Escreva um aviso para a turma"
                              value={aviso}
                            />
                          </div>
                          <button
                            className="btn btn-info btn-blue btn-xs"
                            onClick={handleAviso}
                          >
                            Enviar
                          </button>
                        </div>
                      </Col>
                    </Row>
                  )}

                  <Row>
                    {avisos &&
                      avisos.map((aviso) => (
                        <Col xs={12}>
                          <div className="shadow p-3 mb-4 bg-white rounded fdark dele">
                            {user.nivel === "a" && (
                              <S.TopButton
                                onClick={() => deleteAviso(aviso.id)}
                              >
                                <OverlayTrigger
                                  key="top"
                                  placement="top"
                                  overlay={<Tooltip>Deletar</Tooltip>}
                                >
                                  <S.DeletarIcone />
                                </OverlayTrigger>
                              </S.TopButton>
                            )}
                            <S.TipoAviso>
                              <S.NewspaperIcon />
                            </S.TipoAviso>
                            <div>
                              <strong>{aviso.aviso}</strong>
                              <br />
                              <small>{formatarData(aviso.created_at)}</small>
                            </div>
                          </div>
                        </Col>
                      ))}
                  </Row>
                </>
              )}

              {showAtividades && (
                <>
                  <Row>
                    <Col>
                      <div className="shadow-sm p-3 mb-4 bg-white rounded fdark">
                        <strong>Atividades da Classe</strong>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="text-center">
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => setDisponiveis(true)}
                      >
                        {disponiveis && <S.CheckIcon />} Mostrar Disponíveis
                      </button>
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => setDisponiveis(false)}
                      >
                        {!disponiveis && <S.CheckIcon />} Mostrar Expirados
                      </button>
                      |
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => setTipo("Tema")}
                      >
                        {tipo === "Tema" && <S.CheckIcon />} Mostrar Temas
                      </button>
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => setTipo("Simulado")}
                      >
                        {tipo === "Simulado" && <S.CheckIcon />} Mostrar
                        Simulados
                      </button>
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => setTipo("Tema Bônus")}
                      >
                        {tipo === "Tema Bônus" && <S.CheckIcon />} Mostrar Bônus
                      </button>
                      <button
                        type="button"
                        className="btn btn-link"
                        onClick={() => setTipo("Todos")}
                      >
                        {tipo === "Todos" && <S.CheckIcon />} Mostrar Todos
                      </button>
                      <hr />
                    </Col>
                  </Row>
                  <Row>
                    {user.nivel === "a" && (
                      <Col xs={12} sm={6} md={4} lg={3} xl={2}>
                        <div className="shadow text-center p-3 mb-4 bg-white align-items-center rounded fdark d-flex flex-column align-content-stretch flex-wrap">
                          <S.TipoAviso className="mr-0">
                            <S.CreateIcon />
                          </S.TipoAviso>

                          <div>
                            <S.CaixaTexto>
                              <strong>Nova atividade</strong>
                            </S.CaixaTexto>

                            <h5>
                              <span className="btn btn-success">
                                {" "}
                                <Link
                                  className="btn btn-primary"
                                  to={`/atividades/${id}`}
                                >
                                  CRIAR
                                </Link>
                              </span>
                            </h5>
                          </div>
                        </div>
                      </Col>
                    )}

                    {atividades &&
                      atividades.map((atividade) => (
                        <>
                          {!disponiveis ===
                            validaDataClasse(
                              atividade.entrega,
                              `${atividade.hora}:00`
                            ) && (
                            <Col xs={12} sm={6} md={4} lg={3} xl={2}>
                              <div className="shadow text-center p-3 mb-4 bg-white align-items-center rounded fdark d-flex flex-column align-content-stretch flex-wrap">
                                {user.nivel === "a" && (
                                  <S.TopButton
                                    onClick={() =>
                                      deleteAtividade(atividade.id)
                                    }
                                  >
                                    <OverlayTrigger
                                      key="top"
                                      placement="top"
                                      overlay={<Tooltip>Deletar</Tooltip>}
                                    >
                                      <S.DeletarIcone />
                                    </OverlayTrigger>
                                  </S.TopButton>
                                )}

                                <S.TipoAviso className="mr-0">
                                  <S.LearningAppIcon />
                                </S.TipoAviso>

                                <div>
                                  <span
                                    className={
                                      atividade.genero === "Tema"
                                        ? "badge badge-pill badge-primary"
                                        : atividade.genero === "Simulado"
                                        ? "badge badge-pill badge-warning"
                                        : "badge badge-pill badge-success"
                                    }
                                  >
                                    Aula {atividade.aula}: {atividade.genero}
                                  </span>{" "}
                                  <S.CaixaTexto>{atividade.nome}</S.CaixaTexto>
                                  <hr />
                                  <div className="text-center">
                                    <small>Data para Entrega:</small>{" "}
                                    <span className="badge badge-pill badge-danger">
                                      {formatarData(atividade.entrega)}{" "}
                                      {atividade.hora}
                                    </span>
                                    <hr />
                                    <h5>
                                      <span
                                        className="btn btn-sm btn-warning"
                                        onClick={() => {
                                          setLgShow(true);
                                          setArquivo(atividade.arquivo);
                                          setApoio(atividade.descricao);
                                          setNomeAtividade(atividade.nome);
                                        }}
                                      >
                                        MATERIAL DE APOIO
                                      </span>
                                    </h5>
                                    {validaDataClasse(
                                      atividade.entrega,
                                      `${atividade.hora}:00`
                                    ) ? (
                                      <h5>
                                        <span className="btn btn-sm btn-danger">
                                          VENCIDO{" "}
                                          {validaDataClasse(
                                            atividade.entrega,
                                            `${atividade.hora}:00`
                                          )}
                                        </span>
                                      </h5>
                                    ) : (
                                      <h5>
                                        {!clicado ? (
                                          <>
                                            <span
                                              className="btn btn-sm btn-success"
                                              onClick={() => {
                                                setClicado(true);
                                                criarRedacao(
                                                  atividade.tema_id,
                                                  atividade.id,
                                                  id
                                                );
                                              }}
                                            >
                                              CRIAR REDAÇÃO
                                            </span>
                                          </>
                                        ) : (
                                          <>
                                            <div className="shadow p-3 mb-4 bg-white rounded fdark flex-c">
                                              <div>
                                                <div
                                                  className="spinner-grow text-primary"
                                                  role="status"
                                                >
                                                  <span className="sr-only">
                                                    Loading...
                                                  </span>
                                                </div>
                                                <div
                                                  className="spinner-grow text-secondary"
                                                  role="status"
                                                >
                                                  <span className="sr-only">
                                                    Loading...
                                                  </span>
                                                </div>
                                                <div
                                                  className="spinner-grow text-success"
                                                  role="status"
                                                >
                                                  <span className="sr-only">
                                                    Loading...
                                                  </span>
                                                </div>
                                              </div>
                                              <div>
                                                <strong>Criando</strong>
                                              </div>
                                            </div>
                                          </>
                                        )}
                                      </h5>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Col>
                          )}
                        </>
                      ))}
                  </Row>
                </>
              )}

              {showAlunos && <ListaAlunos turma={classe.name} id_turma={id} />}

              {showRedacoes && (
                <ListaRedacoes turma={classe.name} id_turma={id} />
              )}
            </>
          )}
        </Container>

        <Modal
          size="lg"
          show={lgShow}
          onHide={() => setLgShow(false)}
          aria-labelledby="example-modal-sizes-title-lg"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-modal-sizes-title-lg">
              <small>Material de Apoio</small>
              <br />
              <strong>{nomeAtividade}</strong>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {apoio}

            {arquivo && (
              <>
                <hr />

                <a
                  href={`https://redacoes.nyc3.digitaloceanspaces.com${arquivo}`}
                  rel="noreferrer"
                  title="material de apoio"
                  target="_blank"
                  className="btn btn-primary"
                >
                  Abrir Arquivo de apoio
                </a>
              </>
            )}
          </Modal.Body>
        </Modal>
      </S.MainWrapper>
    </Template>
  );
}

export default Classe;
