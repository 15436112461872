import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

import api from "../../services/api";
import Template from "../../template";
import { toast } from "react-toastify";

import GravarAudio from "../../components/GravarAudio";

import * as S from "./styled";

import { useAuth } from "../../context/auth";

const ListaMentoria = (props) => {
  const { user } = useAuth();

  const { socket } = props;

  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [myId, setMyId] = useState(user.id);

  const [alunos, setAlunos] = useState([]);

  const [alunoId, setIdDoAluno] = useState(0);
  const [alunoNome, setNomeDoAluno] = useState("");

  const [arquivo, setArquivo] = useState(false);
  const [tipo, setTipo] = useState("m");

  const [loading, setLoading] = useState(false);

  async function validaMeuId() {
    try {
      let response = await api.get(`/checkId`);

      setMyId(response.data.data);
    } catch (err) {
    } finally {
      return false;
    }
  }

  async function getMensagensPassadas(userId) {
    try {
      let response = await api.get(`/getMensagens/${userId}`);

      setMessages(response.data.reverse());
    } catch (err) {
    } finally {
      return false;
    }
  }

  async function saveMessage() {
    const dataMensagem = {
      user_id: alunoId,
      monitor_id: myId,
      message: message.trim(),
      view: 0,
      tipo,
      arquivo,
    };

    try {
      await api.post(`/saveMessage/`, dataMensagem);

      let dia = new Date();

      let arquivoFinal = arquivo ? `${arquivo}` : "";

      socket.emit("chat.message", {
        id: myId,
        user_id: alunoId,
        monitor_id: myId,
        message,
        view: 0,
        token: user.token,
        created_at: dia,
        arquivo: arquivoFinal,
        tipo,
      });

      setMessage("");
      setTipo("m");
      setLoading(false);
    } catch (err) {
    } finally {
      return false;
    }
  }

  async function uploadFile(e) {
    const data = new FormData();

    if (e.target.files[0]) {
      data.append("arquivo", e.target.files[0]);

      try {
        const response = await api.post("imagemUpload", data);
        setTipo("i");
        setArquivo(response.data.arquivo);
        setMessage("imagem");
        console.log("Arquivo enviado: ", response.data.arquivo);
      } catch (error) {}
    }
  }

  useEffect(() => {
    validaMeuId();
    async function pegarAlunosMentoria() {
      let dia = new Date();

      const dataAlunos = {
        dia: dia.getUTCDate(),
        mes: parseInt(dia.getUTCMonth()) + 1,
        ano: dia.getFullYear(),
      };

      try {
        let response = await api.post(`/pegarAlunosMentoria`, dataAlunos);

        setAlunos(response.data);
      } catch (err) {
      } finally {
        return false;
      }
    }
    pegarAlunosMentoria();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (arquivo) {
      setLoading(true);
      setTimeout(() => {
        saveMessage();
      }, 1000);
    }
  }, [arquivo]);

  useEffect(() => {
    socket.on("connect", () => toast("Lista Mentoria Iniciada"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const handleNewMessage = (newMessage) => {
      if (parseInt(alunoId) === parseInt(newMessage.user_id)) {
        console.log("newMessage ", newMessage);

        setMessages([...messages, newMessage]);
      }
    };

    socket.on("chat.message", handleNewMessage);

    return () => socket.off("chat.message", handleNewMessage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  function converterData(data) {
    const option = {
      year: "numeric",
      month: "numeric",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      second: "numeric",
    };

    var date = new Date(data);
    return date.toLocaleDateString("pt-BR", option);
  }

  function mudarChat(e) {
    e.preventDefault();

    setIdDoAluno(parseInt(e.target.dataset.user));
    setNomeDoAluno(e.target.dataset.nome);

    getMensagensPassadas(parseInt(e.target.dataset.user));
  }

  return (
    <Template>
      <S.MainWrapper>
        <Container>
          <Row>
            <Col sm={12}>
              <S.MainContent>
                <div className="alert alert-primary" role="alert">
                  <strong>Lista de Monitoria</strong>
                </div>
              </S.MainContent>

              <S.MainContent>
                <Row>
                  <Col xs={12} sm={4}>
                    <p>
                      <strong>Lista de Alunos</strong>
                    </p>

                    <ul>
                      {alunos &&
                        alunos.map((aluno, index) => (
                          <li
                            className="alert alert-info"
                            key={index}
                            data-user={aluno.user_id}
                            data-nome={aluno.nome}
                            onClick={mudarChat}
                          >
                            {aluno.nome}
                          </li>
                        ))}
                    </ul>
                  </Col>
                  <Col>
                    {alunoId ? (
                      <>
                        <p>
                          Chat com o Aluno{" "}
                          <strong>
                            {alunoNome} {alunoId}
                          </strong>
                        </p>

                        {messages.map((m, index) => (
                          <div
                            className={
                              parseInt(m.monitor_id) === 0 ||
                              parseInt(m.monitorid) === 0
                                ? "alert alert-success"
                                : "alert alert-danger text-right"
                            }
                            key={index}
                          >
                            {m.dataf ? m.dataf : converterData(m.created_at)}
                            <br />

                            {m.tipo === "a" ? (
                              <>
                                {parseInt(m.monitor_id) === 0 ||
                                parseInt(m.monitorid) === 0
                                  ? `Aluno:`
                                  : `Suporte:`}
                                <br />
                                <br />
                                <audio controls="controls">
                                  <source
                                    src={`https://redacoes.nyc3.digitaloceanspaces.com/${m.arquivo}`}
                                    type="audio/mp3"
                                  />
                                  Seu navegador não tem suporte para áudio.
                                </audio>
                              </>
                            ) : (
                              <>
                                {m.tipo === "i" ? (
                                  <>
                                    {parseInt(m.monitor_id) === 0 ||
                                    parseInt(m.monitorid) === 0
                                      ? `Aluno:`
                                      : `Suporte:`}
                                    <br />
                                    <br />
                                    <img
                                      loading="lazy"
                                      src={`https://redacoes.nyc3.digitaloceanspaces.com/${m.arquivo}`}
                                      alt={m.monitorid}
                                      className="img-fluid"
                                    />
                                  </>
                                ) : (
                                  <>
                                    {parseInt(m.monitor_id) === 0 ||
                                    parseInt(m.monitorid) === 0
                                      ? `Aluno: ${m.message}`
                                      : `Suporte: ${m.message}`}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        ))}

                        <Row>
                          <Col>
                            <p>
                              <input
                                type="text"
                                placeholder="Digite sua menasgem / dúvida"
                                onChange={(e) => setMessage(e.target.value)}
                                className="form-control"
                                value={message}
                              />
                            </p>
                          </Col>
                        </Row>

                        <Row>
                          <Col xs={9}>
                            <button
                              onClick={saveMessage}
                              className="btn btn-primary"
                              type="button"
                            >
                              Enviar Mensagem
                            </button>
                          </Col>
                          <Col xs={3} className="text-right">
                            <label>
                              <input
                                onChange={uploadFile}
                                onClick={() => setTipo("i")}
                                className="hideinput"
                                type="file"
                              />
                              <div className="btn btn-info text-center">
                                <S.AttachmentIcon />
                              </div>
                            </label>{" "}
                            <GravarAudio
                              loading={loading}
                              setArquivo={setArquivo}
                              setTipo={setTipo}
                            />
                          </Col>
                        </Row>
                      </>
                    ) : (
                      <p>Para iniciar o chat, escolha um aluno ao lado.</p>
                    )}
                  </Col>
                </Row>
              </S.MainContent>
            </Col>
          </Row>
        </Container>
      </S.MainWrapper>
    </Template>
  );
};

export default ListaMentoria;
